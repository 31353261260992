export const Emojis =[
  {
    id: 1,
    icon: "fal fa-frown",
    text: "modals.evaluate_modal.emoji1",
    iconSelected: "emojis__selected fas fa-frown",
  },

  {
    id: 2,
    icon: "fal fa-meh",
    text: "modals.evaluate_modal.emoji2",
    iconSelected: "emojis__selected fas fa-meh",
  },

  {
    id: 3,
    icon: "fal fa-smile",
    text: "modals.evaluate_modal.emoji3",
    iconSelected: "emojis__selected fas fa-smile",
  },

  {
    id: 4,
    icon: "fal fa-grin",
    text: "modals.evaluate_modal.emoji4",
    iconSelected: "emojis__selected fas fa-grin",
  },

]

export const weekEmojis =[
  {
    id: 1,
    icon: "fal fa-frown",
    text: "modals.evaluate_week_modal.emoji1",
    iconSelected: "emojis__selected fas fa-frown",
  },

  {
    id: 2,
    icon: "fal fa-meh",
    text: "modals.evaluate_week_modal.emoji2",
    iconSelected: "emojis__selected fas fa-meh",
  },

  {
    id: 3,
    icon: "fal fa-smile",
    text: "modals.evaluate_week_modal.emoji3",
    iconSelected: "emojis__selected fas fa-smile",
  },

  {
    id: 4,
    icon: "fal fa-grin",
    text: "modals.evaluate_week_modal.emoji4",
    iconSelected: "emojis__selected fas fa-grin",
  },

]

// export default Emojis;
