import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from "../../components/user_nav_bar";
import ProfilePageListItem from "../../components/profile_page_list_item";
import Avatar from "../../components/avatar";

import { userLogout } from "../../redux/actions/user";
import { kidChangeById, kidGetList } from "../../redux/actions/kid";

import { toast } from 'react-toastify';
import { showErrorToasts } from "../../helpers/utilities";
import { withTranslation } from "react-i18next";

class ProfileShow extends Component {


  componentDidMount(){
    const { t } = this.props;
    this.props.dispatch(kidGetList())
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  navigateToEdit = () => {
    this.props.history.push("profile/edit");
    // console.log("navigate to profile edit");
  };

  addKid = () => {
    this.props.history.push("/wizard/kid");
  };

  editKid = (id) => {
    this.props.dispatch(kidChangeById(id));
    this.props.history.push(`/profile/kid/${id}`);
  };

  logout = () => {
    const { dispatch, history } = this.props;

    dispatch(userLogout())
      .then((sucess) => {
        history.replace("/");
      })
      .catch((error) => {
        history.replace("/");
      });
  };

  renderKids = () => {
    const { kid } = this.props;

    return kid.list.map((kid, index) => {
      return (
        <ProfilePageListItem
          key={index}
          type="child"
          person={kid}
          buttonCallback={(id) => this.editKid(id)}
        />
      );
    });
  };

  render() {
    const { user, t } = this.props;
    const { name, email } = user;

    return (
      <UserNavBar>
        <div className="o_container o_container--medium-large">
          <h1 className="title_6 text--extra-bold center">{t('profile.show.title')}</h1>
          <div className="o_container__header o_container__header--avatar">
            <Avatar kind="user" editable={true} />
          </div>

          <div className="profile-page--user">
            <p className="title_2 text--bold">{name}</p>
            <p className="text--gray text--bold">{email}</p>
            <div className="profile-page--user-edit">
              <button
                onClick={this.navigateToEdit}
                className="button secondary_button large_button"
              >
                {t('profile.show.edit_button')}
              </button>
            </div>
          </div>

          <hr className="profile-page__separator" />

          <div className="profile-page--kids">
            <div className="profile-page__header">
              <p className="title_6 text--extra-bold">{t('profile.show.kids_title')}</p>
              <button className="button profile-page__add-button" onClick={this.addKid}><i className="fal fa-plus"></i></button>
            </div>

            {this.renderKids()}
          </div>

          <div className="submit__buttons submit__buttons--center">
            <button className="button tertiary_button small_button" onClick={this.logout}>{t('profile.show.logout_button')}</button>
          </div>
        </div>
      </UserNavBar>
    );
  }
}

const mapStateToProps = ({ user, kid }) => ({
  user,
  kid,
});

export default connect(mapStateToProps)(withTranslation() (ProfileShow));
