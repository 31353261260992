export default {
  why: {
    section1_title: "Porquê o Babygrow?",
    section1_paragraph: "O Babygrow é um serviço que facilita a construção de aprendizagens experienciais que promovem o crescimento do bebé nas suas vertentes comunicacional, psicomotora e socioemocional.",
    section1_paragraph2: "Através da escuta atenta e responsiva das crianças às brincadeiras e atividades propostas semanalmente, pretende-se expandir o leque de experiências das crianças, tornando-as co-construtoras e participantes ativas no seu processo de desenvolvimento.",
    section2_title: "Brincar é fundamental",
    section2_paragraph: "É a brincar que o bebé aprende o mundo. Ao explorar, manipular, observar, interagir, o bebé descobre-se a si e às suas capacidades, simultaneamente descobrindo e agindo sobre o mundo que o rodeia.",
    section3_title: "Os 3 primeiros anos são cruciais",
    section3_paragraph: "Nos três primeiros anos as crianças absorvem o mundo através dos sentidos todos. É o tempo da vida em que há maior potencial de aprendizagem!",
    section4_title: "Como brincar?",
    section4_paragraph: "As brincadeiras sugeridas pelo Babygrow são revistas por peritos em educação de infância. Pretendem potenciar, através do brincar, as capacidades exploratórias, investigativas (o que é isto? o que é que isto faz? o que é que eu posso fazer com isto?) e comunicacionais das crianças.",
    section5_title: "Ir para além dos brinquedos comerciais",
    section5_paragraph: "Ao incentivar a criança a interagir com objetos do dia a dia de diferentes texturas, formas e tamanhos, o Babygrow oferece ao bebé uma interação muito mais rica, sem os custos associados à compra de brinquedos comerciais.",
    section6_title: "Proporcionar situações",
    section6_paragraph: "A possibilidade dada às crianças para explorar e experimentar, fá-las acreditar em si próprias e nas suas conquistas pessoais e permite-lhes crescer nas suas aprendizagens. Brincadeiras que exploram a multisensorialidade, provocam respostas e linguagens múltiplas por parte das crianças.",
    section7_title: "Observar e escutar",
    section7_paragraph: "Um dos enfoques do Babygrow é o de capacitar o cuidador para a observação e a escuta da criança, numa suspensão consciente das suas expectativas de adulto relativamente ao que pode ser feito com os materiais/situações oferecidas, dando possibilidade às crianças a que: construam a sua compreensão pessoal sobre aquilo que fazem; aprendam a observar atenta e cuidadosamente; façam explorações sensoriais ao investigar as propriedades e características dos materiais; fruam dos momentos de interação criados... Concluindo: que façam sentido do mundo que as rodeia. A função do adulto é oferecer segurança com a sua presença, que deve ser atenta, mas não ativa.",
    section8_title: "Explorar livremente",
    section8_paragraph: "Serão disponibilizadas sugestões semanais a realizar com as crianças, promotoras da exploração, curiosidade, autonomia, cooperação e, sobretudo, potenciadoras de sucesso nos desafios que lhes são colocados, de forma a que as crianças ousem correr riscos e experimentar sem receio de falhar. Através das situações sugeridas, é dada a oportunidade às crianças de explorar livremente os materiais. Assim, o papel do adulto, para além de “proporcionador de ocasiões”, passa por ativar, manter e orientar a curiosidade, facilitando e regulando a exploração de alternativas.",
    section3_title: "",
    section3_paragraph: "",  
    section9_title: "Todos podem participar!",
    section9_paragraph: "As brincadeiras sugeridas são também ideais para os avós ou amas de dia realizarem com a criança. Desta forma pode garantir que o tempo que o seu bebé passa com outros adultos é enriquecedor e positivo para o seu crescimento.",
    image_s01_01: "Brincar é fundamental",
    image_s01_02: "Os 3 primeiros anos são cruciais",
    image_s01_03: "Como brincar",
    image_s01_05: "Evitar brinquedos comerciais",
    image_s01_04: "Proporcionar situações",
    image_s01_07: "Observar e escutar",
    image_s01_08: "Proporcionar situações",
    image_s01_06: "Todos podem participar"
  },
  how: {
    title: "Como funciona",
    section1_title: "Registe o seu filho na plataforma",
    section1_paragraph: "Insira algumas informações básicas para que as brincadeiras sejam adequadas ao seu bebé! Pode adicionar à sua conta todos os cuidadores da criança, para que todos tenham acesso a brincadeiras divertidas para fazer a qualquer altura!",
    section2_title: "Receba brincadeiras",
    section2_paragraph: "Todas as semanas irá receber 3 brincadeiras especialmente escolhidas para o seu filho! As brincadeiras têm por base materiais comuns do dia-a-dia e é necessário pouca ou nenhuma preparação. Terá ainda acesso às brincadeiras das semanas anteriores para poderem repetir sempre que quiserem.",
    section3_title: "Brinque e observe",
    section3_paragraph: "Observe como brinca o seu bebé e como reage às situações! Insira as suas notas na plataforma para que as brincadeiras da semana seguinte sejam ainda mais adequadas ao seu filho!",
    image_s03_01: "Registo",
    image_s03_02: "Brincadeiras",
    image_s03_03: "Observar"
  },
  play: {
    title: "Vamos brincar?",
    quote: "“We don't stop playing because we grow old; we grow old because we stop playing.”",
    quote_author: "Bernard Shaw", 
    section1_title: "As nossas brincadeiras",
    section1_subtitle: "As nossas brincadeiras baseiam-se em 3 princípios:",
    section1_list1: "Interações serenas, sensíveis, numa postura atenta e responsiva aos sinais de comunicação da criança",
    section1_list2: "Criar situações lúdicas de exploração e descoberta",
    section1_list3: "Privilegiar o contacto com o natural através de brincadeiras simples, apoiando e expandindo as experiências da criança",
    section1_paragraph: "As sugestões de brincadeiras promovem a exploração, curiosidade, autonomia, cooperação e, sobretudo, potenciam o sucesso nos desafios, de forma a que as crianças ousem correr riscos e experimentar sem receio de falhar, desenvolvendo não só as capacidades motoras e racionais da criança, mas também o lado emocional, promovendo a autoconfiança e autonomia do bebé.",
    section2_title: "O que fazer com as sugestões?",
    section2_paragraph: "As nossas sugestões de brincadeiras pretendem potenciar, através do brincar, as capacidades exploratórias, investigativas (o que é isto? o que é que isto faz? o que é que eu posso fazer com isto?) e comunicacionais das crianças. Para isso é importante que o cuidador aprenda a observar e a escutar a criança, numa suspensão consciente das suas expectativas de adulto relativamente ao que pode ser feito com os materiais / situações oferecidas, dando possibilidade às crianças a que: construam a sua compreensão pessoal sobre aquilo que fazem; aprendam a observar atenta e cuidadosamente; façam explorações sensoriais ao investigar as propriedades e características dos materiais; fruam dos momentos de interação criados... Concluindo: que façam sentido do mundo que as rodeia. A função do adulto é oferecer segurança com a sua presença, que deve ser atenta, mas não eminentemente ativa.",
    section3_title: "Brincar e repetir as brincadeiras",
    section3_paragraph: "Nesta fase da vida da criança, a repetição é fundamental para criar aprendizagem. Desta forma, sugerimos que proporcione várias vezes a mesma brincadeira. Através da sua conta Babygrow, terá acesso permanente a todas as sugestões de brincadeiras das semanas anteriores para que possa ir repetindo.",
    section3_paragraph2: "É importante relembrar que a mesma brincadeira poderá ter concretizações completamente diferentes de uma semana para a outra. Observe a criança e deixe-a liderar a brincadeira, não tentando impor a sua própria visão sobre o rumo que esta deveria seguir. Poderá até ficar surpreendido pelos novos caminhos que a criança descobre com os mesmos objetos/materiais…",
    section3_paragraph3: "Esteja atento às oportunidades que surgem naturalmente e brinque genuinamente com a criança, com prazer para ambos. Dê tempo à criança para ir resolvendo as situações criadas e espere que ela manifeste necessidade de apoio ao invés de o oferecer imediatamente.",
    section3_paragraph4: "Para crianças que já consigam comunicar através de palavras ou frases, sugerimos que dê a escolher à criança aquilo que prefere fazer, incentivando a sua capacidade de escolha. Por exemplo, se pretender repetir uma brincadeira da semana passada, pode perguntar qual delas a criança prefere fazer naquele momento.",
    section4_title: "A interação com a criança",
    section4_subtitle: "A forma como o adulto aborda a criança é muito importante para o seu desenvolvimento. Deve sempre:",
    section4_list1: "Chamar sempre a criança pelo próprio nome",
    section4_list2: "Aproximar-se da criança e da sua atividade com gestos delicados e voz suave, dando continuidade ao que a criança faz, sem interromper",
    section4_list3: "Falar com a criança sobre o que está a acontecer, o que se está a ver, a fazer, a ouvir, a cheirar, a provar, a sentir…",
    section4_list4: "Não infantilizar a comunicação: falar com o bebé de forma “inteligente”",
    section4_list5: "Manter o contacto visual com a criança durante a interaçã",
    section4_list6: "Envolver a criança nas situações/ações, pedindo a sua ajuda, em parceria ativa",
    section4_list7: "Estar atento aos sinais de comunicação da criança; aguardar a resposta e dar-lhe feedback",
    section4_list8: "Oferecer escolhas simples à criança, encorajando-a através do olhar, da palavra e do gesto",
    section4_list9: "Estar atento às descobertas da criança, acompanhando-as, reconhecendo a sua importância como conquistas da criança",
    image_s02_02: "Atividades"
  },
  beta: {
    title: "Beta-testers",
    section1_title: "O que são beta-testers?",
    section1_paragraph: "Quando um produto de software está pronto para o público, é importante efectuar testes com utilizadores reais. Os beta-testers são pessoas que fazem parte do público alvo e que aceitam utilizar o produto durante um período de tempo dando o seu feedback, relatando erros que lhes surjam durante a utilização, respondendo esporadicamente a conjuntos de questões ou participando em discussões abertas sobre determinados aspectos do produto.",
    section2_title: "Quem procuramos?",
    section2_paragraph: "Procuramos pessoas (pais, mães, avós ou outros) que tenham frequentemente ao seu cuidado crianças dos 0 aos 3 anos (inclusivé).",
    section3_title: "O que ganho em ser beta-tester?",
    section3_paragraph: "Os nossos beta-testers terão acesso à plataforma gratuitamente durante mais algum tempo, após esta passar a ser paga. Poderão também vir a ter acesso antecipado a novas funcionalidades que possam surgir.",
    section4_title: "Qual o meu papel enquanto beta-tester?",
    section4_paragraph: "Iremos adicionar os nossos beta-testers a um grupo no Facebook para que possam relatar problemas com a plataforma e dar as suas opiniões sobre determinados assuntos. Pontualmente iremos também pedir que preencham formulários rápidos sobre a sua experiência ao utilizar a plataforma.",
    signup_text: "Se estiveres interessado em ser beta-tester da nossa plataforma clica aqui para te candidatares:",
    signup_button: "Quero ser beta-tester!",
  }  
}