export default {
  step0: {
    title: "Conta-nos como correu a semana de {{start_date}} a {{end_date}}",
    subtitle: "No geral, como é que me senti?",
    next_button: "Seguinte"
  },
  step1: {
    title: "Conta-nos como correu esta semana!",
    subtitle: "Marca as brincadeiras que fizemos esta semana e adiciona observações",
    previous_button: "Anterior",
    next_button: "Seguinte"
  },
  step2: {
    title: "Há algo de novo sobre mim?",
    biography_title: "A minha biografia",
    biography_subtitle: "Clica nos parágrafos para actualizar a biografia.",
    update_button: "Alguma novidade?",
    previous_button: "Anterior",
    next_button: "Seguinte"
  }
}