import { ACTIVITY_GET_LIST_SUCCESS, ACTIVITY_UPDATE_SUCCESS } from "../actions/activity";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";
import { KID_CHANGE } from "../actions/kid";

const initialState = {
  kid_activities: [],
  next_page: true,
  page: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_GET_LIST_SUCCESS: {
      if (action.payload.data.kid_activities) {
        // let newArray = [];
        // if (action.payload.data.page == 1) {
        //   newArray = action.payload.data.activities;
        // } else {
        //   newArray = state.kid_activities.concat(action.payload.data.activities);
        // }

        let newArray = state.kid_activities.concat(action.payload.data.kid_activities);
        return {
          ...state,
          kid_activities: newArray,
          page: action.payload.data.page
        };
      } else {
        return {
          ...state,
          next_page: false,
        };
      }
    }
    case ACTIVITY_UPDATE_SUCCESS: {
      let newKidActivity = action.payload.data;
      let newArray = state.kid_activities.map( kw => kw.id == newKidActivity.id ? {...kw,
        checked: newKidActivity.checked, rating: newKidActivity.rating } : {...kw})
      return { ...initialState, kid_activities: newArray };
    }

    case KID_CHANGE:
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
