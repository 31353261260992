import { toast } from 'react-toastify';
import React, { Component } from "react";

export const genderHelper = (gender, type) =>{
  let options = [
    ['o', 'a'],           // 0
    ['um', 'uma'],        // 1
    ['menino', 'menina'], // 2
    ['do', 'da']          // 3
  ];

  if (Array.isArray(type)) {
    let aux_array = [];
    type.forEach((i) => {
      aux_array.push(options[i][gender]);
    });
    return aux_array.join(" ");
  } else {
    return options[type][gender];
  }
}

export const getGender = (gender) =>{
  return ['male', 'female'][gender];
}

export const skillKindHelper = (skills, kind) => {
  let names = skills.filter(s => s.kind == kind).map(s => s.name);
  // let join = ""
  // try {
  //   join = names[0].toLowerCase();
  // } catch (e) {
  //
  // }
  //
  // names.forEach((name, i) => {
  //   if (i == 0) {
  //     // continue
  //   } else if (i == names.length - 1) {
  //     join += "<span style='color: black'> e <span>" + name.toLowerCase();
  //   } else {
  //     join += ", " + name.toLowerCase();
  //   }
  // });

  // return join;
  if (names.length == 0) {
    return null;
  }

  return(
    names.map(function(name, i)
    {
        if(i > 0)
            return( <span key={i}>
                    <span style={{color: "black"}}>{i == names.length - 1 ? ' e ' : ', '}</span>
                    <span>{name.toLowerCase()}</span>
                  </span>)
        return (<span key={i}>{name.toLowerCase()}</span>)
    })
  )


}

export const showErrorToasts = (errors) => {

  // Object.entries(errors).forEach(([key, value]) => {
  //   value.forEach((item, i) => {
  //     toast.error(`${key} ${value}`);
  //   });
  // })

  errors.forEach((item, i) => {
    toast.error(item);
  });


}

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const isKidTooOld = (birthdate) => {
  // over 4 years
  return monthDiff(birthdate, new Date()) > 48;
}

