import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Constants from "../helpers/constants";

import NavBar from '../components/nav_bar';

class How extends Component {
  render() {
    const { t } = this.props;
    const locale = Constants.locale;
    return (
      <NavBar>
        <div className="o_container">
          <h1 className="title_2">{t('text_pages.how.title')}</h1>

          <div className="landing-page__inside">
            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.how.section1_title')}</h5>
                <p className="text_large">
                {t('text_pages.how.section1_paragraph')}
                </p>
              </div>

              <div className="section__image">
                <img src={require(`../../images/s03_01_${locale}.svg`)} alt={t('text_pages.how.image_s03_01')} />
              </div>
            </div>

            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.how.section2_title')}</h5>
                <p className="text_large">
                {t('text_pages.how.section2_paragraph')}
                </p>
              </div>

              <div className="section__image">
                <img src={require(`../../images/s03_02_${locale}.svg`)} alt={t('text_pages.how.image_s03_02')} />
              </div>
            </div>

            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.how.section3_title')}</h5>
                <p className="text_large">
                {t('text_pages.how.section3_paragraph')}
                </p>
              </div>

              <div className="section__image">
                <img src={require(`../../images/s03_03_${locale}.svg`)} alt={t('text_pages.how.image_s03_03')} />
              </div>
            </div>

          </div>
        </div>
      </NavBar>
    );
  }
}
export default withTranslation() (How);
