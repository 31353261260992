import React, { Component } from "react";
import { connect } from "react-redux";

import ChooseAvatarModal from "./choose_avatar_modal";

import { userEditAvatar } from "../redux/actions/user";
import { kidEditAvatar } from "../redux/actions/kid";

import { toast } from 'react-toastify';

import { showErrorToasts } from "../helpers/utilities";

import { withTranslation } from "react-i18next";

// When kind == kid and we want to remote update their avatar, we need to pass prop kidId with their id
class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAvatarModal: false,
    };
  }

  changeAvatar = (file) => {
    const { dispatch, kind, kidId, t } = this.props;

    if (kind === "kid") {
      dispatch(kidEditAvatar(kidId, file))
        .then((success) => {
          toast.success(
            t('global.toast_success.avatar_update')
          )
        })
        .catch((error) => {
          const { status, data } = error.error.response
          if (data.full_messages) {
            showErrorToasts(data.full_messages);
          } else {
            toast.error(
              t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
            )
          }
        });
    } else {
      dispatch(userEditAvatar(file))
        .then((success) => {
          toast.success(
            t('global.toast_success.avatar_update')
          )
        })
        .catch((error) => {
          const { status, data } = error.error.response
          if (data.full_messages) {
            showErrorToasts(data.full_messages);
          } else {
            toast.error(
              t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
            )
          }
        });
    }
    this.setState({ showAvatarModal: false });
  };

  changeAvatarLocal = (file) => {
    this.setState({ showAvatarModal: false });
    this.props.handleSubmit(file);
  };

  render() {
    const {
      kind,
      editable,
      handleSubmit,
      customAvatarFile,
      user,
      kid,
      kidId,
      t
    } = this.props;
    let avatarUrl;

    if (customAvatarFile) {
      avatarUrl = URL.createObjectURL(customAvatarFile);
    } else if (kind == "kid" && kid.list.length) {
      let editKid = kid.list.find((kid) => kid.id === kidId);
      if (editKid) {
        avatarUrl = editKid.avatar.medium.url;
      }
    } else if (user.avatar && user.avatar.medium) {
      avatarUrl = user.avatar.medium.url;
    }

    if (avatarUrl == null) {
      avatarUrl = require("images/caregiver/caregiver_avatar_01.png");
    }

    return (
      <>
        <div className="user__avatar-container">
          <div className="o_container--relative">
            <button
              type="button"
              className="user__avatar"
              style={{
                backgroundImage: `url(${avatarUrl})`,
              }}
              onClick={() => this.setState({ showAvatarModal: true })}
              disabled={!editable}
            ></button>

            {editable && (
              <i
                className="fal fa-pencil user__avatar-edit"
                onClick={() => this.setState({ showAvatarModal: true })}
              ></i>
            )}
          </div>
        </div>
        <div className="modal__wrapper">
          <ChooseAvatarModal
            showAvatarModal={this.state.showAvatarModal}
            hideModal={() => this.setState({ showAvatarModal: false })}
            cancelModal={() => this.setState({ showAvatarModal: false })}
            kind={kind}
            confirmModal={
              handleSubmit ? this.changeAvatarLocal : this.changeAvatar
            }
            message={t('modals.avatar.message')}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user, kid }) => ({
  user,
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Avatar));
