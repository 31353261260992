export const GET_BLOG_POSTS = "GET_BLOG_POSTS";
export const GET_BLOG_POST = "GET_BLOG_POST";

export const getBlogPosts = () => ({
  type: GET_BLOG_POSTS,
  payload: {
    request: {
      method: "get",
      url: "/posts/",
    },
  },
});

export const getBlogPost = (slug) => ({
  type: GET_BLOG_POST,
  payload: {
    request: {
      method: "get",
      url: "/posts/"+slug,
    },
  },
});
