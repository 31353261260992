export default {
  homepage: {
    title: "Olá {{name}}!",
    subtitle_option1: "Aqui estão algumas sugestões para brincar com o {{name}}",
    subtitle_option1_male: "Aqui estão algumas sugestões para brincar com o {{name}}.",
    subtitle_option1_female: "Aqui estão algumas sugestões para brincar com a {{name}}.",
    subtitle_error1_male: "Parabéns, o {{name}} já fez 4 anos! Infelizmente neste momento ainda não temos brincadeiras para crianças a partir dos 4 anos.",
    subtitle_error1_female: "Parabéns, a {{name}} já fez 4 anos! Infelizmente neste momento ainda não temos brincadeiras para crianças a partir dos 4 anos.",
    subtitle_error2_male: "Ainda não sabemos o suficiente sobre o {{name}} para lhe poder dar sugestões de brincadeiras. Por favor vai à página de perfil do {{name}} e insere algumas informações!",
    subtitle_error2_female: "Ainda não sabemos o suficiente sobre a {{name}} para lhe poder dar sugestões de brincadeiras. Por favor vai à página de perfil da {{name}} e insere algumas informações!",
  }
}
