import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import Step0 from "./components/step0";
import Step1 from "./components/step1";
import Step2 from "./components/step2";

import { userLogin, userLogout } from "../../redux/actions/user";
import { weekFormGet, weekFormUpdate} from "../../redux/actions/activity"

import { genderHelper,  skillKindHelper} from "../../helpers/utilities";
import { withTranslation } from "react-i18next";

import { toast } from 'react-toastify';

class WeekForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      kid_week: {step: -1}
    };
  }

  componentDidMount(){
    this.getWeekForm();
  }

  getWeekForm(){
    let { id } = this.props.match.params;
    const { dispatch, history, kid, t } = this.props;
    dispatch(weekFormGet(kid.list[kid.chosen].id, id))
      .then((success) => {
        console.log("success", success)
        this.setState({
          kid_week: success.payload.data,
        });
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  nextStep(step, rating = null){
    let { id } = this.props.match.params;
    const { dispatch, history, kid, t } = this.props;
    dispatch(weekFormUpdate(kid.list[kid.chosen].id, id, step, rating))
      .then((success) => {
        console.log("success", success)
        window.scrollTo(0,0);
        this.setState({
          kid_week: success.payload.data,
        });

      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  render() {
    // const { name, email } = this.props.user;
    // const { kid_activities, next_page } = this.props.activity;
    const { t } = this.props;
    const chosen_kid  = this.props.kid.list[this.props.kid.chosen];
    // const listActivities = this.props.activity.kid_activities.map((a) => <p>{a.title}</p>  );
    let show_step = null;
    if (this.state.kid_week.step == 0) {
      show_step = <Step0 rating={this.state.kid_week.rating} nextStep={(step, rating) => this.nextStep(step, rating)} kidWeekDate={this.state.kid_week.chosen_date}/>;
    } else if (this.state.kid_week.step == 1) {
      show_step = <Step1 nextStep={(step) => this.nextStep(step, this.state.kid_week.rating)}/>;
    } else if (this.state.kid_week.step == 2) {
      show_step = <Step2 nextStep={(step) => this.nextStep(step, this.state.kid_week.rating)} history={this.props.history}/>;
    } else if (this.state.kid_week.step == 3) {
      toast.success(
        t('global.toast_success.form_update')
      )
      this.props.history.replace("/home");
    }

    return (
      <UserNavBar>
        {show_step}
     </UserNavBar>


    );
  }
}

// export default WeekForm;

const mapStateToProps = ({ kid, user, activity }) => ({
  kid,
  user,
  activity
});

export default connect(mapStateToProps)(withTranslation() (WeekForm));
