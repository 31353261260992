export default {
  user_nav: {
    blog: "Blog",
    store: "Loja",
    partners: "Parceiros"
  },
  nav: {
    blog: "Blog",
    store: "Loja",
    partners: "Parceiros",
    register: "Criar conta",
    login: "Entrar"
  },
  footer: {
    blog: "Blog",
    why: "Porquê o Babygrow?",
    want_play: "Vamos Brincar?",
    partners: "Parceiros",
    terms: "Termos & Condições",
    privacy: "Política de privacidade",
    cookies: "Política de cookies",
    developer: "desenvolvido por"
  },
  cookies_bar:{
    text: "Ao utilizar este site, concorda que o mesmo utilize cookies que serão armazenadas no seu computador.",
    accept_button: "Aceito",
    knowmore_button: "Saiba mais"
  },
  toast_success:{
    create_account: "Conta criada com sucesso!",
    reset_password_instructions: "Foram enviadas instruções para o seu email!",
    reset_password_success: "Palavra-passe alterada com sucesso!",
    resend_invite: "Convite reenviado com sucesso!",
    avatar_update: "O avatar foi actualizado com sucesso!",
    info_update: "Actualizado com sucesso!",
    profile_update: "O perfil foi actualizado com sucesso!",
    form_update: "Preenchido com sucesso!"
  },
  toast_error:{
    unknown_error: "Aconteceu um erro imprevisto!",
    accept_terms: "Tem de aceitar os termos e condições e a política de privacidade.",
    login_error: "Por favor verifique se o email e a palavra-passe estão correctos.",
    incorrect_email: "Ocorreu um erro! Por favor verifique se o email está correcto."
  },
  toast_info:{
    update_activites: "Clica aqui para nos contares como correram as brincadeiras",
  }
}