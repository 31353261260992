import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import {startLoading, stopLoading} from '../../redux/actions/app';

// import AddSkill from '../../components/add_skill';


class AddSkillGuide extends Component {

  render() {
    return (
      <div>
        <h1 className="title_1"> Components Guide </h1>

        <h5 className="title_5">Add Skill</h5>

        {/*<AddSkill title="Título" nextStep={() => console.log("funciona")} options={{ 1:{text: "Op 1", checked: false}, 2:{text: "Op 2", checked: false}, 3:{text: "Op 3", checked: false} }}/>*/}

      </div>
    );
  }
}

const mapStateToProps = ({app}) => ({
  app: app,
});

export default connect(mapStateToProps)(AddSkillGuide);
