import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { cookiesPolicyAccept } from "../redux/actions/consents";
import { history } from "../helpers/routing";
import { withTranslation } from "react-i18next";

class Cookies extends Component {
  acceptCookiesPolicy = () => {
    this.props.dispatch(cookiesPolicyAccept());
  };

  navigateToCookiesPolicy = () => {
    history.replace("/cookiesPolicy");
  };

  render() {
    const { acceptedCookiesPolicy, t } = this.props;

    if (acceptedCookiesPolicy === false) {
      return (
        <div className="cookies__container">
          <p className="overline_text cookies__text">
            {t('global.cookies_bar.text')}
          </p>

          <button className="button secondary_button small_button cookies__buttons" onClick={this.navigateToCookiesPolicy}>{t('global.cookies_bar.knowmore_button')}</button>
          <button className="button primary_button small_button cookies__buttons" onClick={this.acceptCookiesPolicy}>{t('global.cookies_bar.accept_button')}</button>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ consents }) => ({
  acceptedCookiesPolicy: consents.acceptedCookiesPolicy,
});

export default connect(mapStateToProps)(withTranslation()(Cookies));


