import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import NavBar from '../components/nav_bar';
import s01_01 from '../../images/s01_01.svg';
import s01_02 from '../../images/s01_02.svg';
import s01_03 from '../../images/s01_03.svg';
import s01_04 from '../../images/s01_04.svg';
import s01_05 from '../../images/s01_05.svg';
import s01_06 from '../../images/s01_06.svg';
import s01_07 from '../../images/s01_07.svg';
import s01_08 from '../../images/s01_08.svg';


class BetaTesters extends Component {
  render() {
    const { t } = this.props;
    return (
      <NavBar>
        <div className="o_container">
          <h1 className="title_2">{t('text_pages.beta.title')}</h1>
          <div className="landing-page__inside">
            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.beta.section1_title')}</h5>
                <p className="text_large">{t('text_pages.beta.section1_paragraph')}</p>
              </div>
            </div>

            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.beta.section2_title')}</h5>
                <p className="text_large">{t('text_pages.beta.section2_paragraph')}</p>
              </div>
            </div>

            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.beta.section3_title')}</h5>
                <p className="text_large">{t('text_pages.beta.section3_paragraph')}</p>
              </div>
            </div>

            <div className="why-page__section">
              <div className="section__text">
                <h5 className="title_5 section__title">{t('text_pages.beta.section4_title')}</h5>
                <p className="text_large">{t('text_pages.beta.section4_paragraph')}</p>
              </div>

            </div>

            <div className="why-page__section">
              <div className="section__text">
                <p className="text_large">{t('text_pages.beta.signup_text')}</p>
              </div>

              <div className="section__image">
                <div className="center">
                  <a href="https://sara628797.typeform.com/to/uB6YK3" className="button primary_button small_button" role="button">
                    {t('text_pages.beta.signup_button')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavBar>
    );
  }
}
export default withTranslation() (BetaTesters);
