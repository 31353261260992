import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import CaregiverSelect from "../../components/caregiverSelect";
import ProfilePageListItem from "../../components/profile_page_list_item";

import { genderHelper,  skillKindHelper, getGender} from "../../helpers/utilities";
import { kidAddCaregiver, kidRemoveCaregiver } from "../../redux/actions/kid";

import { toast } from 'react-toastify';
import { showErrorToasts } from "../../helpers/utilities";

import { withTranslation } from "react-i18next";


class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kid: this.props.kid.list[this.props.kid.chosen],
      isAddCaretakerVisible: true,
    };
  }

  addCaregiver = (email) => {
    const { t } = this.props;
    const { id } = this.state.kid;
    console.log(`add caregiver ${email}`);

    this.props
      .dispatch(kidAddCaregiver(id, email))
      .then((success) => {
        this.updateKid();
      })
      .catch((error) => {
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });
  };

  updateKid = () => {
    const { id } = this.state.kid;

    this.setState({ kid: this.props.kid.list.find((kid) => kid.id == id) });
  };

  removeCaregiver = (caregiverId) => {
    const { t } = this.props;
    const { id } = this.state.kid;

    this.props
      .dispatch(kidRemoveCaregiver(id, caregiverId))
      .then((success) => {
        this.updateKid();
      })
      .catch((error) => {
        // console.log(error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  renderFamilyMember = () => {
    const { kid } = this.state;

    return kid.users.map((person, index) => {
      return (
        <ProfilePageListItem
          key={index}
          type={person.name ? "caretaker" : "invitation"}
          person={person}
          buttonCallback={this.removeCaregiver}
        />
      );
    });
  };

  render() {
    const { t } = this.props;
    const { chosen, list } = this.props.kid;
    const { isAddCaretakerVisible } = this.state;

    const kid = list[chosen];

    return (
      <UserNavBar>
        <div className="o_container o_container--medium">
          <h1 className="title_6 center">{t('wizard.invite.title',{context: getGender(kid.gender), name: kid.name})}.</h1>

          <div className="caregivers_select">
            <CaregiverSelect
              caregivers={[]}
              addCallback={this.addCaregiver}
              isVisible={isAddCaretakerVisible}
            />
          </div>
          {this.renderFamilyMember()}
          <div className="submit__buttons submit__buttons--center">
            <Link to="/wizard/add-another-kid" className="button primary_button large_button" role="button">
              {t('wizard.invite.next_button')} 
            </Link>
          </div>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)( withTranslation() (Invite));
