import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../redux/store";

import { Art, Sensations, OpenEnd, Care, Music, Movement, Autonomy, Light, Cook, Books } from "../helpers/gifts";

import { toast } from 'react-toastify';

import NavBar from '../components/nav_bar';


class Suggestions extends Component {


  render_element = (element, index) => {
    return(
      <div className="gift" key={element.number}>
        <img src={element.imgUrl} alt="" />
        <div>
          <h5 className="title_5 gift_number">{index}</h5>
          <h6 className="title_6">{element.name}</h6>
          <p>{element.brand}</p>
          <p className="gift_age">{element.age}</p>
          <a href={element.buttonUrl} target="_blank">
            <button type="button" name="button" className="small_button_text primary_button small_button gift_button gift_button">
              Ver Mais
            </button>
          </a>
        </div>
      </div>
    )
  }

  render_elements = (elements) => {
    return elements.map((element, index) => {
      return (
        this.render_element(element, index + 1)
      )
    });
  }


  render() {

    return (
      <NavBar>
        <div className="o_container">
          <h1 className=" title_1">As nossas sugestões</h1>
          <div className="suggestions_text_container">
            <p className="text_large">Para complementar as nossas sugestões para um <a target="_blank" href="https://babygrow.pt/blog/natalnaodescartavel">Natal não descartável</a>, criámos esta lista com ideias de produtos escolhidos pelos nossos especialistas, que complementam cada uma das áreas que sugerimos.</p>
            <p className="text_large">Apelamos à escolha pela qualidade acima da quantidade. Escolha prendas que verdadeiramente estimulem e suscitem o interesse da criança e que a permitam brincar livremente e construir o seu próprio conhecimento do mundo. Escolha prendas não descartáveis.</p>
            <p className="text_large">Regista-te gratuitamente em <a href="https://babygrow.pt/register">babygrow.pt</a> para obteres <strong>descontos exclusivos</strong> nos nossos parceiros e receber semanalmente novas ideias para brincar.</p>
          </div>

          <div className="suggestions_index">
            <p><a href="#art" className="suggestions_index--link">Oferecer arte</a></p>
            <p><a href="#sensations" className="suggestions_index--link">Oferecer sensações</a></p>
            <p><a href="#openEnd" className="suggestions_index--link">Oferecer materiais de fim aberto</a></p>
            <p><a href="#care" className="suggestions_index--link">Oferecer prendas de cuidar</a></p>
            <p><a href="#music" className="suggestions_index--link">Oferecer música</a></p>
            <p><a href="#movement" className="suggestions_index--link">Oferecer movimento</a></p>
            <p><a href="#autonomy" className="suggestions_index--link">Oferecer autonomia</a></p>
            <p><a href="#light" className="suggestions_index--link">Oferecer luz</a></p>
            <p><a href="#cook" className="suggestions_index--link">Oferecer prendas de cozinhar</a></p>
            <p><a href="#books" className="suggestions_index--link">Oferecer livros</a></p>
          </div>

          <div>
            <h3 className="title_3 text--blue list_title" id="art">Oferecer arte</h3>
            <div className="suggestions_list">
              {this.render_elements(Art)}
            </div>

            <h3 className="title_3 text--blue list_title" id="sensations">Oferecer sensações</h3>
            <div className="suggestions_list">
              {this.render_elements(Sensations)}
            </div>

            <h3 className="title_3 text--blue list_title" id="openEnd">Oferecer materiais de fim aberto</h3>
            <div className="suggestions_list">
              {this.render_elements(OpenEnd)}
            </div>

            <h3 className="title_3 text--blue list_title" id="care">Oferecer prendas de cuidar</h3>
            <div className="suggestions_list">
              {this.render_elements(Care)}
            </div>

            <h3 className="title_3 text--blue list_title" id="music">Oferecer música</h3>
            <div className="suggestions_list">
              {this.render_elements(Music)}
            </div>

            <h3 className="title_3 text--blue list_title" id="movement">Oferecer movimento</h3>
            <div className="suggestions_list">
              {this.render_elements(Movement)}
            </div>

            <h3 className="title_3 text--blue list_title" id="autonomy">Oferecer autonomia</h3>
            <div className="suggestions_list">
              {this.render_elements(Autonomy)}
            </div>

            <h3 className="title_3 text--blue list_title" id="light">Oferecer luz</h3>
            <div className="suggestions_list">
              {this.render_elements(Light)}
            </div>

            <h3 className="title_3 text--blue list_title" id="cook">Oferecer prendas de cozinha</h3>
            <div className="suggestions_list">
              {this.render_elements(Cook)}
            </div>

            <h3 className="title_3 text--blue list_title" id="books">Oferecer livros</h3>
            <div className="suggestions_list">
              {this.render_elements(Books)}
            </div>
          </div>
        </div>
      </NavBar>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

// export default connect(() => ({}))(Suggestions);
export default connect(mapStateToProps)(Suggestions);
