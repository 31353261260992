import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import moment from 'moment';
import { withTranslation } from "react-i18next";


import moment from 'moment';
moment.locale("pt");

class Post extends Component {

  render() {
    const {post, text, t} = this.props;
    let class_title = "title_5 post_title";
    let class_author = "title_6";
    let class_published_date = "";

    if ( text == "small" ) {
      class_title = "recommended_post__title";
      class_author = "recommended_post__author";
      class_published_date = "recommended_post__date";
    }

    return(
      <div className={text == "small" ? "blog_element" : "blog_element--with_padding" } key={this.key}>
        <Link className="blog_element__link" to={"/blog/" + post.slug} role="button">
          <div className="blog_frame">
            <img className="blog_cover" src={post.cover_image.large.url} alt="cover" />
            <div className="blog_text">
              <h2 className={class_title}>{post.title}</h2>
              <p className={class_author}>{post.author.name}</p>
              <p className={class_published_date}>{moment(post.published_date).format("DD MMM YYYY")}</p>
            </div>
            {/*<img src={post.author.avatar.thumb.url} alt="author avatar" />*/}
          </div>
          <div className="blog_button">
            <button type="button" name="button"
                    className={text == "small" ? "small_button_text primary_button small_button"
                                               : "button primary_button small_button" }>
              {t('components.blog_post_component.read_button')}
            </button>
          </div>
        </Link>
      </div>
    )
  }
}

export default withTranslation() (Post);
