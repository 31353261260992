import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class RemarksModal extends Component{
  handleKeyDown(e){
    if (e.keyCode === 13 ) {
      this.props.handleSubmit(e.target.id);
    }
  }

  renderRemarkLabel(remark){
    if (remark.kid_id){
      return <input type="text" className="input_text" onChange={this.props.handleTextRemarks} onKeyDown={(e) => this.handleKeyDown(e)} id={"text_" + remark.id} value={remark.title} />
    } else {
      return <label htmlFor={"checkbox_" + remark.id}><p>{remark.title}</p></label>
    }
  };

  render(){
    const {remarks, handleCheckRemarks, handleAddRemark, handleTextRemarks,
      handleSubmit, show_remarks_modal, hideModal, message, cancelModal, confirmModal, t} = this.props;
    let checklist = remarks.map((remark, key) =>
    <div className="remarks__list" key={remark.id}>
      <label className="control control_checkbox">
        <input type="checkbox" checked={remark.checked} onChange={handleCheckRemarks} id={"checkbox_" + remark.id} />
        <div className="control_indicator"></div>
      </label>
      {/*{remark.isEditable && <input type="text" onChange={handleTextRemarks} onKeyDown={(e) => this.handleKeyDown(e)} id={"text_" + remark.id}/>}*/}
      {/*{!remark.isEditable && <label htmlFor={"checkbox_" + remark.id}>{remark.title}</label>}*/}
      {this.renderRemarkLabel(remark)}
    </div>
    );

    if(!show_remarks_modal){
      return null;
    }

    return (
      <div className="modal__background modal__background--remarks">
        <div className="modal__container">
          <div className="modal__header">
            <button type="button" className="modal__close" onClick={() => hideModal()}><i className="fal fa-times"></i></button>
            <p className="modal__text regular_button_text">{message}</p>
          </div>

          <form className="remarks__form" onSubmit={(e) => e.preventDefault()}>
            {checklist}
            <button type="button" className="remarks__button" onClick={() => handleAddRemark()}>
              <i className="far fa-plus"></i><p>{t('modals.remarks_modal.add_button_placeholder')}</p>
            </button>
          </form>

          <div className="modal__buttons modal__buttons--spaced">
            <button type="button" className="button tertiary_button large_button cancel_button" onClick={() => cancelModal()}>{t('modals.remarks_modal.cancel_button')}</button>
            <button type="button" className="button primary_button large_button next_button" onClick={() => confirmModal()}>{t('modals.remarks_modal.save_button')}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation() (RemarksModal);
