import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";

import { getBlogPosts } from "../../redux/actions/blog";

import { toast } from 'react-toastify';

import NavBar from '../../components/nav_bar';
import Post from '../../components/post';
import { withTranslation } from "react-i18next";


class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    const { t } = this.props;
    let { slug } = this.props.match.params;

    this.props
      .dispatch(getBlogPosts())
      .then((success) => {
        const { title, content } = success.payload.data;
        console.log("ASdasdasda", success.payload.data);
        this.setState({ posts: success.payload.data });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  _posts = () => {
    return this.state.posts.map((post, index) => {
      return (
        <Post index={index} post={post} />
      )
    });
  }

  render() {
    const { t } = this.props;
    const { content, title } = this.state;
    console.log(this.state.content);
    return (
      <NavBar>
        <div className="o_container">
          <h1 className="o_container__header title_1">{t('blog.blog_page.title')}</h1>
          <div className="blog_grid">
            {this._posts()}
          </div>
        </div>
      </NavBar>
    );
  }
}

export default connect(() => ({})) (withTranslation() (Blog));
