export default{
  activity_card:{
    tried_button: "Experimentei"
  },
  activity_list:{
    previous_button: "Ver brincadeiras anteriores",
    remarks_modal_title: "O que fiz nesta brincadeira?",
    evaluate_modal_title: "O que achei desta brincadeira?"
  },
  kid_bio_component: {
    name: "Olá, o meu nome é ",
    birthdate: "nasci no dia ",
    weeks_1: "com ",
    weeks_2: " semanas ",
    gender_part1_male: " e sou",
    gender_part1_female: " e sou",
    gender_part2_male: " um menino",
    gender_part2_female: " uma menina",
    communication_skills: "Comunico com os outros através de",
    perception_skills: "Sinto o que se passa à minha volta e consigo",
    time_skills: "Passo grande parte do meu tempo",
    interaction_skills: "Quando interajo com objetos costumo"
  },
  add_skill_component: {
    options_alert: "Pode selecionar várias opções",
    nice_word1: "Fantástico",
    nice_word2: "Uau",
    nice_word3: "Maravilha",
    confirm_button: "! Confirmar"
  },
  caregiver_component: {
    placeholder: "Insere o email de um novo cuidador",
    add_button: "Adicionar"
  },
  profile_page_list_item: {
    remove_button: "Remover",
    resend_button: "Reenviar",
    invite_sent: "Convite Enviado",
    edit_button: "Editar"
  },
  blog_post_component: {
    read_button: "Ler Artigo"
  },
  merchants_component: {
    see_more_button: "Ver Mais"
  }
}