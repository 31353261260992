// import React from "react";
// import { render } from "react-dom";
// import App from "../general/app";
//
// document.addEventListener("DOMContentLoaded", () => {
//   render(
//     <App />,
//     document.body.appendChild(document.createElement("div"))
//   );
// });

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// import { createStore } from 'redux'
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {PersistGate} from 'redux-persist/integration/react';
// import todoApp from './reducers'
// import App from './components/App'
import * as Sentry from '@sentry/browser';
import App from "../general/app";
import ErrorBoundary from "../general/pages/error_boundary";
import Constants from "../general/helpers/constants";
import Environment from "../environment";

import {store, persistor} from '../general/redux/store';

import '../general/locales';

// import app from '../general/redux/reducers/app';
// const reducers = combineReducers({
//   app: app
// });
//
// const store = createStore(reducers)

if (Constants.sentryDSN != "") {
  Sentry.init({dsn: Constants.sentryDSN});
}

if (Environment == "production") {
  console.log = function () {};
}


document.addEventListener("DOMContentLoaded", () => {
  render(
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  )
});
