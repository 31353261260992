export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL";
export const USER_ACCEPT_INVITATION = "USER_ACCEPT_INVITATION";
export const USER_ACCEPT_INVITATION_SUCCESS = "USER_ACCEPT_INVITATION_SUCCESS";
export const USER_ACCEPT_INVITATION_FAIL = "USER_ACCEPT_INVITATION_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const USER_SET_TOKENS = "USER_SET_TOKENS";
export const USER_EDIT = "USER_EDIT";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAIL = "USER_EDIT_FAIL";
export const USER_EDIT_AVATAR = "USER_EDIT_AVATAR";
export const USER_EDIT_AVATAR_SUCCESS = "USER_EDIT_AVATAR_SUCCESS";
export const USER_EDIT_AVATAR_FAIL = "USER_EDIT_AVATAR_FAIL";

export const userLogin = (email, password) => ({
  type: USER_LOGIN,
  payload: {
    request: {
      method: "post",
      url: "/users/sign_in",
      // url: "/user_auth/sign_in",
      data: {
        user: {
          email,
          password,
        }
      },
    },
  },
});

export const userRegister = (
  name,
  email,
  gender,
  password,
  passwordConfirmation,
  avatar,
  termsAccepted
) => {
  let formData = new FormData();

  formData.append("avatar", avatar);
  formData.set("name", name);
  formData.set("email", email);
  formData.set("gender", gender);
  formData.set("password", password);
  formData.set("password_confirmation", passwordConfirmation);
  formData.set("consents_accepted", termsAccepted);

  return {
    type: USER_REGISTER,
    payload: {
      request: {
        method: "post",
        url: "/users",
        // url: "/user_auth",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData
      },
    },
  };
};

export const userResetPassword = (email) => ({
  type: USER_RESET_PASSWORD,
  payload: {
    request: {
      method: "post",
      url: "/users/password",
      // url: "/user_auth/password",
      data: {
        user : {
          email,
        },
      },
    },
  },
});

export const userChangePassword = (
  password,
  passwordConfirmation,
  resetPasswordToken
) => ({
  type: USER_CHANGE_PASSWORD,
  payload: {
    request: {
      method: "put",
      url: "/users/password",
      // url: "/user_auth/password",
      data: {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetPasswordToken,
        },
      },
    },
  },
});

export const userAcceptInvitation = (
  name,
  gender,
  password,
  passwordConfirmation,
  invitationToken,
  avatar,
  termsAccepted
) => {
  let formData = new FormData();

  formData.append("avatar", avatar);
  formData.set("name", name);
  formData.set("gender", gender);
  formData.set("password", password);
  formData.set("password_confirmation", passwordConfirmation);
  formData.set("invitation_token", invitationToken);
  formData.set("consents_accepted", termsAccepted);

  return {
    type: USER_ACCEPT_INVITATION,
    payload: {
      request: {
        method: "put",
        url: "/users/invitation",
        // url: "/user_auth/invitation",
        data: formData,
      },
    },
  };
};

export const userLogout = () => ({
  type: USER_LOGOUT,
  payload: {
    request: {
      method: "delete",
      url: "/users/sign_out",
      // url: "/user_auth/sign_out",
    },
  },
});

export const userSetTokens = (accessToken, client, uid) => ({
  type: USER_SET_TOKENS,
  accessToken,
  client,
  uid,
});

export const userEdit = (
  name,
  email,
  gender,
  receive_emails,
  password,
  newPassword,
  newPasswordConfirmation
) => {
  let user = {};

  if (newPassword) {
    user = {
      name,
      email,
      gender,
      receive_emails,
      // current_password: password,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    }
  } else {
    user = {
      name,
      email,
      gender,
      receive_emails
    }
  }

  return {
    type: USER_EDIT,
    payload: {
      request: {
        method: "patch",
        url: "/users",
        // url: "/user_auth",
        data: user
      },
    }
  };
};

export const userEditAvatar = (avatar) => {
  let formData = new FormData();
  formData.append("avatar", avatar);

  return {
    type: USER_EDIT_AVATAR,
    payload: {
      request: {
        method: "patch",
        url: "/users",
        // url: "/user_auth",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    },
  };
};
