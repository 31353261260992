import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Constants from "../helpers/constants";

import NavBar from '../components/nav_bar';


class Play extends Component {

  render() {
    
    const { t } = this.props;
    const locale = Constants.locale;
    return (
      <NavBar>
        <div className="o_container">
          <h1 className="title_2">{t('text_pages.play.title')}</h1>
          <div className="play__quote">
            <h3 className="title_5 text--gray">{t('text_pages.play.quote')}</h3>
            <p className="title_6 text--gray">{t('text_pages.play.quote_author')}</p>
          </div>

          <div className="play_section">
            <h5 className="title_5 play_section__subtitle">{t('text_pages.play.section1_title')}</h5>
            <p className="text_large">
            {t('text_pages.play.section1_subtitle')}
            </p>
              <ol className="text_large play_list ol_list">
                <li>{t('text_pages.play.section1_list1')}</li>
                <li>{t('text_pages.play.section1_list2')}</li>
                <li>{t('text_pages.play.section1_list3')}</li>
              </ol>
              <div className="play_image">
                <img src={require(`../../images/s02_02_${locale}.svg`)} alt={t('text_pages.play.image_s02_02')}/>
              </div>
              <p className="text_large">
              {t('text_pages.play.section1_paragraph')}
              </p>
          </div>

          <div className="play_section">
            <h5 className="title_5 play_section__subtitle">{t('text_pages.play.section2_title')}</h5>
            <p className="text_large">
            {t('text_pages.play.section2_paragraph')}
            </p>
            {locale === 'en' && 
              <span>
                <p className="text_large">
                {t('text_pages.play.section2_paragraph2')}
                </p>
                <ul className="text_large play_list ul_list">
                  <li>{t('text_pages.play.section2_list1')}</li>
                  <li>{t('text_pages.play.section2_list2')}</li>
                  <li>{t('text_pages.play.section2_list3')}</li>
                  <li>{t('text_pages.play.section2_list4')}</li>
                </ul>
                <p className="text_large play_text_bottom">
                {t('text_pages.play.section2_paragraph3')}
                </p>
                <p className="text_large">
                {t('text_pages.play.section2_paragraph4')}
                </p>
              </span>
            }
          </div>

          <div className="play_section">
            <h5 className="title_5 play_section__subtitle">{t('text_pages.play.section3_title')}</h5>
            <p className="text_large">
            {t('text_pages.play.section3_paragraph')}
            </p>
            <p className="text_large">
            {t('text_pages.play.section3_paragraph2')}
            </p>
            <p className="text_large">
            {t('text_pages.play.section3_paragraph3')}
            </p>
            <p className="text_large">
            {t('text_pages.play.section3_paragraph4')}
            </p>
            {locale === 'en' && 
              <p className="text_large">
              {t('text_pages.play.section3_paragraph5')}
              </p>
            }
          </div>

          <div className="play_section">
            <h5 className="title_5 play_section__subtitle">{t('text_pages.play.section4_title')}</h5>
            <p className="text_large">
            {t('text_pages.play.section4_subtitle')}
            </p>
            <ul className="text_large play_list ul_list">
              <li>{t('text_pages.play.section4_list1')}</li>
              <li>{t('text_pages.play.section4_list2')}</li>
              <li>{t('text_pages.play.section4_list3')}</li>
              <li>{t('text_pages.play.section4_list4')}</li>
              <li>{t('text_pages.play.section4_list5')}</li>
              <li>{t('text_pages.play.section4_list6')}</li>
              <li>{t('text_pages.play.section4_list7')}</li>
              <li>{t('text_pages.play.section4_list8')}</li>
              <li>{t('text_pages.play.section4_list9')}</li>
              {locale === 'en' && 
                <li>{t('text_pages.play.section4_list10')}</li>
              }
            </ul>
          </div>
        </div>
      </NavBar>
    );
  }
}
export default withTranslation() (Play);
