import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";

import { withTranslation } from "react-i18next";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

class CaregiverSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      value: props.caregivers,
    };
  }

  handleChange = (value, actionMeta) => {
    console.group("Value Changed");
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({ value });
  };

  handleInputChange = (inputValue, event) => {
    if (event.action == "input-change"){
      this.setState({ inputValue: inputValue });
    }
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    const { addCallback } = this.props;

    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault();

        this.submitChange();
    }
  };

  submitChange = () => {
    const { inputValue, value } = this.state;
    const { addCallback } = this.props;

    this.setState({inputValue: ""})

    if (addCallback) {
      addCallback(inputValue);
    }
  };


  render() {
    const { inputValue } = this.state;
    const { isVisible, caregivers, t } = this.props;
    const value = caregivers.map((el) => createOption(el));
    const selectStyles = {
      container: styles => ({ ...styles, width: "100%", margin: "0px 16px 0px 0px"}),
      placeholder: styles => ({ ...styles, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "90%"}),
    }

    if (!isVisible) {
      return null;
    } else {
      return (
        <>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder={t('components.caregiver_component.placeholder')}
          value={value}
          styles={selectStyles}
        />
        <button
          onClick={this.submitChange}
          className="button primary_button small_button"
        >
          {t('components.caregiver_component.add_button')}
        </button>
        </>
      );
    }
  }
}
export default (withTranslation() (CaregiverSelect));