export const ACTIVITY_GET_LIST = "ACTIVITY_GET_LIST";
export const ACTIVITY_GET_LIST_SUCCESS = "ACTIVITY_GET_LIST_SUCCESS";
export const ACTIVITY_GET_LIST_FAIL = "ACTIVITY_GET_LIST_FAIL";

export const ACTIVITY_GET = "ACTIVITY_GET";
export const ACTIVITY_GET_SUCCESS = "ACTIVITY_GET_SUCCESS";
export const ACTIVITY_GET_FAIL = "ACTIVITY_GET_FAIL";

export const ACTIVITY_UPDATE = "ACTIVITY_UPDATE";
export const ACTIVITY_UPDATE_SUCCESS = "ACTIVITY_UPDATE_SUCCESS";
export const ACTIVITY_UPDATE_FAIL = "ACTIVITY_UPDATE_FAIL";

export const WEEK_KID_CREATE = "WEEK_KID_CREATE";
export const WEEK_KID_CREATE_SUCCESS = "WEEK_KID_CREATE_SUCCESS";
export const WEEK_KID_CREATE_FAIL = "WEEK_KID_CREATE_FAIL";

export const WEEK_FORM_GET = "WEEK_FORM_GET";
export const WEEK_FORM_GET_SUCCESS = "WEEK_FORM_GET_SUCCESS";
export const WEEK_FORM_GET_FAIL = "WEEK_FORM_GET_FAIL";

export const WEEK_FORM_UPDATE = "WEEK_FORM_UPDATE";
export const WEEK_FORM_UPDATE_SUCCESS = "WEEK_FORM_UPDATE_SUCCESS";
export const WEEK_FORM_UPDATE_FAIL = "WEEK_FORM_UPDATE_FAIL";

export const ACTIVITY_SET_LIST = "ACTIVITIY_SET_LIST";

export const activityGetList = (kid_id, page) => ({
  type: ACTIVITY_GET_LIST,
  payload: {
    request: {
      method: "get",
      url: "kids/" + kid_id + "/activities" ,
      params: {
        // chosen_date: chosen_date ? chosen_date : null
        page: page
       },
    },
  },
});

export const activityGet = (kid_id, activity_id) => ({
  type: ACTIVITY_GET,
  payload: {
    request: {
      method: "get",
      url: "kids/" + kid_id + "/activities/" + activity_id
    },
  },
});

export const activityUpdateCheck = (kid_id, activity_id, checked) => ({
  type: ACTIVITY_UPDATE,
  payload: {
    request: {
      method: "patch",
      url: "kids/" + kid_id + "/activities/" + activity_id,
      data: {
        kid_activity:{
          checked
        }
      },
    },
  },
});

export const activityUpdateRating = (kid_id, activity_id, rating) => ({
  type: ACTIVITY_UPDATE,
  payload: {
    request: {
      method: "patch",
      url: "kids/" + kid_id + "/activities/" + activity_id,
      data: {
        kid_activity:{
          rating
        }
      },
    },
  },
});

export const activityUpdateRemarks = (kid_id, activity_id, remarks) => ({
  type: ACTIVITY_UPDATE,
  payload: {
    request: {
      method: "patch",
      url: "kids/" + kid_id + "/activities/" + activity_id + "/update_remarks",
      data: {
        remarks
      },
    },
  },
});

export const weekKidCreate = (kid_id) => ({
  type: WEEK_KID_CREATE,
  payload: {
    request: {
      method: "get",
      url: "kids/" + kid_id + "/create_kid_week"
    },
  },
});

export const weekFormGet = (kid_id, kid_week_id) => ({
  type: WEEK_FORM_GET,
  payload: {
    request: {
      method: "get",
      url: "kids/" + kid_id + "/kid_week/" + kid_week_id
    },
  },
});


export const weekFormUpdate = (kid_id, kid_week_id, step, rating = null) => ({
  type: WEEK_FORM_UPDATE,
  payload: {
    request: {
      method: "patch",
      url: "kids/" + kid_id + "/kid_week/" + kid_week_id + "/update_kid_week",
      data: {
        kid_week:{
          step,
          rating
        }
      },
    },
  },
});
