import React, { Component } from "react";
import { Link } from "react-router-dom";

import not_found from '../../images/not_found.svg';
import { withTranslation } from "react-i18next";

class NotFound extends Component {
  componentDidMount() {
    document.body.classList.add("color__background");
  };

  componentWillUnmount() {
    document.body.classList.remove("color__background");
  };

  render() {
    const { t } = this.props;
    return (
      <div className="o_container o_container--medium center error__container">
        <h1 className="title_1">{t('error_pages.not_found.title')}</h1>
        <img src={not_found} alt="Criança a olhar pelos binóculos" />
        <h3 className="title_3 error__message">{t('error_pages.not_found.subtitle')}</h3>

        <Link to="/" className="button proceed_button small_button" role="button">
          {t('error_pages.not_found.back_button')}
        </Link>
      </div>
    );
  }
}

export default withTranslation() (NotFound);
