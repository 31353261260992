export default {
  cookies_policy: {
    title: "Cookies Policy",
    version: "Version",
    back_button: "Back to home"
  },
  privacy_policy: {
    title: "Privacy Policy",
    back_button: "Back"
  },
  show_consents: {
    title: "Consents to GDPR",
    consents: "I read and accept:",
    accept_button: "Accept",
    back_button: "Back to home"
  },
  terms: {
    title: "Terms and Conditions",
    back_button: "Back"
  }
}