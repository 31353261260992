import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../../components/user_nav_bar';
import ActivityList from "../../../components/activity_list";

import { userLogin, userLogout } from "../../../redux/actions/user";
import {activityGetList} from "../../../redux/actions/activity"

import { toast } from 'react-toastify';

import { genderHelper,  skillKindHelper} from "../../../helpers/utilities";
import { withTranslation } from "react-i18next";

class Step1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      next_page: 1,
      timestamp: new Date().getTime()
    };
  }

  componentDidMount(){
    const { kid, activity, history } = this.props;

    if (kid.list.length === 0) {
      history.replace('/wizard/kid');
    } else if (activity.kid_activities.length == 0){
      this.activities_list();
    } else {
      this.setState({
        next_page: activity.page + 1,
        timestamp: new Date().getTime()
      });
    }
  }

  activities_list(){
    const { dispatch, history, kid } = this.props;
    dispatch(activityGetList(kid.list[kid.chosen].id, this.state.next_page))
      .then((success) => {
        console.log("success", success)
        let data = success.payload.data;
        this.setState({
          next_page: data.page + 1,
          timestamp: new Date().getTime()
        });
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  render() {
    const { t } = this.props;
    const { name, email } = this.props.user;
    const { kid_activities, next_page } = this.props.activity;
    const chosen_kid  = this.props.kid.list[this.props.kid.chosen];
    // const listActivities = this.props.activity.kid_activities.map((a) => <p>{a.title}</p>  );
    return (
      <>
        <div className="">
          <div className="feed__background__header feed__background__header--form o_container o_container--feed">
            <h1 className="title_3">{t('week_form.step1.title')}</h1>
            <h4 className="title_5">{t('week_form.step1.subtitle')}</h4>
          </div>
        </div>
        {/*listActivities*/}
        <div className="o_container o_container--feed feed__content">
          <ActivityList kid_activities={kid_activities} timestamp={this.state.timestamp} showNextWeek={false} showMoreActivitiesCallback={() => this.activities_list()} />
          <div className="week_form_buttons week_form_buttons--double">
            <button type="button" name="button" className="button large_button tertiary_button" onClick={() => this.props.nextStep(0)}>{t('week_form.step1.previous_button')}</button>
            <button type="button" name="button" className="button large_button primary_button" onClick={() => this.props.nextStep(2)}>{t('week_form.step1.next_button')}</button>
          </div>

        </div>
     </>


    );
  }
}

// export default Step1;

const mapStateToProps = ({ kid, user, activity }) => ({
  kid,
  user,
  activity
});

export default connect(mapStateToProps)(withTranslation() (Step1));
