import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import moment from 'moment';

import { Emojis } from "../helpers/emojis";
import { withTranslation } from "react-i18next";

class ActivityCard extends Component {

  remarks_button_class = (remarks) => {
    if (remarks.filter(remark => remark.checked == true).length > 0) {
      return "emojis__selected far fa-comment-alt-lines"
    } else {
      return "remarks__not-selected far fa-comment-alt-lines"
    }
  }

  render() {

    // const { chosen, list } = this.props.kid;
    //
    // const kid = list[chosen];
    const { kid_activity ,index, buttonCallback, remarkCallback, evaluateCallback, t } = this.props;

    let activity = kid_activity.activity;

    let image = "";

    if (activity.cover_image) {
      image = activity.cover_image.medium.url;
    }

    return (
      <div className="activity-card">
        <Link to={"/activities/" + kid_activity.id} role="button">
          <div className={`activity-card__img activity__background-img--${activity.image_color}`}>
            <img src={image} alt="cover" />
          </div>
          <h3 className="activity-card__title title_3">{activity.title}</h3>
        </Link>
        <div className="activity-card__details">
          <div className="activity-card__button-container">
            <div className="activity-card__button" onClick={() => remarkCallback(kid_activity.id)} >
              <i className={this.remarks_button_class(activity.remarks)} ></i>
            </div>
            <div className="activity-card__button" onClick={() => evaluateCallback(kid_activity.id)} ><i className={ kid_activity.rating == null ? "emojis__not-selected far fa-smile" : Emojis[kid_activity.rating].iconSelected } ></i></div>

            <label
              className={`button small_button ${ kid_activity.checked ? "cta_button_selected" : "cta_button" }`}
              id={index+"_label"}
            >
              <input style={{display: "none"}}
                onClick={() => buttonCallback(kid_activity.id)}
                type="checkbox"
                id={index}
                defaultChecked={kid_activity.checked}
              />
            <i id={index+"_icon"} className="fas fa-check" style={{display: kid_activity.checked ? "inline-block" : "none", paddingRight: "6px"}}></i>
              {t('components.activity_card.tried_button')}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

 export default withTranslation() (ActivityCard);

// const mapStateToProps = ({ kid }) => ({
//   kid,
// });
//
// export default connect(mapStateToProps)(ActivityCard);
