export default {
  why: {
    section1_title: "Why Babygrow?",
    section1_paragraph: "Babygrow is a service that facilitates the construction of experiential learning that promotes the baby's growth in its communicational, psychomotor and socioemotional aspects.",
    section1_paragraph2: "Através da escuta atenta e responsiva das crianças às brincadeiras e atividades propostas semanalmente, pretende-se expandir o leque de experiências das crianças, tornando-as co-construtoras e participantes ativas no seu processo de desenvolvimento.",
    section2_title: "Playing is key",
    section2_paragraph: "It is through play that the baby learns about the world. By exploring, manipulating, observing and interacting, the child discovers themselves and their capacities, simultaneously learning about and acting upon the world that surrounds them.",
    section3_title: "The first 3 years are crucial",
    section3_paragraph: "In the first 3 years of their lives, children absorb the world through all of their senses. It is the period of life with the most potential for learning!",
    section4_title: "How to play?",
    section4_paragraph: "The suggestions brought to you by Babygrow are proposed and reviewed by Childhood experts. They seek to promote the exploratory, communicative and investigative capacities of each child through play, allowing the child to answer questions such “What’s this?”, “What does it do?” or “What can I do with it?” on their own, or with little adult help.",
    section5_title: "Going beyond commercial toys",
    section5_paragraph: "By encouraging the child to interact with objects present in their daily routine that each have different textures, shapes and sizes, Babygrow offers play with much richer interaction than that of normal commercial toys, and without the associated costs that come with them!",
    section6_title: "Creating opportunity",
    section6_paragraph: "By giving the child the opportunity to explore and experiment, we allow them to believe in themselves and their personal conquests, which in turn helps them grow through play. Exploring multiple senses draws out different responses and languages from the child.",
    section7_title: "Watching and listening",
    section7_paragraph: "One of Babygrow's focuses is to train the caregiver to observe and listen to the child, in a conscious suspension of their adult expectations regarding what can be done with the materials / situations offered, giving children the possibility to: build the their personal understanding of what they do; learn to observe attentively and carefully; make sensory explorations when investigating the properties and characteristics of materials; enjoy the moments of interaction created ... In conclusion: that make sense of the world around them. The adult's role is to offer security with his presence, which must be attentive, but not active.",
    section8_title: "Explore freely",
    section8_paragraph: "The caregiver will receive weekly suggestions of activities to do with the children, that promote exploration, curiosity, autonomy, cooperation and, above all, that can enhance their success in the challenges they are faced with, so that children dare to take risks and experiment without fear of failure. Through the suggested scenarios, children are given the opportunity to freely explore the materials. Thus, the role of the adult, in addition to being an “opportunity provider”, involves activating, maintaining and guiding curiosity, facilitating and regulating the exploration of alternatives.",
    section9_title: "Everyone can do it!",
    section9_paragraph: "The suggestions brought to you by Babygrow can also be used by grandparents or nannies. This way you can make sure that the time your baby spends with other adults is enriching and positive for their growth.",
    image_s01_01: "Playing is key",
    image_s01_02: "The first 3 years are crucial",
    image_s01_03: "How to play?",
    image_s01_05: "Going beyond commercial toys",
    image_s01_04: "Creating opportunity",
    image_s01_07: "Watching and listening",
    image_s01_08: "Creating opportunity",
    image_s01_06: "Everyone can do it!"
  },
  how: {
    title: "How does it work?",
    section1_title: "Register your child in the platform",
    section1_paragraph: "We need to know some basic information so that the suggestions we give are tailored to your baby. You can give each of the child’s caretakers access, so that everyone can receive the suggestions and play with the baby at any time!",
    section2_title: "Get playing!",
    section2_paragraph: "Every week you will receive 3 suggestions specifically chosen for your child’s profile! These activities are based on common materials from our daily lives, and as such need very little to no previous setup. You will also have access to all the previous activities you have received, so you can repeat them whenever you wish.",
    section3_title: "Play and observe",
    section3_paragraph: "Watch how your baby plays and reacts to new situations! Add notes to your baby’s profile so that next week’s suggestion are even more adapted to your baby!",
    image_s03_01: "Register",
    image_s03_02: "Activities",
    image_s03_03: "Observe"
  },
  play: {
    title: "Want to play?",
    quote: "“We don't stop playing because we grow old; we grow old because we stop playing.”",
    quote_author: "Bernard Shaw", 
    section1_title: "Our activities",
    section1_subtitle: "Our activities are based on 3 premises:",
    section1_list1: "Serene and sensitive interactions through an attentive and responsive analysis of the child’s communication signals.",
    section1_list2: "Creating enriching opportunities for exploration and discovery",
    section1_list3: "Favouring the contact with nature through simple play, supporting and expanding the child’s life experience.",
    section1_paragraph: "The suggested activities promote exploration, curiosity, autonomy, cooperation and, above all, enhance the possibility for success, so that children dare to take risks and experience things without fear of failure, developing not only the child's motor and rational abilities, but also the emotional side, promoting the baby's self-confidence and autonomy.",
    section2_title: "What to do with the suggestions?",
    section2_paragraph: "Our suggested activities intend to enhance, through play, the child's exploratory, investigative (what is this? what does this do? what can I do with it?) and communication skills.",
    section2_paragraph2: "It is important for the caregiver to learn to observe and listen to the child, distancing themselves from their adult expectations regarding what can be done with the materials / situations offered, and allowing children to:",
    section2_list1: "build their personal understanding of that that they are doing;",
    section2_list2: "learn to observe attentively and carefully;",
    section2_list3: "make sensory explorations when investigating the properties and characteristics of materials;",
    section2_list4: " enjoy the interaction moments created...",
    section2_paragraph3: "In conclusion: to make sense of the world around them.",
    section2_paragraph4: "The adult's role is to offer security with their presence, which must be attentive, but not eminently active.",
    section3_title: "Play and repeat the activities",
    section3_paragraph: "At this stage in life, repetition is essential for learning. Therefore, we suggest that you offer the same materials several times. Through your Babygrow.fun account, you will have permanent access to all the play ideas from the previous weeks so you and your child can repeat, repeat, repeat!",
    section3_paragraph2: "It is important to remember that the same materials / activity can have completely different results from one week to the next and that is completely normal and expected.",
    section3_paragraph3: "Observe the child and let them lead the activity. The adult should never try to impose their own vision on how they believe the activity should be. Let the child play as freely as possible and you may even be surprised by the new paths that the child discovers with the same objects / materials…",
    section3_paragraph4: "Be aware of opportunities that arise naturally and play genuinely with the child. Allow the child enough time to solve the situations created and wait for the child to show the need for support rather than offering it immediately.",
    section3_paragraph5: "For children who are already able to communicate using words or phrases, we suggest giving the child a choice on what they prefer to do, encouraging their ability to choose. For example, if you want to repeat an activity from last week, you can ask them which one they would prefer.",
    section4_title: "Interaction with the child",
    section4_subtitle: "The way the adult approaches and communicates with the child is very important for their development. You should always:",
    section4_list1: "Always call the child by their first name",
    section4_list2: "Approach the child and their activity with delicate gestures and soft voice, in continuity to what the child is doing, without interrupting",
    section4_list3: "Talk to the child about what is happening, what is being seen, doing, hearing, smelling, tasting, feeling ...",
    section4_list4: "Do not infantilize communication: talk to your baby as you would talk to any adult person, without using “baby talk” or made up words.",
    section4_list5: "Maintain eye contact with the child during every interaction",
    section4_list6: "Involve the child in situations / actions, by asking for their help, in an active partnership",
    section4_list7: "Be observant to communication signals;",
    section4_list8: "Whenever asking a question, wait for a reply then give the child your thoughts and feedback as well",
    section4_list9: "Offer simple choices to the child, encouraging them to communicate in various ways (eye sight, gestures, words...)",
    section4_list10: "Be mindful of the child's discoveries, accompanying them in their victories, and recognising their importance as the child's achievements",
    image_s02_02: "Activities"
  },
  beta: {
    title: "Beta-testers",
    section1_title: "What are beta-testers?",
    section1_paragraph: "When a software product is ready for the public, it is important to test with real users. Beta-testers are people who are part of the target audience and who accept to use the product for a period of time giving their feedback, reporting errors that arise during use, responding sporadically to sets of questions or participating in open discussions about certain aspects of the product.",
    section2_title: "Who are we looking for?",
    section2_paragraph: "We are looking for people (fathers, mothers, grandparents or others) who often have children from 0 to 3 years old in their care (inclusive).",
    section3_title: "What do I gain from being a beta-tester?",
    section3_paragraph: "Our beta-testers will have access to the platform free of charge for some time after it is paid. They may also have early access to new features that may arise.",
    section4_title: "What is my role as a beta-tester?",
    section4_paragraph: "We will be adding our beta-testers to a Facebook group so that they can report problems with the platform and give their opinions on certain issues. We will also occasionally ask you to fill out quick forms about your experience using the platform.",
    signup_text: "If you are interested in being a beta-tester of our platform click here to apply:",
    signup_button: "I want to be a beta-tester!",
  }  
}