import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import moment from 'moment';

import ActivityCard from "./activity_card";
import EvaluateModal from "./evaluate_modal";
import RemarksModal from "./remarks_modal";
import { withTranslation } from "react-i18next";

import { activityUpdateCheck, activityUpdateRemarks } from "../redux/actions/activity";

import { toast } from 'react-toastify';

class ActivityList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      //activities
      kid_activities_list: [],
      //modals
      show_evaluate_modal: false,
      show_remarks_modal: false,
      info_evaluate_modal: "",
      info_remarks_modal: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.timestamp!==this.props.timestamp){
      //Perform some operation here
      this.setState({kid_activities_list: this.props.kid_activities});
    }
  }

  remarkCallback(id) {
    let info = "";
    this.state.kid_activities_list.map((kid_activity, index) =>
      kid_activity.id === id ? info = kid_activity : null
    );
    this.setState({
      show_remarks_modal: true,
      info_remarks_modal: info
    });
  }

  evaluateCallback(id) {

    let activity_filtered = this.state.kid_activities_list.filter(activity =>
      activity.id === id
    )

    this.setState({
      show_evaluate_modal: true,
      info_evaluate_modal: activity_filtered[0]
    });
  }

  buttonCallback(id) {
    let activity_filtered = this.state.kid_activities_list.filter(activity =>
      activity.id === id
    )

    this.props.dispatch(activityUpdateCheck(this.props.kid.list[this.props.kid.chosen].id, activity_filtered[0].id, !activity_filtered[0].checked ))
      .then((success) => {
        this.setState({
          kid_activities_list: this.state.kid_activities_list.map((activity, index) =>
            activity.id === id ? {...activity, checked: !activity.checked} : activity
          )
        });
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  handleEvaluate(rating){
    console.log("rating", rating, this.state.info_evaluate_modal);
    // let aux = this.state.kidActivity;
    // aux.activity.rating = rating;
    // this.setState({kidActivity: {...this.state.kidActivity, rating: rating}});
    this.setState({
      kid_activities_list: this.state.kid_activities_list.map((activity, index) =>
        activity.id === this.state.info_evaluate_modal.id ? {...activity, rating: rating} : activity
      )
    });
  };

  handleCheckRemarks(e){
    console.log("handleCheckRemarks", e);

    let checkedRemark = null;
    let checkedKidActivity = null;

    let remarks_aux = [];


    // this.state.kid_activities_list.forEach(kid_activity => {
      // kid_activity.activity.remarks.forEach(remark => {
    this.state.info_remarks_modal.activity.remarks.forEach(remark => {
      if ("checkbox_" + remark.id == e.target.id) {
        checkedRemark = remark;
        checkedRemark.checked = !checkedRemark.checked;
        // checkedKidActivity = kid_activity;
      } else {
        checkedRemark = remark;
      }

      remarks_aux.push(checkedRemark);
    });


    let info_remarks_modal = this.state.info_remarks_modal;
    info_remarks_modal.activity.remarks = remarks_aux;

    this.setState({info_remarks_modal: info_remarks_modal })


    // });

    // this.props.dispatch(activityUpdateRemarks(this.props.kid.list[this.props.kid.chosen].id, this.state.info_remarks_modal.id, checkedRemark ))
    //   .then((success) => {
    //     console.log("success", success);
    //
    //     this.setState({
    //       kid_activities_list: this.state.kid_activities_list.map((kid_activity, index) =>
    //         kid_activity.id === this.state.info_remarks_modal.id ? success.payload.data : kid_activity
    //       )
    //     });
    //   })
    //   .catch((error) => {
    //     // console.log("error", error);
    //     toast.error(
    //       "Aconteceu um erro imprevisto!"
    //     )
    //   });
  };

  updateRemarks() {
    const { t } = this.props;
    let remarks = this.state.info_remarks_modal.activity.remarks
    this.props.dispatch(activityUpdateRemarks(this.props.kid.list[this.props.kid.chosen].id, this.state.info_remarks_modal.id, remarks ))
      .then((success) => {
        console.log("success", success);

        this.setState({
          kid_activities_list: this.state.kid_activities_list.map((kid_activity, index) =>
            kid_activity.id === this.state.info_remarks_modal.id ? success.payload.data : kid_activity
          ),
          show_remarks_modal : false
        });

        toast.success(
          t('global.toast_success.info_update')
        )
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  handleAddRemark(){
    console.log("handleAddRemark");
    let newRemarks = this.state.info_remarks_modal.activity.remarks;
    let newId = (newRemarks.length + 1) * -1;
    // newRemarks.push({id: newId, value: "", isChecked: false, isEditable: true})
    // this.setState({kidActivity: {...this.state.kidActivity,
    //   activity: {...this.state.kidActivity.activity,
    //     remarks: [...this.state.kidActivity.activity.remarks, {id: newId, title: "", checked: false, kid_id: this.state.kidActivity.kid_id}]
    // }}});

    let newKidActivity = this.state.info_remarks_modal;

    this.setState({
      kid_activities_list: this.state.kid_activities_list.map((kid_activity, index) => {
        if (kid_activity.id === this.state.info_remarks_modal.id) {
          newKidActivity = {...kid_activity,
            activity: {...kid_activity.activity,
              remarks: [...kid_activity.activity.remarks, {id: newId, title: "", checked: true, kid_id: kid_activity.kid_id}]
          }}
          return newKidActivity
        } else {
          return kid_activity;
        }
      })
    });

    this.setState({info_remarks_modal: newKidActivity})
  };

  handleTextRemarks(e){
    console.log("handleTextRemarks");
    let newRemarks = [];

    this.state.info_remarks_modal.activity.remarks.forEach(remark => {
      if ("text_" + remark.id == e.target.id) {
        remark.title = e.target.value;
      }

      newRemarks.push(remark);
    });


    let newKidActivity = this.state.info_remarks_modal;

    this.setState({
      kid_activities_list: this.state.kid_activities_list.map((kid_activity, index) => {
        if (kid_activity.id === this.state.info_remarks_modal.id) {
          newKidActivity = {...kid_activity,
            activity: {...kid_activity.activity,
              remarks: newRemarks
          }}
          return newKidActivity
        } else {
          return kid_activity;
        }
      })
    });
  };

  showActivities() {

    let activities = this.state.kid_activities_list.map((kid_activity, index) =>
      <ActivityCard
        key={index}
        index={index}
        kid_activity={kid_activity}
        buttonCallback={(id, show) => this.buttonCallback(id, show)}
        remarkCallback={(id) => this.remarkCallback(id)}
        evaluateCallback={(id) => this.evaluateCallback(id)}
      />
    );

    return activities;
  }

  render() {

    // const { chosen, list } = this.props.kid;
    //
    // const kid = list[chosen];

    const { showMoreActivitiesCallback, t } = this.props;

    let remarks = []
    if (this.state.info_remarks_modal) {
      remarks = this.state.info_remarks_modal.activity.remarks;
    }

    if (this.state.kid_activities_list.length == 0) {
      return null;
    }

    return(
      <div className="activity-list">
        <div className="activity-list__activities">
          {this.showActivities()}
        </div>
        <div className="activity-list__see_more_button" style={{textAlign: 'center'}}>
          {this.props.showNextWeek ? <button type="button" className="primary_button small_button" onClick={() => showMoreActivitiesCallback()}>{t('components.activity_list.previous_button')}</button> : ""}
        </div>
        <div className="activity-list__modals" id="modals" style={{position: "fixed", top: "0", left: "0", width: "100%", zIndex: "100"}}>

          {this.state.show_evaluate_modal && <EvaluateModal
            show_evaluate_modal={this.state.show_evaluate_modal}
            hideModal={() => this.setState({show_evaluate_modal : false})}
            activity={this.state.info_evaluate_modal}
            handleEvaluate={(e) => this.handleEvaluate(e)}
            message={t('components.activity_list.evaluate_modal_title')} />}

            <RemarksModal
              show_remarks_modal={this.state.show_remarks_modal}
              hideModal={() => this.setState({show_remarks_modal : false})}
              cancelModal={() => this.setState({show_remarks_modal : false})}
              confirmModal={() => this.updateRemarks()}
              remarks={remarks}
              handleCheckRemarks={(e) => this.handleCheckRemarks(e)}
              handleAddRemark={() => this.handleAddRemark()}
              handleTextRemarks={(e) => this.handleTextRemarks(e)}
              handleSubmit={(e) => this.handleSubmit(e)}
              message={t('components.activity_list.remarks_modal_title')} />
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation()(ActivityList));