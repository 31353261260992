import React, { Component } from 'react';

class SkillOption extends Component {
  render() {
    const {option, value, setOption} = this.props;

    return (
      <>
      <label className={`add-skills__options-button option button ${option.checked ? "form_button selected" : "form_button"}`} id={value+"_label"} key={this.key}>
        <input style={{display: 'none'}}
          onClick={()=> {setOption(!option.checked, value)}}
          type="checkbox"
          id={option.id}
          defaultChecked={option.checked}
          value={option.name} />
        {option.name.toLowerCase()}
      </label>
      </>
    );
  }
}
export default SkillOption;
