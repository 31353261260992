export default {
  avatar_modal: {
    add_photo: "Adicionar uma foto",
    cancel_button: "Cancelar",
    save_button: "Confirmar"
  },
  avatar: {
    message: "Escolha um avatar ou adicione uma foto"
  },
  evaluate_modal: {
    emoji1: "Não gostei",
    emoji2: "Mais ou menos",
    emoji3: "Gostei",
    emoji4: "Adorei"
  },
  evaluate_week_modal: {
    emoji1: "Rabugento",
    emoji2: "Mais ou menos",
    emoji3: "Alegre",
    emoji4: "Muito contente"
  },
  remarks_modal: {
    add_button_placeholder: "O que fiz mais?",
    cancel_button: "Cancelar",
    save_button: "Confirmar"
  },
  text_modal: {
    cancel_button: "Cancel",
    confirm_button: "Confirm"
  }
}