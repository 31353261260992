import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import { store } from "../../redux/store";

import { getBlogPost } from "../../redux/actions/blog";

import { toast } from 'react-toastify';

import NavBar from '../../components/nav_bar';
import Post from '../../components/post';
import { withTranslation } from "react-i18next";

import moment from 'moment';
moment.locale("pt");

const ReactMarkdownWithHtml = require('react-markdown/with-html')

class ShowBlog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      subtitle: "",
      content: "",
      tags: [],
      merchant_products: [],
      posts: []
    };
  }

  componentDidMount() {
    this.setBlogPostContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setBlogPostContent();
    }
  }

  setBlogPostContent() {
    const { t } = this.props;
    let { slug } = this.props.match.params;

    this.props
      .dispatch(getBlogPost(slug))
      .then((success) => {
        const { title, subtitle, content, author, published_date, tag_list } = success.payload.data.post;
        console.log("ASdasdasda", success.payload.data);
        this.setState({
          title: title,
          subtitle: subtitle,
          content: content,
          author: author,
          published_date: published_date,
          tags: tag_list,
          merchant_products: success.payload.data.merchant_products,
          posts: success.payload.data.other_posts
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  _tags = () => {
    return this.state.tags.map((tag, index) => {
      return (
        <div className="blog_tags__element" key={index}>
          <p>{tag}</p>
        </div>)
    });
  }

  _merchant_products = () => {
    return this.state.merchant_products.map((merchant_product, index) => {
      return (
        <a key={index} href={merchant_product.url} target="_blank" className="blog_merchant_product merchant_product--blog" >
          <div>
            <img src={merchant_product.image}/>
            {merchant_product.promo == "" ? "" :
              <p className="merchant_product--caption">
                {merchant_product.name}
                <br></br>
                <span>
                  {this.props.user.id != null ? merchant_product.promo : ""}
                </span>
              </p>
            }
            <p></p>
          </div>
        </a>)
    });
  }


  _posts = () => {
    console.log(this.state.posts);
    return this.state.posts.map((post, index) => {
      return (
        <Post key={index} post={post} text="small" />
      )
    });
  }

  render() {
    const { t } = this.props;
    const { content, title, subtitle, published_date, author, tags, posts } = this.state;
    return (
      <NavBar>
        <div className="o_container o_container--blog">
          <h1 className="o_container__header blog_header title_3">
            {title}
            { subtitle &&
              <p className="text--medium-gray title_5">{subtitle}</p>
            }
          </h1>
          <div className="blog_wrapper">
              <div className="blog_main">
                <div className="blog_author">
                  { author &&
                    <img className="blog_author__image" src={author.avatar.medium.url} alt="author avatar" />
                  }
                  <div className="blog_author__name">
                    {author ? <p className="title_6">{author.name}</p> : "" }
                    {author ? <p>{author.title}</p> : "" }
                    <p>{moment(published_date).format("DD MMM YYYY")}</p>
                  </div>
                </div>
                <div className="blog_content">
                  <ReactMarkdownWithHtml children={content} allowDangerousHtml />
                </div>
                {tags.length > 0 &&
                  <div className="blog_tags">
                    <h2 className="title_6">{t('blog.show.tags')}</h2>
                    <div className="blog_tags_wrapper">
                      {this._tags()}
                    </div>
                  </div>
                }
              </div>

              <div className="blog_merchant_product_grid">
                {this._merchant_products()}
              </div>

              <div className="see_others_posts__container" >
                <div className="see_others_posts__posts" >
                  {this._posts()}
                </div>
                <div className="center" style={{paddingTop: "64px"}}>
                  <Link to="/blog" className="button secondary_button large_button" role="button">
                    {t('blog.show.suggested_articles')}
                  </Link>
                </div>
              </div>
          </div>
        </div>
      </NavBar>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(withTranslation() (ShowBlog));
