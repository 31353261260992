import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userChangePassword } from "../../redux/actions/user";
import { getQueryStringParams } from "../../helpers/routing";

import UserNavBar from '../../components/user_nav_bar';

import { toast } from 'react-toastify';

import { showErrorToasts } from "../../helpers/utilities";

import { withTranslation } from "react-i18next";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      resetPasswordToken: "",
    };
  }

  componentDidMount = () => {
    // console.log(getQueryStringParams(this.props.location.search));
    const { reset_password_token } = getQueryStringParams(
      this.props.location.search
    );
    this.setState({ resetPasswordToken: reset_password_token });
  };

  changePassword = (event) => {
    event.preventDefault();

    const { dispatch, history, t } = this.props;
    const { password, passwordConfirmation, resetPasswordToken } = this.state;

    dispatch(
      userChangePassword(password, passwordConfirmation, resetPasswordToken)
    )
      .then((success) => {
        // console.log("-------------", success);
        // alert("Password changed successfuly");
        // history.replace("/login");

        // const { status, data } = success.payload
        // if (data.full_messages) {
        //   showErrorToasts(data.full_messages);
        // } else {
        toast.success(
          t('global.toast_success.reset_password_success')
        )
        history.replace("/login");
        // }

      })
      .catch((error) => {
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });
  };

  handleInputChange = (event) => {
    const { name, checked, value } = event.target;

    this.setState({
      [name]: checked ? checked : value,
    });
  };

  render() {
    const { t } = this.props;
    const { password, passwordConfirmation } = this.state;

    return (
      <UserNavBar theme="white">
        <div className="o_container o_container--small">
          <h1 className="o_container__header title_3">{t('auth.change_password.title')}</h1>
          <form className="form_login" onSubmit={this.changePassword}>
            <label className="form_input">
              {t('auth.change_password.new_password_input')}
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                required
              />
            </label>
            <label className="form_input">
              {t('auth.change_password.confirm_password_input')}
              <input
                type="password"
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                required
              />
            </label>
            <div className="form_login_bottom">
              <input
                type="submit"
                value="Alterar"
                className="button large_button primary_button"
              />
            </div>
          </form>
          {/* <Link
            to="/login"
            className="button primary_button small_button"
            role="button"
          >
            Criar conta
          </Link> */}
        </div>
      </UserNavBar>
    );
  }
}
// export default Home;

const mapStateToProps = ({ app }) => ({
  app,
});

export default connect(mapStateToProps)(withTranslation() (ChangePassword));
