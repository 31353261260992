import React, { Component } from 'react';
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import moment from 'moment';

import ActivityList from "../../components/activity_list";

class ActivityListGuide extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activities_list: [
        {id: 1 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Linda", checked: false },
        {id: 2 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Linda", checked: true },
        {id: 3 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Linda", checked: false },
        {id: 4 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Linda", checked: false }
      ]
    }
  }

  showMoreActivities() {
    this.setState({
      activities_list: this.state.activities_list.push(
        {id: 5 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Gira", checked: true },
        {id: 6 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Gira", checked: true },
        {id: 7 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Gira", checked: true },
        {id: 8 ,cover_image: "https://imgs.sapo.pt/gfx/118869.gif", title: "Atividade Gira", checked: true }
      )
    });
    console.log("Botão a adicionar atividades ao state");
  }

  render() {

    // const { chosen, list } = this.props.kid;
    //
    // const kid = list[chosen];

    return(
      <div>
        <h1 className="title_1"> Components Guide </h1>

        <h5 className="title_5">Activity List</h5>

        <h6 className="title_6">- Weekly Form</h6>
        <ActivityList activities={this.state.activities_list} onlyShowAWeek={true} />

        <h6 className="title_6">- Activities Page</h6>
        <ActivityList activities={this.state.activities_list} onlyShowAWeek={false} showMoreActivitiesCallback={() => this.showMoreActivities()} />

      </div>
    )
  }
}

export default ActivityListGuide;



// const mapStateToProps = ({ kid }) => ({
//   kid,
// });
//
// export default connect(mapStateToProps)(ActivityCard);
