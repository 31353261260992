import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import {startLoading, stopLoading} from '../redux/actions/app';

class ComponentsGuide extends Component {

  render() {
    return (
      <div>
        <h1> Components Guide </h1>
        <Link
          to="/components_guide/nav"
          className="button primary_button small_button"
          role="button"
        >
          Nav
        </Link>
        <Link
          to="/components_guide/modal"
          className="button primary_button small_button"
          role="button"
        >
          Modal
        </Link>
        <Link
          to="/components_guide/profile_page_list_item"
          className="button primary_button small_button"
          role="button"
        >
          Profile Page List Item
        </Link>
        <Link
          to="/components_guide/add_skill"
          className="button primary_button small_button"
          role="button"
        >
          Add Skill
        </Link>
        <Link
          to="/components_guide/activity_list"
          className="button primary_button small_button"
          role="button"
        >
          Activity List
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({app}) => ({
  app: app,
});

export default connect(mapStateToProps)(ComponentsGuide);
