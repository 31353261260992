export default {
  login: {
    title: "Bem-vindo ao Babygrow!",
    email_input: "Email",
    password_input: "Palavra-passe",
    reset_password: "Recuperar palavra-passe",
    login: "Entrar",
    register: "Criar Conta"
  },
  register: {
    title: "Criar conta",
    gender_male: "Masculino",
    gender_female: "Feminino",
    name_input: "Nome",
    email_input: "Email",
    password_input: "Palavra-passe",
    password_confirmation_input: "Confirmar palavra-passe",
    accept_terms1: "Eu aceito os",
    terms_link: "Termos e Condições",
    accept_terms2: "e a",
    privacy_link: "Política de Privacidade",
    register_button: "Criar conta"
  },
  reset_password: {
    title: "Recuperar Password",
    email_input: "Email",
    recover_button: "Recuperar"
  },
  change_password: {
    title: "Alterar palavra-passe",
    new_password_input: "Nova palavra-passe",
    confirm_password_input: "Confirmar nova palavra-passe"
  },
  accept_invitation: {
    title: "Aceitar Convite",
    gender_male: "Masculino",
    gender_female: "Feminino",
    name_input: "Nome",
    password_input: "Palavra-passe",
    password_confirmation_input: "Confirmar palavra-passe",
    accept_terms1: "Eu aceito os",
    terms_link: "Termos e Condições",
    accept_terms2: "e a",
    privacy_link: "Política de Privacidade",
    confirm_button: "Confirmar"
  }
}