export default {
  step0: {
    title: "Tell us how the week from {{start_date}} to {{end_date}} went",
    subtitle: "Overall, how did I feel?",
    next_button: "Next"
  },
  step1: {
    title: "Tell us how this week went!",
    subtitle: "Check the activites we did this week and add remarks",
    previous_button: "Previous",
    next_button: "Next"
  },
  step2: {
    title: "Is there something new about me?",
    biography_title: "My biography",
    biography_subtitle: "Tap on the paragraphs to update my briography.",
    update_button: "Got any news?",
    previous_button: "Previous",
    next_button: "Next"
  }
}