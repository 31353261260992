export default {
  cookies_policy: {
    title: "Política de Cookies",
    version: "Versão",
    back_button: "Voltar à página principal"
  },
  privacy_policy: {
    title: "Política de Privacidade",
    back_button: "Voltar"
  },
  show_consents: {
    title: "Consentimentos para o GDPR",
    consents: "Eu li e aceito:",
    accept_button: "Aceitar",
    back_button: "Voltar à página principal"
  },
  terms: {
    title: "Termos e Condições",
    back_button: "Voltar"
  }
}