import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import NavBar from '../components/nav_bar';
import s01_01 from '../../images/s01_01.svg';
import s01_02 from '../../images/s01_02.svg';
import s01_03 from '../../images/s01_03.svg';
import s01_04 from '../../images/s01_04.svg';
import s01_05 from '../../images/s01_05.svg';
import s01_06 from '../../images/s01_06.svg';
import s01_07 from '../../images/s01_07.svg';
import s01_08 from '../../images/s01_08.svg';


class Why extends Component {
  render() {
    const { t } = this.props;
    return (
      <NavBar>
        <div className="o_container">
          <h1 className="title_2">{t('text_pages.why.section1_title')}</h1>

          <div className="why-page__intro">
            <p className="text_large">
            {t('text_pages.why.section1_paragraph')}
            </p>
            <p className="text_large">
            {t('text_pages.why.section1_paragraph2')}
            </p>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section2_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section2_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_01} alt={t('text_pages.why.image_s01_01')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section3_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section3_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_02} alt={t('text_pages.why.image_s01_02')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section4_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section4_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_03} alt={t('text_pages.why.image_s01_03')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section5_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section5_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_05} alt={t('text_pages.why.image_s01_05')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section6_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section6_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_04} alt={t('text_pages.why.image_s01_04')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section7_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section7_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_07} alt={t('text_pages.why.image_s01_04')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section8_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section8_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_08} alt={t('text_pages.why.image_s01_08')} />
            </div>
          </div>

          <div className="why-page__section">
            <div className="section__text">
              <h5 className="title_5 section__title">{t('text_pages.why.section9_title')}</h5>
              <p className="text_large">
              {t('text_pages.why.section9_paragraph')}
              </p>
            </div>

            <div className="section__image">
              <img src={s01_06} alt={t('text_pages.why.image_s01_06')} />
            </div>
          </div>
        </div>
      </NavBar>
    );
  }
}
export default withTranslation() (Why);
