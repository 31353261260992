export default {
  activity_show:{
    instructions_section: "What are we gonna do?",
    materials_section: "What do we need?",
    prepare_section: "Let's prepare the activity",
    play_section: "Let's Play",
    watch_section: "Watch me closely...",
    alert_section: "Be careful!",
    suggested_materials_section: "Some useful materials for this activity",
    back_button: "Back",
    remarks_modal_title: "What did I do in this activity?",
    evaluate_modal_title: "What did I think of this activity?",
    activity_card_tried_button: "Tried"
  },
}