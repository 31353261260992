import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";

import { cookiesPolicyGet } from "../../redux/actions/consents";

import { toast } from 'react-toastify';

import NavBar from '../../components/nav_bar';

import { withTranslation } from "react-i18next";

class CookiesPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consent: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.props
      .dispatch(cookiesPolicyGet())
      .then((success) => {
        const { consent } = success.payload.data;
        this.setState({ consent: consent });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  render() {
    const { t } = this.props;
    const { content, version } = this.state.consent;
    console.log(this.state.consent);
    return (
      <NavBar>
        <div className="o_container o_container--medium">
          <h1 className="o_container__header title_3">{t('gdpr.cookies_policy.title')}</h1>
          <p>{t('gdpr.cookies_policy.version')} {version}</p>
          <div className="form_consents">
            <ReactMarkdown source={content} />
            <div className="form_consents_footer form_consents_footer--terms">
              <Link to="/" role="button">
                <button type="button" name="button" className="button tertiary_button large_button">{t('gdpr.cookies_policy.back_button')}</button>
              </Link>
            </div>
          </div>
        </div>
      </NavBar>
    );
  }
}

export default connect(() => ({}))(withTranslation()(CookiesPolicy));
