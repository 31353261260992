export default {
  login: {
    title: "Welcome to Babygrow!",
    email_input: "Email",
    password_input: "Password",
    reset_password: "Reset password",
    login: "Log In",
    register: "Sign Up"
  },
  register: {
    title: "Sign Up",
    gender_male: "Male",
    gender_female: "Female",
    name_input: "Name",
    email_input: "Email",
    password_input: "Password",
    password_confirmation_input: "Confirm password",
    accept_terms1: "I accept the",
    terms_link: "Terms and Conditions",
    accept_terms2: "and the",
    privacy_link: "Privacy Policy",
    register_button: "Sign Up"
  },
  reset_password: {
    title: "Recover Password",
    email_input: "Email",
    recover_button: "Recover"
  },
  change_password: {
    title: "Change password",
    new_password_input: "New password",
    confirm_password_input: "Confirm new password"
  },
  accept_invitation: {
    title: "Accept Invitation",
    gender_male: "Male",
    gender_female: "Female",
    name_input: "Name",
    password_input: "Password",
    password_confirmation_input: "Confirm password",
    accept_terms1: "I accept the",
    terms_link: "Terms and Conditions",
    accept_terms2: "and the",
    privacy_link: "Privacy Policy",
    confirm_button: "Confirm"
  }
}