import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from "../../../components/user_nav_bar";
import BiographyComponent from "../../../components/biography";
import Avatar from "../../../components/avatar";
import ProfilePageListItem from "../../../components/profile_page_list_item";
import CaregiverSelect from "../../../components/caregiverSelect";
import Skills from "../../../components/skills";

import { genderHelper,  skillKindHelper} from "../../../helpers/utilities";
import { kidAddCaregiver, kidRemoveCaregiver } from "../../../redux/actions/kid";
import { kidGetSkills } from "../../../redux/actions/kid";
import { withTranslation } from "react-i18next";

class Step2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBiography: true
    };
  }


  updateSkills = () => {
    this.setState({showBiography: false})
  };


  editKid = () => {
    let kidId = this.props.kid.list[this.props.kid.chosen].id
    this.props.history.push(`/profile/kid/${kidId}/edit`);
  };

  renderContent = () => {
    const { t } = this.props;
    const kid = this.props.kid.list[this.props.kid.chosen]

    if (this.state.showBiography) {
      return (
        <>
          <div className="week_form_header week_form_header--left">
            <h1 className="title_3">{t('week_form.step2.title')}</h1>
          </div>
          <div className="profile_kid_bio">
            <h2 className="title_5">{t('week_form.step2.biography_title')}</h2>
            <p>{t('week_form.step2.biography_subtitle')}</p>
          </div>
          <BiographyComponent onBasicInfoClick={this.editKid} />
          <div className="profile_kid_button_wrapper">
            <button
              onClick={this.updateSkills}
              className="button secondary_button large_button"
            >
              {t('week_form.step2.update_button')}
            </button>
          </div>
          <div className="week_form_buttons week_form_buttons--double">
            <button type="button" name="button" className="button large_button tertiary_button" onClick={() => this.props.nextStep(1)}>{t('week_form.step2.previous_button')}</button>
            <button type="button" name="button" className="button large_button primary_button" onClick={() => this.props.nextStep(3)}>{t('week_form.step2.next_button')}</button>
          </div>

        </>
      );
    } else {
      return (
        <Skills
          doneCallback={() => this.setState({showBiography: true})}/>
      );
    }


  }


  render() {

    return (
      <div className="o_container o_container--medium">

        {this.renderContent()}

      </div>
    );
  }
}

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Step2));
