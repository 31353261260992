import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import logo_purple from "../../images/logo_purple.png";
import logo_white from "../../images/logo_white.png";

import { kidChange } from "../redux/actions/kid";

import { history } from "../helpers/routing";

import Footer from '../components/footer';

import { withTranslation } from "react-i18next";

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Constants from "../helpers/constants";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        menuOpened: false,
        navbarType: null
    };
    this.toggleClass = this.toggleClass.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  toggleClass() {
    this.setState({ menuOpened: !this.state.menuOpened });
    $(".burger_menu").toggleClass('open');
    if (this.state.menuOpened) {
      enableBodyScroll(this.targetElement);
    } else {
      disableBodyScroll(this.targetElement);
    }
  };

  handleWindowResize() {
    if (window.innerWidth < 600) {
      this.setState({navbarType: 'mobile'});
    } else {
      this.setState({navbarType: 'desktop'})
    }
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    this.targetElement = document.querySelector('#navbar');
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    clearAllBodyScrollLocks();
  };

  render() {
    const { t } = this.props;
    const locale = Constants.locale;
    //LOGGED IN?
    var logged_in = this.props.user.name != "" ? true : false;

    //NAVBAR THEME
    var nav_bar_theme = this.props.theme;
    var logo_theme = "";

    if (nav_bar_theme == "white") {
      nav_bar_theme = "nav-bar_bg_white";
      logo_theme = logo_purple;
    } else {
      nav_bar_theme = "nav-bar_bg_purple";
      logo_theme = logo_white;
    }

    let avatar = "";
    try {
      avatar = this.props.user.avatar.thumb.url;
    } catch (e) {}

    return (
      <>
      <div className="container">
        <div className="nav-bar" id="navbar">
          <div className={"nav-bar__main " + nav_bar_theme}>
            <Link to="/" className="nav-bar__main__logo">
              <img src={logo_theme} alt="Babygrow" />
            </Link>

            <div className="nav-bar__right">
              <div className={this.state.menuOpened === true && this.state.navbarType === 'mobile' ? 'navbar__list--mobile': "nav-bar__links__container"}>
                <a href="/blog" className="nav-bar__link regular_button_text" target="_blank">
                  {t('global.nav.blog')}
                </a>
                <a href="https://www.etsy.com/shop/babygrowshop" className="nav-bar__link regular_button_text" target="_blank">
                  {t('global.nav.store')}
                </a>
                {locale === 'pt' &&
                  <a href="/partners" className="nav-bar__link regular_button_text" target="_blank">
                    {t('global.nav.partners')}
                  </a>
                }
                {!logged_in && (
                  <>
                  <Link to="/register" className="nav-bar__link regular_button_text">
                    {t('global.nav.register')}
                  </Link>
                  <Link to="/login" className="nav-bar__link regular_button_text">
                    {t('global.nav.login')}
                  </Link>
                  </>
                )}
              </div>

              <div className="burger_menu" onClick={this.toggleClass}>
                <span></span>
              </div>

              {logged_in && (
                <div>
                  <Link to="/home" className="nav-bar__main__profile-info">
                    <div
                      style={{ backgroundImage: `url(${avatar})` }}
                      alt="Babygrow"
                      className="nav-bar__main__profile-info__avatar"
                    />
                    <p className="nav-bar__main__profile-info__name">
                      {this.props.user.name}
                    </p>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="nav-bar__hidden"></div>

        {this.props.children}
      </div>

      <Footer />
      </>
    );
  }
}

const mapStateToProps = ({ kid, user }) => ({
  user,
  kid,
});

export default connect(mapStateToProps)(withTranslation() (NavBar));
