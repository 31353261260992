import React, { Component } from 'react';
import { caregiverAvatar, kidAvatar } from "../helpers/avatar";
import { withTranslation } from "react-i18next";

class ChooseAvatarModal extends Component{
  constructor(props) {
    super(props)
    this.state = {
        file: null,
        clickedAvatar: null,
        clickedPhoto: null,
    }
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.handleClickedAvatar = this.handleClickedAvatar.bind(this);
    this.handleClickedPhoto = this.handleClickedPhoto.bind(this);
  }

  uploadPhoto(e) {
    this.setState({file: e.target.files[0], clickedPhoto: true});
  };

  handleClickedAvatar(key){
    this.setState({clickedAvatar: key, clickedPhoto: null});
  };

  handleClickedPhoto(e){
    this.setState({clickedPhoto: true, clickedAvatar: null});
  };

  handleConfirmModal = async () => {
    const { confirmModal, cancelModal, kind } = this.props;
    const { file, clickedAvatar, clickedPhoto } = this.state;

    let avatarList = kind == "kid" ? kidAvatar : caregiverAvatar;

    if (clickedAvatar != null) {
      let response = await fetch(avatarList[clickedAvatar].imgUrl);
      let localAvatar = await response.blob();

      confirmModal(localAvatar);
    } else if (clickedPhoto) {
      confirmModal(file);
    } else {
      cancelModal();
    }
  }

  render(){
    const { hideModal, showAvatarModal, message, cancelModal, kind, t } = this.props;
    const { file, clickedAvatar, clickedPhoto } = this.state;

    let avatarList = kind == "kid" ? kidAvatar : caregiverAvatar;

    let avatar = avatarList.map((avatar, key) =>
      <button type="button" className={clickedAvatar === key ? "modal__avatar-img active__avatar" : "modal__avatar-img"} onClick={() => this.handleClickedAvatar(key)} key={avatar.id} style={{backgroundImage: `url(${avatar.imgUrl})`}}></button>
    );

    let imgPreview, fileUrl;
    if (file) {
      fileUrl = URL.createObjectURL(file);
      imgPreview = <button type="button" className={clickedPhoto ? "modal__avatar-img modal__upload-img active__avatar" : "modal__avatar-img modal__upload-img"} onClick={(e) => this.handleClickedPhoto(e)} style={{backgroundImage: `url(${fileUrl})`}}></button>
    };

    if(!showAvatarModal){
      return null;
    }

    return (
      <div className="modal__background modal__background--avatar">
        <div className="modal__container">
          <div className="modal__header">
            <button type="button" className="modal__close" onClick={() => hideModal()}><i className="fal fa-times"></i></button>
            <p className="modal__text regular_button_text">{message}</p>
          </div>

          <div className="modal__avatar">
            {avatar}
          </div>

          <form className="modal__upload">
            <div className="modal__upload-button">
              <label htmlFor="button_photo" className="button secondary_button small_button"><i className="far fa-plus"></i>{t('modals.avatar_modal.add_photo')}</label>
              <input type="file" id="button_photo" onChange={this.uploadPhoto} />
            </div>

            {imgPreview}
          </form>

          <div className="modal__buttons">
            <button type="button" className="button tertiary_button large_button cancel_button" onClick={() => cancelModal()}>{t('modals.avatar_modal.cancel_button')}</button>
            <button type="button" className="button primary_button large_button next_button" onClick={this.handleConfirmModal}>{t('modals.avatar_modal.save_button')}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation() (ChooseAvatarModal);
