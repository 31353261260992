export default {
  show: {
    title: "My profile",
    edit_button: "Edit",
    kids_title: "Children",
    logout_button: "Log out"
  },
  edit: {
    title: "Edit profile",
    gender_male: "Male",
    gender_female: "Female",
    name_input: "Name",
    email_input: "Email",
    change_password_title: "Change password",
    new_password_input: "New password",
    confirm_new_password_input: "Confirm new password",
    save_button: "Save",
    receive_emails: "Subscribe Emails"
  },
  kid_edit_profile: {
    title_male: "Edit {{name}}'s profile",
    title_female: "Edit {{name}}'s profile",
    gender_male: "Boy",
    gender_female: "Girl",
    name_input: "Name",
    date_input: "Birthday",
    birth_date1: "Was born at",
    birth_date2: "weeks",
    save_button: "Save"
  },
  kid_show: {
    title_male: "Edit {{name}}'s profile",
    title_female: "Edit {{name}}'s profile",
    bio_title: "My biography",
    bio_subtitle: "Tap on the paragraph you wish to update.",
    update_button: "Anything new with me?",
    caregivers_title: "Caretakers",
    back_button: "Back"
  }
}
