import React, { Component } from 'react';
import SkillOption from './skill_option';

import { withTranslation } from "react-i18next";

class AddSkill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_button: "Fantástico! Confirmar",
    };
  }

  componentDidMount(){
    const { t } = this.props;
    var nice_word = [t('components.add_skill_component.nice_word1'),t('components.add_skill_component.nice_word2'),t('components.add_skill_component.nice_word3')];
    nice_word = nice_word[Math.floor(Math.random()*nice_word.length)];
    this.setState({
      confirm_button : nice_word+ t('components.add_skill_component.confirm_button')
    });
  }

  componentWillUnmount(){
    window.scrollTo(0,0);
  }

  showButtonWithInfo(){
    const { t } = this.props;
    let n_checked = 0;
    const list = this.props.options;
    let options = Object.keys(list).map((key, i) => {
      if( list[key].checked ) {
        n_checked++;
      }
    });

    if (n_checked < 1) {
      return null;
    } else {
      let info = null;
      if (n_checked == 1){
        info = <p className='add-skills__info'> {t('components.add_skill_component.options_alert')}</p>
      }
      return (
        <>
        <button
          className="add-skills__confirm-button button proceed_button small_button"
          onClick={() => this.nextStepClick()}>
          {this.state.confirm_button} <i className="button_icon_right fas fa-arrow-right"></i></button>
        { info }
        </>
      );
    }
  }

  nextStepClick(){
    window.scrollTo(0,0);
    let list = [];
    if (this.state.options) {
      list = this.state.options
    } else {
      list = this.props.options;
    }
    this.props.nextStep(list.filter(opt => opt.checked));
  }

  setOption(checked, key){
    let list = this.props.options;
    list[key].checked = checked;
    this.setState({
      options: list
    })
  }

  getOptions(){
    const list = this.props.options;
    let options = Object.keys(list).map((key, i) =>
      <SkillOption key={i} option={list[key]} value={key} setOption={(checked, key) => this.setOption(checked, key)}/>
    );

    return options;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="add-skills">
        <h3 className="title_4 text--extra-bold center">{this.props.title}...</h3>
        <div className="add-skills__options">
          {this.getOptions()}
        </div>

        <div className="add-skills__confirm-container">
          {this.showButtonWithInfo()}
        </div>
      </div>
    );
  }
}
export default withTranslation() (AddSkill);
