import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import Avatar from "../../components/avatar";

import { withTranslation } from "react-i18next";

class AddAnotherKid extends Component {

  _list_kids = () => {
      if (this.props.kid.list.length > 0) {
        let list = this.props.kid.list.map((kid, key) =>
        <div className="kid_profile" key={key}>
          <Avatar kind="kid" editable={false} kidId={kid.id} />
          <h2 className="title_6">{kid.name}</h2>
        </div>
        )
        return (
          list
        );
      }
  };

  render() {
    const { t } = this.props;
    return (
      <UserNavBar>
        <div className="o_container o_container--medium o_container--add_kid">
          <h1 className="title_6 center">{t('wizard.add_another_kid.title')}</h1>
            <div className="kids_wrapper">
              {this._list_kids()}
              <Link to="/wizard/kid" className="kid_add_button_wrapper">
                <button type="button" className="kid_add_button">
                  <i className="fal fa-plus"></i>
                </button>
              </Link>
            </div>
          <div className="submit__buttons submit__buttons--center">
            <Link to="/home" className="button tertiary_button large_button" role="button">
              {t('wizard.add_another_kid.notnow_button')}
            </Link>
            {/*<Link to="/wizard/kid" className="button primary_button small_button" role="button">
              Seguinte
            </Link>*/}
          </div>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (AddAnotherKid));
