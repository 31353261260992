import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import { genderHelper,  skillKindHelper, getGender} from "../helpers/utilities";
import { kidGetSkills } from "../redux/actions/kid";
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import Constants from "../helpers/constants";

class Biography extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { chosen, list } = this.props.kid;

    dispatch(kidGetSkills(list[chosen].id));
  }

  handleBasicInfoClick = (event) => {
    event.preventDefault();
    const { onBasicInfoClick } = this.props;

    if (onBasicInfoClick) {
      onBasicInfoClick();
    }
  }

  render() {
    const { t } = this.props;
    const locale = Constants.locale;
    const { chosen, list, skills } = this.props.kid;
    const kid = list[chosen];

    console.log("Biography RENDER", this.props.kid);

    return (
      <>
      <div className="biography__paragraph">
        <Link to="" onClick={this.handleBasicInfoClick}>
          <h3 className="title_4">
            {t('components.kid_bio_component.name') }<span>{kid.name}</span>, {t('components.kid_bio_component.birthdate')}<span>{moment(kid.birthdate).locale(locale).format(t('date.formats.long'))}</span> {t('components.kid_bio_component.weeks_1')}<span>{kid.gestation} {t('components.kid_bio_component.weeks_2')}</span> {t('components.kid_bio_component.gender_part1', {context: getGender(kid.gender)})}<span>{t('components.kid_bio_component.gender_part2', {context: getGender(kid.gender)})}</span>.
          </h3>
        </Link>
      </div>

      {skillKindHelper(skills, 0) &&
        <div className="biography__paragraph">
          <Link to={`/profile/kid/${kid.id}/edit_skills/0`}>
            <h3 className="title_4">{t('components.kid_bio_component.communication_skills')} <span>{skillKindHelper(skills, 0)}</span>.</h3>
          </Link>
        </div>
      }

      {skillKindHelper(skills, 1) &&
        <div className="biography__paragraph">
          <Link to={`/profile/kid/${kid.id}/edit_skills/1`}>
            <h3 className="title_4">{t('components.kid_bio_component.perception_skills')} <span>{skillKindHelper(skills, 1)}</span>.</h3>
          </Link>
        </div>
      }

      {skillKindHelper(skills, 2) &&
        <div className="biography__paragraph">
          <Link to={`/profile/kid/${kid.id}/edit_skills/2`}>
            <h3 className="title_4">{t('components.kid_bio_component.time_skills')} <span>{skillKindHelper(skills, 2)}</span>.</h3>
          </Link>
        </div>
      }

      {skillKindHelper(skills, 3) &&
        <div className="biography__paragraph">
          <Link to={`/profile/kid/${kid.id}/edit_skills/3`}>
            <h3 className="title_4">{t('components.kid_bio_component.interaction_skills')} <span>{skillKindHelper(skills, 3)}</span>.</h3>
          </Link>
        </div>
      }
      </>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Biography));
