import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from "../../components/user_nav_bar";
import Avatar from "../../components/avatar";

import { userEdit } from "../../redux/actions/user";

import { toast } from 'react-toastify';

import { showErrorToasts } from "../../helpers/utilities";
import { withTranslation } from "react-i18next";

class ProfileEdit extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    const { name, email, gender,receive_emails } = user;

    this.state = {
      name: name,
      email: email,
      gender: gender,
      receive_emails: receive_emails,
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
    };
  }

  editProfile = (event) => {
    event.preventDefault();

    const { dispatch, history, t } = this.props;
    const {
      name,
      email,
      gender,
      receive_emails,
      password,
      newPassword,
      newPasswordConfirmation,
    } = this.state;

    dispatch(userEdit(name, email, gender, receive_emails, password, newPassword, newPasswordConfirmation))
      .then((success) => {
        toast.success(
          t('global.toast_success.profile_update')
        )
      })
      .catch((error) => {
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });

    // this.props.history.replace('profile/edit');
  };

  // handleInputChange = (event) => {
  //   const { name, checked, value } = event.target;
  //
  //   this.setState({
  //     [name]: checked ? checked : value,
  //   });
  // };

  handleInputChange = (event) => {
    console.log("!!!", event.target);
    const { name, checked, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleGender(value) {
    this.setState({ gender: value });
  };

  handleReceiveEmails(value) {
    this.setState({ receive_emails: !value });
  };

  render() {
    const { t } = this.props;
    const {
      name,
      email,
      gender,
      receive_emails,
      password,
      newPassword,
      newPasswordConfirmation,
    } = this.state;

    return (
      <UserNavBar>
        <div className="o_container o_container--small">
          <h1 className="title_6 text--extra-bold center">{t('profile.edit.title')}</h1>
          <div className="o_container__header o_container__header--avatar">
            <Avatar kind="user" editable={true} />
          </div>
          <form className="form_login" onSubmit={this.editProfile}>
            <div className="kid__gender-container">
              <label htmlFor="male" className={gender === 0 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="male" name="gender" value="0" onChange={() => this.handleGender(0)} />
                <p className="small_button_text">{t('profile.edit.gender_male')}</p>
              </label>

              <label htmlFor="female" className={gender === 1 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="female" name="gender" value="1" onChange={() => this.handleGender(1)} />
                <p className="small_button_text">{t('profile.edit.gender_female')}</p>
              </label>
            </div>
            <label className="form_input">
              {t('profile.edit.name_input')}
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                autoComplete="username"
                className="input_text"
              />
            </label>
            <label className="form_input">
              {t('profile.edit.email_input')}
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                autoComplete="email"
                className="input_text"
              />
            </label>
            <h4 className="title_6">{t('profile.edit.change_password_title')}</h4>
            {/*<label className="form_input">
              Palavra-passe actual
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="current-password"
                className="input_text"
              />
            </label>*/}
            <label className="form_input">
              {t('profile.edit.new_password_input')}
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                className="input_text"
              />
            </label>
            <label className="form_input">
              {t('profile.edit.confirm_new_password_input')}
              <input
                type="password"
                name="newPasswordConfirmation"
                value={newPasswordConfirmation}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                className="input_text"
              />
            </label>
            <label className="form_input">
              <input
              type="checkbox"
              name="receive_emails"
              value={receive_emails}
              onChange={() => this.handleReceiveEmails(receive_emails)}
              checked={receive_emails}
              />
              <span className="u-pl">{t('profile.edit.receive_emails')}</span>
            </label>
            <div className="form_login_bottom">
              <input
                type="submit"
                value={t('profile.edit.save_button')}
                className="button large_button primary_button"
              />
            </div>
          </form>
        </div>
      </UserNavBar>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(withTranslation() (ProfileEdit));
