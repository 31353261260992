import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import Avatar from '../../components/avatar';

import { kidCreate } from "../../redux/actions/kid";
import { kidAvatar } from "../../helpers/avatar"

import { showErrorToasts } from "../../helpers/utilities";
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

class Kid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      avatar: null,
      name: "",
      gender: "",
      birthdate: "",
      gestation: "40",
      activeGenre: null,
    };
  }

  async componentDidMount() {
    // loading default avatar file
    let response = await fetch(kidAvatar[0].imgUrl);
    let localAvatar = await response.blob();

    this.setState({ avatar: localAvatar });
    
  }

  createKid = (event) => {
    event.preventDefault();

    console.log("!!!", event);

    const { dispatch, history, t } = this.props;
    const { name, birthdate, gender, gestation, avatar } = this.state;

    dispatch(kidCreate(name, birthdate, gender, gestation, avatar))
      .then((success) => {
        // const { status, data } = success.payload
        // if (data.full_messages) {
        //   showErrorToasts(data.full_messages);
        // } else {
          history.replace("/wizard/biography");
        // }
        // history.push("/wizard/biography");
      })
      .catch((error) => {
        // console.log("error", error);
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }

      });
  };

  handleInputChange = (event) => {
    console.log("!!!", event.target);
    const { name, checked, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleActiveGenre(value){
    this.setState({activeGenre: value});
  };

  handleAvatarChange = (avatar) => {
    this.setState({ avatar: avatar });
  }

  render() {
    const { t } = this.props;
    const { name, birthdate, gestation, avatar } = this.state;

    return (
      <>
        <UserNavBar>
          <div className="o_container o_container--small">
            <h1 className="title_6 center">{t('wizard.kid_register.title')}</h1>
            <div className="o_container__header o_container__header--avatar">
              <Avatar kind="kid" editable={true} handleSubmit={this.handleAvatarChange} customAvatarFile={avatar} />
            </div>

            <form onSubmit={this.createKid}>
              <div className="kid__gender-container">
                <label htmlFor="male" className={this.state.activeGenre === 0 ? "kid__gender-button active__gender" : "kid__gender-button"} onClick={() => this.handleActiveGenre(0)}>
                  <input type="radio" id="male" name="gender" value="0" onChange={this.handleInputChange}/>
                  <p className="small_button_text">{t('wizard.kid_register.gender_male')}</p>
                </label>

                <label htmlFor="female" className={this.state.activeGenre === 1 ? "kid__gender-button active__gender" : "kid__gender-button"} onClick={() => this.handleActiveGenre(1)}>
                  <input type="radio" id="female" name="gender" value="1" onChange={this.handleInputChange}/>
                  <p className="small_button_text">{t('wizard.kid_register.gender_female')}</p>
                </label>
              </div>

              <div className="form_input">
              <label htmlFor="name">{t('wizard.kid_register.name_input')}</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  autoComplete="username"
                  className="input_text"
                />
              </div>

              <div className="form_input">
                <label htmlFor="birthdate">{t('wizard.kid_register.date_input')}</label>
                <input
                  type="date"
                  name="birthdate"
                  value={birthdate}
                  onChange={this.handleInputChange}
                  className="input_text"
                />
              </div>

              <div className="form_input kid__input-weeks">
              <label>{t('wizard.kid_register.birth_date1')}</label>
              <input
                type="number"
                name="gestation"
                value={gestation}
                onChange={this.handleInputChange}
                className="input_text"
              />
              <label>{t('wizard.kid_register.birth_date2')}</label>
              </div>

              <div className="submit__buttons submit__buttons--center">
                <input type="submit" value={t('wizard.kid_register.next_button')} className="button primary_button large_button" />
              </div>
            </form>
          </div>
        </UserNavBar>
      </>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  app,
});

export default connect(mapStateToProps)(withTranslation() (Kid));
