import React, { Component } from 'react';
import { connect } from "react-redux";
import { Emojis } from "../helpers/emojis";

import { activityUpdateRating } from "../redux/actions/activity";

import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

class EvaluateModal extends Component{
  constructor(props) {
  super(props);
    this.state = {
      clickedIcon: ""
    }
  };

  componentDidMount() {
    this.setState({clickedIcon: this.props.activity.rating});
  }


  handleClickedIcon(key){
    const { t } = this.props;
    let currentIcon = null;

    if(this.state.clickedIcon != key){
      currentIcon = key;
    }
    console.log("------------", key);
    this.props.dispatch(activityUpdateRating(this.props.kid.list[this.props.kid.chosen].id, this.props.activity.id, currentIcon ))
      .then((success) => {
        console.log("!!!", success);
        this.setState({clickedIcon: currentIcon});
        this.props.handleEvaluate(currentIcon);
      })
      .catch((error) => {
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  render(){
    const {hideModal, show_evaluate_modal, message, activity, t} = this.props;

    console.log("activity", activity, this.state.clickedIcon);
    let emojis = Emojis.map((emojis, key) =>
    <button type="button" className="modal__emojis-button" key={emojis.id} onClick={() => this.handleClickedIcon(key)}>
      {console.log("->", key)}
      <div className="modal__emojis-button-container">
        <i className={this.state.clickedIcon === key ? emojis.iconSelected : emojis.icon}></i>
        <p className="overline_text">{t(emojis.text)}</p>
      </div>
    </button>
    );

    if(!show_evaluate_modal){
      return null;
    }

    return (
      <div className="modal__background modal__background--text">
        <div className="modal__container">
          <div className="modal__header">
            <button type="button" className="modal__close" onClick={() => hideModal()}><i className="fal fa-times"></i></button>
            <p className="modal__text regular_button_text">{message}</p>
          </div>

          <div className="modal__emojis">
            {emojis}
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps) (withTranslation() (EvaluateModal));
