export default {
  error_boundary: {
    title: "500",
    subtitle: "Ups, algo correu mal!",
    back_button: "Voltar à home"
  },
  not_found: {
    title: "404",
    subtitle: "Ups, página não encontrada!",
    back_button: "Voltar à home"
  }
}