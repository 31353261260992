import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class ProfilePageListItem extends Component {

  render() {
    const { buttonCallback, resendCallback, person, type, t } = this.props;
    var avatar;
    var first_line, second_line;
    var button = `${t('components.profile_page_list_item.remove_button')}`;
    var invitation_styles = "";

    if (type == "invitation") {
      //randon avatar on invitations??
      avatar = require('images/caregiver/caregiver_avatar_01.png');
      first_line = t('components.profile_page_list_item.invite_sent');
      invitation_styles = "profile-page-list__invitation";
    } else if (type == "caretaker") {
      first_line = person.name;
      avatar = person.avatar.medium.url;
    } else {
      first_line = person.name;
    };

    if (type == "child") {
      avatar = person.avatar.medium.url;
      second_line = person.birthdate;
      button = t('components.profile_page_list_item.edit_button');
    } else {
      second_line = person.email;
    };

    return (
      <div className="profile-page-list-item">
        <div className="profile-page-list-item__info">
          <div className="profile-page-list__avatar" style={{ backgroundImage: `url(${avatar})` }}></div>

          <div className={"profile-page-list-item__details " + invitation_styles}>
            <p className="small_button_text">{first_line}</p>
            <p>{second_line}</p>
          </div>
        </div>
        <div className="profile-page-list-item__button-wrapper">
          {type == "invitation" &&
              <button
              type="button"
              className="profile-page-list-item__button button tertiary_button small_button"
              onClick={() => resendCallback ? resendCallback(person.id) : null}
            >
              {t('components.profile_page_list_item.resend_button')}
            </button>
          }
          <button
            type="button"
            className="profile-page-list-item__button button secondary_button small_button"
            onClick={() => buttonCallback ? buttonCallback(person.id) : null}
          >
            {button}
          </button>
        </div>
      </div>
    );
  }
}
export default withTranslation() (ProfilePageListItem);
