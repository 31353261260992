import {
  KID_GET_LIST_SUCCESS,
  KID_SET_LIST,
  KID_CREATE_SUCCESS,
  KID_EDIT_AVATAR_SUCCESS,
  KID_CHANGE,
  KID_CHANGE_BY_ID,
  KID_EDIT_SUCCESS,
  KID_ADD_CAREGIVER_SUCCESS,
  KID_REMOVE_CAREGIVER_SUCCESS,
  KID_GET_SKILLS_SUCCESS,
  KID_ADD_SKILLS_SUCCESS,
} from "../actions/kid";

import {
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
} from "../actions/user";

const initialState = {
  chosen: 0,
  list: [],
  skills: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KID_GET_LIST_SUCCESS: {
      const list = action.payload.data;
      return { ...state, list };
    }
    case KID_SET_LIST: {
      return { ...state, list: action.list };
    }
    case KID_EDIT_AVATAR_SUCCESS: {
      const { data: kid } = action.payload;

      return {
        ...state,
        list: state.list.map((entry) => (entry.id === kid.id ? kid : entry)),
      };
    }
    case KID_CHANGE: {
      return { ...state, chosen: action.index };
    }
    case KID_CHANGE_BY_ID: {
      let index = state.list.findIndex((e) => e.id == action.id)
      return { ...state, chosen: index };
    }
    case KID_CREATE_SUCCESS: {
      // const { list } = action.payload.data.data;
      console.log("***", state.list);
      let aux_list = state.list;
      aux_list.push(action.payload.data);
      console.log("***1", aux_list);
      let chosen = 0;
      if (aux_list.length > 0) {
        chosen = aux_list.length - 1;
      }
      return { ...state, list: aux_list, chosen: chosen };
    }
    case KID_EDIT_SUCCESS: {
      const kid = action.payload.data;

      return {
        ...state,
        list: state.list.map((el) => (el.id === kid.id ? kid : el)),
      };
    }
    case KID_ADD_CAREGIVER_SUCCESS: {
      const id = action.meta.previousAction.payload.kidId;
      const user = action.payload.data;

      return {
        ...state,
        list: state.list.map((el) =>
          el.id === id ? { ...el, users: [...el.users, user] } : el
        ),
      };
    }
    case KID_REMOVE_CAREGIVER_SUCCESS: {
      const id = action.meta.previousAction.payload.kidId;
      const caregiverId = action.meta.previousAction.payload.caregiverId;

      return {
        ...state,
        list: state.list.map((el) =>
          el.id === id
            ? { ...el, users: el.users.filter((c) => c.id !== caregiverId) }
            : el
        ),
      };
    }
    case KID_ADD_SKILLS_SUCCESS:
    case KID_GET_SKILLS_SUCCESS: {
      return { ...state, skills: action.payload.data.skills };
    }
    case USER_LOGIN_SUCCESS:
    case USER_REGISTER_SUCCESS: {
      return { ...state, list: action.payload.data.kids };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
