import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";

import { consentsGet, consentsAccept } from "../../redux/actions/consents";

import { toast } from 'react-toastify';

import { withTranslation } from "react-i18next";

class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consents: [],
      acceptedConsents: [],
      kindRoutes: {
        0: "/terms",
        1: "/cookiesPolicy",
        2: "/privacyPolicy",
      },
    };
  }

  componentDidMount() {
    const { dispatch, history, t } = this.props;
    dispatch(consentsGet())
      .then((success) => {
        const { consents } = success.payload.data;

        if (consents.length) {
          this.setState({
            consents: consents,
            acceptedConsents: consents.map(() => false),
          });
        } else {
          history.replace("/home");
        }
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  handleConsentsCheckedChange = (event, index) => {
    const { checked } = event.target;
    const { acceptedConsents } = this.state;

    this.setState({
      acceptedConsents: acceptedConsents.map((consented, i) =>
        index === i ? checked : consented
      ),
    });
  };

  acceptConsents = (event) => {
    event.preventDefault();

    const { dispatch, history } = this.props;
    dispatch(consentsAccept())
      .then((success) => {
        history.replace("/home");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  renderInputs = () => {
    const { consents, acceptedConsents } = this.state;

    return consents.map((consent, index) => {
      const { kind_s, version, kind } = consent;
      const route = this.state.kindRoutes[kind];

      return (
        <label key={index} className="form_consents_checkbox">
          <label className="control control_checkbox label_text">
            <input
              type="checkbox"
              name={`acceptedConsents[${index}]`}
              value={acceptedConsents[index]}
              onChange={(event) => this.handleConsentsCheckedChange(event, index)}
            />
            <div className="control_indicator"></div>
          </label>
          <Link to={route} target="_blank" className="">
            {kind_s}
          </Link>
          <span>versão {version}</span>
        </label>
      );
    });
  };

  render() {
    const { t } = this.props;
    const { acceptedConsents } = this.state;

    return (
      <div className="o_container o_container--medium o_container_consents">
        <h1 className="o_container__header title_3">{t('gdpr.show_consents.title')}</h1>
        <h2 className="title_5">{t('gdpr.show_consents.consents')}</h2>
        <form className="form_consents" onSubmit={this.acceptConsents}>
          {this.renderInputs()}
          <div className="form_consents_footer">
            <input
              type="submit"
              disabled={acceptedConsents.indexOf(false) != -1}
              value={t('gdpr.show_consents.accept_button')}
              className="button primary_button small_button"
            />
            <Link to="/" role="button">
              <button type="button" name="button" className="button tertiary_button large_button">{t('gdpr.show_consents.back_button')}</button>
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(() => ({}))(withTranslation() (Terms));
