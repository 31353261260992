export default {
  header_title: "Vamos brincar!",
  header_text: "Com o Babygrow, pais e cuidadores de crianças dos 0 aos 3 anos, recebem semanalmente ideias para brincarem juntos, seguindo os interesses da criança e promovendo o seu crescimento livre, a nível motor, intelectual e emocional.",
  header_button: "Vamos lá!",
  section1_title: "Porquê o Babygrow?",
  section1_subtitle1: "Brincar é fundamental",
  section1_text1: "É a brincar que o bebé aprende o mundo. Ao explorar, manipular, observar, interagir, o bebé descobre-se a si e às suas capacidades, simultaneamente descobrindo e agindo sobre o mundo que o rodeia.",
  section1_subtitle2: "Os 3 primeiros anos são cruciais",
  section1_text2: "Nos 3 primeiros anos as crianças absorvem o mundo através dos sentidos todos. É o tempo da vida em que há maior potencial de aprendizagem!",
  section1_subtitle3: "Como brincar?",
  section1_text3: "As brincadeiras sugeridas pelo Babygrow são revistas por peritos em educação de infância. Pretendem potenciar, através do brincar, as capacidades exploratórias, investigativas (o que é isto? o que é que isto faz? o que é que eu posso fazer com isto?) e comunicacionais das crianças.",
  section1_subtitle4: "Ir para além dos brinquedos comerciais",
  section1_text4: "Ao incentivar a criança a interagir com objetos do dia a dia de diferentes texturas, formas e tamanhos, o Babygrow oferece ao bebé uma interação muito mais rica, sem os custos associados à compra de brinquedos comerciais.",
  section1_subtitle5: "Proporcionar situações",
  section1_text5: "A possibilidade dada às crianças para explorar e experimentar, fá-las acreditar em si próprias e nas suas conquistas pessoais e permite-lhes crescer nas suas aprendizagens. Brincadeiras que exploram a multisensorialidade, provocam respostas e linguagens múltiplas por parte das crianças.",
  section1_subtitle6: "Todos podem participar!",
  section1_text6: "As brincadeiras sugeridas são também ideais para os avós ou amas de dia realizarem com a criança. Desta forma pode garantir que o tempo que o seu bebé passa com outros adultos é enriquecedor e positivo para o seu crescimento.",
  section1_button: "Quero saber mais!",
  section2_title: "Vamos brincar?",
  section2_subtitle: "As nossas brincadeiras baseiam-se em 3 pressupostos:",
  section2_text1: "Interações serenas, sensíveis, numa postura atenta e responsiva aos sinais de comunicação da criança",
  section2_text2: "Criar situações lúdicas de exploração e descoberta",
  section2_text3: "Privilegiar o contacto com o natural através de brincadeiras simples, apoiando e expandindo as experiências da criança",
  section2_button: "Quero saber mais!",
  section3_title: "Como funciona?",
  section3_subtitle1: "Registe o seu filho na plataforma",
  section3_text1: "Insira algumas informações básicas para que as brincadeiras sejam adequadas ao seu bebé! Pode adicionar à sua conta todos os cuidadores da criança, para que todos tenham acesso a brincadeiras divertidas para fazer a qualquer altura!",
  section3_subtitle2: "Receba as brincadeiras",
  section3_text2: "Todas as semanas irá receber 3 brincadeiras especialmente escolhidas para o seu filho! As brincadeiras têm por base materiais comuns do dia-a-dia e é necessário pouca ou nenhuma preparação. Terá ainda acesso às brincadeiras das semanas anteriores para poderem repetir sempre que quiserem.",
  section3_subtitle3: "Brinque e observe",
  section3_text3: "Observe como brinca o seu bebé e como reage às situações! Insira as suas notas na plataforma para que as brincadeiras da semana seguinte sejam ainda mais adequadas ao seu filho!",
  section3_button: "Quero saber mais!",
  image_header: "Família",
  image_s01_01: "Brincar é fundamental",
  image_s01_02: "Os 3 primeiros anos são cruciais",
  image_s01_03: "Como brincar",
  image_s01_05: "Evitar brinquedos comerciais",
  image_s01_04: "Proporcionar situações",
  image_s01_06: "Todos podem participar",
  image_s02_01: "Atividades",
  image_s03_01: "Registo",
  image_s03_02: "Recebe brincadeiras",
  image_s03_03: "Observar"
}
