import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class TextModal extends Component{
  render(){
    const {hideModal, show_text_modal, message, cancelModal, confirmModal, t} = this.props;
    if(!show_text_modal){
      return null;
    }
    return (
      <div className="modal__background modal__background--text">
        <div className="modal__container">
          <div className="modal__header">
            <button type="button" className="modal__close" onClick={() => hideModal()}><i className="fal fa-times"></i></button>
            <p className="modal__text regular_button_text">{message}</p>
          </div>

          <div className="modal__buttons">
            <button type="button" className="button tertiary_button large_button cancel_button" onClick={() => cancelModal()}>{t('modals.text_modal.cancel_button')}</button>
            <button type="button" className="button primary_button large_button next_button" onClick={() => confirmModal()}>{t('modals.text_modal.confirm_button')}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation() (TextModal);
