import {
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_SET_TOKENS,
  USER_EDIT_SUCCESS,
  USER_EDIT_AVATAR_SUCCESS,
} from "../actions/user";

const initialState = {
  id: null,
  email: "",
  name: "",
  gender: "",
  receive_emails: "",
  accessToken: null,
  client: null,
  uid: null,
  avatar: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_EDIT_SUCCESS:
    case USER_EDIT_AVATAR_SUCCESS:
    case USER_LOGIN_SUCCESS:
    case USER_REGISTER_SUCCESS: {
      const { id, email, name, avatar, gender, receive_emails } = action.payload.data;
      return { ...state, id, email, name, avatar, gender, receive_emails };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case USER_SET_TOKENS: {
      const { accessToken, client, uid } = action;
      if (accessToken) {
        return { ...state, accessToken, client, uid };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
