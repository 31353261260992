import { COOKIES_POLICY_ACCEPT } from "../actions/consents";

const initialState = {
  acceptedCookiesPolicy: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COOKIES_POLICY_ACCEPT: {
      return { ...state, acceptedCookiesPolicy: true };
    }
    default:
      return state;
  }
};
