import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../../redux/store";

import UserNavBar from '../../components/user_nav_bar';

import ReactMarkdown from 'react-markdown';

import { activityGet, activityUpdateCheck, activityUpdateRemarks} from "../../redux/actions/activity";
import EvaluateModal from "../../components/evaluate_modal";
import RemarksModal from "../../components/remarks_modal";

import { Emojis } from "../../helpers/emojis";

import { toast } from 'react-toastify';

import { withTranslation } from "react-i18next";

class Show extends Component {

  constructor(props) {
    super(props);

    this.state = {
      kidActivity: { activity: { remarks: [] }, merchant_products: []},
      show_evaluate_modal: false,
      show_remarks_modal: false,
      // remarks: [
      //   {id: 1, value: "Deitei de barriga para baixo", isChecked: false, isEditable: false},
      //   {id: 2, value: "Virei para o lado", isChecked: false, isEditable: false}
      // ],
    };
  }

  componentDidMount(){
    this.getActivity()
  }

  getActivity = () => {

    const { t } = this.props;
    let { id } = this.props.match.params;

    this.props.dispatch(activityGet(this.props.kid.list[this.props.kid.chosen].id, id))
      .then((success) => {
        console.log("!!!", success.payload.data);

        this.setState({ kidActivity: success.payload.data});
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  buttonCallback(id) {
    const { t } = this.props;
    this.props.dispatch(activityUpdateCheck(this.props.kid.list[this.props.kid.chosen].id, id, !this.state.kidActivity.checked ))
      .then((success) => {
        console.log("success", success);
        this.setState({ kidActivity: success.payload.data});
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  handleEvaluate(rating){
    console.log("rating", rating);
    // let aux = this.state.kidActivity;
    // aux.activity.rating = rating;
    this.setState({kidActivity: {...this.state.kidActivity, rating: rating}});
  };

  handleCheckRemarks(e){
    console.log("handleCheckRemarks", e);
    let checkedRemark = null;
    let remarks_aux = [];

    this.state.kidActivity.activity.remarks.forEach(remark => {
      if ("checkbox_" + remark.id == e.target.id) {
        // remark.isChecked = !remark.isChecked;
        checkedRemark = remark;
        checkedRemark.checked = !checkedRemark.checked
      } else {
        checkedRemark = remark;
      }

      remarks_aux.push(checkedRemark);
      // checkedRemark.push(remark);
    });

    // checkedRemark.checked = !checkedRemark.checked
    // this.setState({remarks: checkedRemark});

    let kidActivity = this.state.kidActivity;
    kidActivity.activity.remarks = remarks_aux;

    this.setState({kidActivity: kidActivity })

    // this.props.dispatch(activityUpdateRemark(this.props.kid.list[this.props.kid.chosen].id, this.state.kidActivity.id, checkedRemark ))
    //   .then((success) => {
    //     console.log("success", success);
    //     this.setState({ kidActivity: success.payload.data});
    //   })
    //   .catch((error) => {
    //     // console.log("error", error);
    //     toast.error(
    //       "Aconteceu um erro imprevisto!"
    //     )
    //   });
  };

  updateRemarks() {
    const { t } = this.props;
    let remarks = this.state.kidActivity.activity.remarks
    this.props.dispatch(activityUpdateRemarks(this.props.kid.list[this.props.kid.chosen].id, this.state.kidActivity.id, remarks ))
      .then((success) => {
        console.log("success", success);

        this.setState({
          kidActivity: success.payload.data,
          show_remarks_modal : false
        });

        toast.success(
          t('global.toast_success.info_update')
        )
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  handleAddRemark(){
    console.log("handleAddRemark");
    let newRemarks = this.state.kidActivity.activity.remarks;
    let newId = (newRemarks.length + 1) * -1;
    // newRemarks.push({id: newId, value: "", isChecked: false, isEditable: true})
    this.setState({kidActivity: {...this.state.kidActivity,
      activity: {...this.state.kidActivity.activity,
        remarks: [...this.state.kidActivity.activity.remarks, {id: newId, title: "", checked: true, kid_id: this.state.kidActivity.kid_id}]
    }}});

    this.setState({remarks: newRemarks})
  };

  handleTextRemarks(e){
    console.log("handleTextRemarks");
    let newRemarks = [];

    this.state.kidActivity.activity.remarks.forEach(remark => {
      if ("text_" + remark.id == e.target.id) {
        remark.title = e.target.value;
      }

      newRemarks.push(remark);
    });
    this.setState({kidActivity: {...this.state.kidActivity,
      activity: {...this.state.kidActivity.activity,
        remarks: newRemarks
    }}});
  };

  handleSubmit(id){
    console.log("handleSubmit");
    //console.log(e);
    let newRemarks = [];

    this.state.remarks.forEach(remark => {
      if ("text_" + remark.id == id) {
        remark.isEditable = false;
      }
      newRemarks.push(remark);
    });
    this.setState({remarks: newRemarks});
  };

  _remarks = () => {
    if (this.state.kidActivity.activity.remarks && this.state.kidActivity.activity.remarks.length > 0) {
      return (
        this.state.kidActivity.activity.remarks.filter(remark => remark.kid_id == null).map((remark, index) =>
          <li key={index}>{remark.title}</li>
        )
      );
    } else {
      return "";
    }
  };

  _section = (title, content) => {
    if (content) {
      return (
        <>
          <h2>{title}</h2>
          <ReactMarkdown source={content} />
        </>
      );
    } else {
      return "";
    }
  };

  _merchant_products = () => {
    return this.state.kidActivity.merchant_products.map((merchant_product, index) => {
      return (
        <a key={index} href={merchant_product.url} target="_blank" className="merchant_product--activity" >
          <div>
            <img src={merchant_product.image}/>
            {merchant_product.promo == "" ? "" :
              <p className="merchant_product--caption">
                {merchant_product.name}
                <br></br>
                <span>
                  {this.props.user.id != null ? merchant_product.promo : ""}
                </span>
              </p>
            }
            <p></p>
          </div>
        </a>
      )
    });
  }

  render() {
    const { t } = this.props;
    let { id } = this.props.match.params;

    let kidActivity = this.state.kidActivity;
    let activity = kidActivity.activity;

    let image = "";

    if (activity.cover_image) {
      image = activity.cover_image.large.url;
    }

    let remarked = this.state.kidActivity.activity.remarks.filter(remark => remark.checked == true).length > 0;

    return (
      <UserNavBar>
        <div className="activity_page">
          <div className={`activity_cover activity__background-img--${activity.image_color}`}>
            <img src={image} alt="cover" />
          </div>
          <div className="activity_header o_container o_container--activity">
            <h1>{activity.title}</h1>

            <div className="activity_header_buttons">
              <div className="activity-card__button" onClick={() => this.setState({show_remarks_modal: true})} >
                <i className={ remarked ? "emojis__selected far fa-comment-alt-lines" : "far fa-comment-alt-lines" } ></i>
              </div>
              <div className="activity-card__button" onClick={() => this.setState({show_evaluate_modal: true})} >
                <i className={ kidActivity.rating == null ? "far fa-smile" : Emojis[kidActivity.rating].iconSelected } ></i>
              </div>
              <label
                className={`cta_button button small_button ${kidActivity.checked ? "cta_button_selected" : "cta_button"}`}
                id={kidActivity.id+"_label"}
              >
                <input style={{display: "none"}}
                  onClick={() => this.buttonCallback(kidActivity.id)}
                  type="checkbox"
                  id={kidActivity.id}
                  defaultChecked={kidActivity.checked}
                />
              <i id={kidActivity.id+"_icon"} className="fas fa-check button_icon_left"></i>
                {t('activities.activity_show.activity_card_tried_button')}
              </label>
            </div>
          </div>

          <article className="activity_content o_container o_container--medium">
            <ReactMarkdown source={activity.summary} />
            {this._section(t('activities.activity_show.instructions_section'), activity.description_todo)}
            {this._section(t('activities.activity_show.materials_section'), activity.description_need)}
            {this._section(t('activities.activity_show.prepare_section'), activity.description_prepare)}
            {this._section(t('activities.activity_show.play_section'), activity.description_suggestions)}

            <h2>{t('activities.activity_show.watch_section')}</h2>
            <ul>
              {this._remarks()}
            </ul>
            <ReactMarkdown source={activity.description_play_further} />

            {this._section(t('activities.activity_show.alert_section'), activity.description_alert)}

          </article>

          { this.state.kidActivity.merchant_products && this.state.kidActivity.merchant_products.length > 0 &&
            <div className="activity_merchant_product o_container o_container--medium o_container--merchant_product">
              <h3 className="title_5">{t('activities.activity_show.suggested_materials_section')}</h3>
              <div className="activity_merchant_product_grid">
                {this._merchant_products()}
              </div>
              {/*<p className="activity_merchant_product_note title_6">**Links afiliados</p>*/}
            </div>
          }
          <div className="activity_bottom o_container o_container--activity">
            <Link to="/home">
              <button type="button" name="button" className="button tertiary_button large_button">{t('activities.activity_show.back_button')}</button>
            </Link>
            {/*<button type="button" name="button" className="button large_button primary_button"><i className="far fa-cloud-download button_icon_left"></i>Descarregar PDF</button>*/}
          </div>
          <div className="activity-list__modals" id="modals" style={{position: "fixed", top: "0", left: "0", width: "100%", zIndex: "100"}}>

            {this.state.show_evaluate_modal && <EvaluateModal
              show_evaluate_modal={this.state.show_evaluate_modal}
              hideModal={() => this.setState({show_evaluate_modal : false})}
              handleEvaluate={(e) => this.handleEvaluate(e)}
              activity={this.state.kidActivity}
              message={t('activities.activity_show.evaluate_modal_title')} />}

            <RemarksModal
              show_remarks_modal={this.state.show_remarks_modal}
              hideModal={() => this.setState({show_remarks_modal : false})}
              cancelModal={() => this.setState({show_remarks_modal : false})}
              confirmModal={() => this.updateRemarks()}
              remarks={this.state.kidActivity.activity.remarks}
              handleCheckRemarks={(e) => this.handleCheckRemarks(e)}
              handleAddRemark={() => this.handleAddRemark()}
              handleTextRemarks={(e) => this.handleTextRemarks(e)}
              handleSubmit={(e) => this.handleSubmit(e)}
              message={t('activities.activity_show.remarks_modal_title')} />

          </div>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid, user }) => ({
  kid,
  user
});

export default connect(mapStateToProps)(withTranslation() (Show));
