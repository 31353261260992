// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import en from "./en";
// import de from "./de";
//
// i18n.use(LanguageDetector).init({
//   // we init with resources
//   resources: {
//     en: en,
//     de: de
//   },
//   fallbackLng: "en",
//   debug: false,
//
//   // have a common namespace used around the full app
//   ns: ["translations"],
//   defaultNS: "translations",
//
//   keySeparator: false, // we use content as keys
//
//   interpolation: {
//     escapeValue: false, // not needed for react!!
//     formatSeparator: ","
//   },
//
//   react: {
//     wait: true
//   }
// });
//
// export default i18n;


import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Constants from "../helpers/constants";

import landing_en from "./pages/landing/en";
import landing_pt from "./pages/landing/pt";
import home_en from "./pages/home/en";
import home_pt from "./pages/home/pt";
import global_en from "./pages/global/en";
import global_pt from "./pages/global/pt";
import auth_en from "./pages/auth/en";
import auth_pt from "./pages/auth/pt";
import wizard_en from "./pages/wizard/en";
import wizard_pt from "./pages/wizard/pt";
import modals_en from "./pages/modals/en";
import modals_pt from "./pages/modals/pt";
import components_en from "./pages/components/en";
import components_pt from "./pages/components/pt";
import activities_en from "./pages/activities/en";
import activities_pt from "./pages/activities/pt";
import profile_en from "./pages/profile/en";
import profile_pt from "./pages/profile/pt";
import blog_en from "./pages/blog/en";
import blog_pt from "./pages/blog/pt";
import merchants_en from "./pages/merchants/en";
import merchants_pt from "./pages/merchants/pt";
import gdpr_en from "./pages/gdpr/en";
import gdpr_pt from "./pages/gdpr/pt";
import error_pages_en from "./pages/error_pages/en";
import error_pages_pt from "./pages/error_pages/pt";
import text_pages_en from "./pages/text_pages/en";
import text_pages_pt from "./pages/text_pages/pt";
import week_form_en from "./pages/week_form/en";
import week_form_pt from "./pages/week_form/pt";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          landing: landing_en,
          home: home_en,
          global: global_en,
          auth: auth_en,
          wizard: wizard_en,
          modals: modals_en,
          components: components_en,
          activities: activities_en,
          profile: profile_en,
          blog: blog_en,
          merchants: merchants_en,
          gdpr: gdpr_en,
          error_pages: error_pages_en,
          text_pages: text_pages_en,
          week_form: week_form_en,
          date: {
            formats: {
              default: "D/MM/YYYY",
              long: "D [of] MMMM [of] YYYY",
              short: "D [of] MMMM"
            }
          }
        },
       
      },
      pt: {
        translation: {
          landing: landing_pt,
          home: home_pt,
          global: global_pt,
          auth: auth_pt,
          wizard: wizard_pt,
          modals: modals_pt,
          components: components_pt,
          activities: activities_pt,
          profile: profile_pt,
          blog: blog_pt,
          merchants: merchants_pt,
          gdpr: gdpr_pt,
          error_pages: error_pages_pt,
          text_pages: text_pages_pt,
          week_form: week_form_pt,
          date: {
            formats: {
              default: "D/MM/YYYY",
              long: "D [de] MMMM [de] YYYY",
              short: "D [de] MMMM"
            }
          }
        },
      }
    },
    lng: Constants.locale,
    fallbackLng: "pt",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
