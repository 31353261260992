export default {
  avatar_modal: {
    add_photo: "Add a photo",
    cancel_button: "Cancel",
    save_button: "Save"
  },
  avatar: {
    message: "Choose an avatar or add a photo"
  },
  evaluate_modal:{
    emoji1: "I didn't care for it",
    emoji2: "I wasn't very interested",
    emoji3: "I liked it",
    emoji4: "I loved it"
  },
  evaluate_week_modal: {
    emoji1: "Grumpy",
    emoji2: "So-so",
    emoji3: "Joyful",
    emoji4: "Very happy"
  },
  remarks_modal:{
    add_button_placeholder: "What else did I do?",
    cancel_button: "Cancel",
    save_button: "Confirm"
  },
  text_modal: {
    cancel_button: "Cancelar",
    confirm_button: "Confirmar"
  }
}