import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userResetPassword } from "../../redux/actions/user";

import NavBar from '../../components/nav_bar';

import { toast } from 'react-toastify';

import { withTranslation } from "react-i18next";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  recoverPassword = (event) => {
    event.preventDefault();

    const { dispatch, history, t } = this.props;
    const { email } = this.state;

    dispatch(userResetPassword(email))
      .then((success) => {

        // const { status, data } = success.payload
        // if (data.full_messages) {
        //   // showErrorToasts(data.full_messages);
        //   toast.error(
        //     "Ocorreu um erro! Por favor verifique se o email está correcto."
        //   )
        // } else {
        toast.success(
          t('global.toast_success.reset_password_instructions')
        )
        history.replace("/login");
        // }
      })
      .catch((error) => {
        // console.log("error", error);
        const { status, data } = error.error.response
        if (data.full_messages) {
          toast.error(
            t('global.toast_error.incorrect_email')
          )
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });
  };

  handleInputChange = (event) => {
    const { name, checked, value } = event.target;

    this.setState({
      [name]: checked ? checked : value,
    });
  };

  render() {
    const { t } = this.props;
    const { email } = this.state;

    return (
      <NavBar theme="white">
        <div className="o_container o_container--small">
          <h1 className="o_container__header title_3">{t('auth.reset_password.title')}</h1>
          <form className="form_login" onSubmit={this.recoverPassword}>
            <label className="form_input">
              {t('auth.reset_password.email_input')}
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                required
              />
            </label>
            <div className="form_login_bottom">
              <input type="submit" value={t('auth.reset_password.recover_button')} className="button large_button primary_button"/>
            </div>
          </form>
          {/* <Link
            to="/login"
            className="button primary_button small_button"
            role="button"
          >
            Criar conta
          </Link> */}
        </div>
      </NavBar>
    );
  }
}
// export default Home;

const mapStateToProps = ({ app }) => ({
  app,
});

export default connect(mapStateToProps)(withTranslation() (ResetPassword));
