export default {
  homepage:{
    title: "Hi {{name}}!",
    subtitle_option1: "Here are our suggestions for {{name}}'s to play.",
    subtitle_error1_male: "Happy Birthday, {{name}} is already 4! Unfortunately at this moment we still don't have activites for children from 4 years old.",
    subtitle_error1_female: "Happy Birthday, {{name}} is already 4! Unfortunately at this moment we still don't have activites for children from 4 years old.",
    subtitle_error2_male: "We still don't know enough about {{name}} to be able to give you suggestions for activities. Please go to {{name}}'s profile page and fill in some information!",
    subtitle_error2_female: "We still don't know enough about {{name}} to be able to give you suggestions for activities. Please go to {{name}}'s profile page and fill in some information!",
  }
}
