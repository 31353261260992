export default {
  activity_show: {
    instructions_section: "O que vamos fazer?",
    materials_section: "Do que precisamos?",
    prepare_section: "Vamos preparar a brincadeira",
    play_section: "Vamos brincar?",
    watch_section: "Olha bem para mim...",
    alert_section: "Tem cuidado!",
    suggested_materials_section: "Alguns materiais úteis para esta brincadeira",
    back_button: "Voltar",
    remarks_modal_title: "O que fiz nesta brincadeira?",
    evaluate_modal_title: "O que achei desta brincadeira?",
    activity_card_tried_button: "Experimentei"
  },
}