import merchants from "../../../pages/merchants";

export default{
  activity_card:{
    tried_button: "Tried"
  },
  activity_list: {
    previous_button: "Show previous activities",
    remarks_modal_title: "What did you observe while I played?",
    evaluate_modal_title: "How was my mood while I played?"
  },
  kid_bio_component: {
    name: "Hi, my name is ",
    birthdate: "I was born on ",
    weeks_1: " at ",
    weeks_2: "weeks",
    gender_part1_male: " and I am",
    gender_part1_female: " and I am",
    gender_part2_male: " a boy",
    gender_part2_female: " a girl",
    communication_skills: "I communicate with others through",
    perception_skills: " I feel my surroundings and I'm able to",
    time_skills: "I spend most of my time",
    interaction_skills: "When I interact with objects I tend to"
  },
  add_skill_component: {
    options_alert: "You can select multiple options",
    nice_word1: "Great",
    nice_word2: "Wow",
    nice_word3: "Wonderful",
    confirm_button: "! Confirm"
  },
  caregiver_component: {
    placeholder: "Type the email of the caretaker you'd like to add",
    add_button: "Add"
  },
  profile_page_list_item: {
    remove_button: "Remove",
    resend_button: "Resend",
    invite_sent: "Invite Sent",
    edit_button: "Edit"
  },
  blog_post_component: {
    read_button: "Read Article"
  },
  merchants_component: {
    see_more_button: "See more"
  }
}