export const Art =[
  {
    name: "Cavalete duplo",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/12"
  },
  {
    name: "Plasticina orgânica",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/13"
  },
  {
    name: "Mesa",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/14"
  },
  {
    name: "Pedras de cera",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/15"
  },
  {
    name: "Giz",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_5.png"),
    buttonUrl: "https://babygrow.pt/merchant_product/16"
  },
  {
    name: "Marcadores",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/17"
  },
  {
    name: "Plasticina mágica UV",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/18"
  },
  {
    name: "Autocolantes",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/19"
  },
  {
    name: "Play foam brilha no escuro",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_9.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/105"
  },
  {
    name: "Tinta de dedos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/art/sug_arte_10.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/21"
  },
]

export const Sensations =[
  {
    name: "Mesa sensorial",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/22"
  },
  {
    name: "Mesa sensorial dupla",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/23"
  },
  {
    name: "Areia cinética",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/24"
  },
  {
    name: "Areia mágica",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/25"
  },
  {
    name: "Tabuleiro de madeira",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/26"
  },
  {
    name: "Tabuleiro de plástico",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/27"
  },
  {
    name: "Conjunto para areia",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/28"
  },
  {
    name: "Tabuleiro grande",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/29"
  },
  {
    name: "Baldes",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_9.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/30"
  },
  {
    name: "Mesa sensorial grande",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/sensations/sug_sensorial_10.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/31"
  },
]

export const OpenEnd =[
  {
    name: "Pedras de madeira",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/32"
  },
  {
    name: "Blocos descobrimentos",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/33"
  },
  {
    name: "Cilindro com bolas",
    brand: "Papai Tó",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/34"
  },
  {
    name: "Pirâmide colorida",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/35"
  },
  {
    name: "Arco-íris duplo",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/36"
  },
  {
    name: "Kit para bebés",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/37"
  },
  {
    name: "Peças amarelas",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/38"
  },
  {
    name: "Blocos de madeira",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/39"
  },
  {
    name: "Sólidos geométricos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_9.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/40"
  },
  {
    name: "Botões coloridos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_10.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/41"
  },
  {
    name: "Cilindro com sino e mordedor",
    brand: "Papai Tó",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_11.png"),
    buttonUrl: "https://babygrow.pt/merchant_product/42"
  },
  {
    name: "Mesa de construção",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_12.png"),
    buttonUrl: "https://babygrow.pt/merchant_product/43"
  },
  {
    name: "Tubos coloridos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_13.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/44"
  },
  {
    name: "Cilindros coloridos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_14.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/45"
  },
  {
    name: "Peças de madeira",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/open_end/sug_aberto_15.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/46"
  },
]

export const Care =[
  {
    name: "Kit de jardineiro",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/47"
  },
  {
    name: "Kit para plantar",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/48"
  },
  {
    name: "Regador",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/49"
  },
  {
    name: "Carrinho de mão",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/50"
  },
  {
    name: "Kit de jardim",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/51"
  },
  {
    name: "Kit de jardim",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/52"
  },
  {
    name: "Prensador de folhas e flores",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/53"
  },
  {
    name: "Kit para plantar",
    brand: "Ludicenter",
    age: "",
    imgUrl: require("../../images/gifts/care/sug_cuidar_8.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/102"
  },
]

export const Music =[
  {
    name: "Xilofone de arrasto",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/54"
  },
  {
    name: "Piano",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/55"
  },
  {
    name: "Kit música",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/56"
  },
  {
    name: "Guitarra",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/57"
  },
  {
    name: "Acordeão",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/58"
  },
  {
    name: "Xilofone",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/59"
  },
  {
    name: "Tambor",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/60"
  },
  {
    name: "Bateria",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/music/sug_musica_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/61"
  },
]

export const Movement =[
  {
    name: "Triângulo de inspiração Pikler",
    brand: "Papai Tó",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/103"
  },
  {
    name: "Prancha de balanço",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/63"
  },
  {
    name: "Tábua redonda",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/64"
  },
  {
    name: "Cubo para escalar",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/65"
  },
  {
    name: "Cubo colorido",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/66"
  },
  {
    name: "Arco para escalar",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/67"
  },
  {
    name: "Arco grande",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/68"
  },
  {
    name: "Prancha de balanço com corda",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/69"
  },
  {
    name: "Balancé",
    brand: "Papai Tó",
    age: "",
    imgUrl: require("../../images/gifts/movement/sug_movimento_9.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/104"
  },
]

export const Autonomy =[
  {
    name: "Torre de aprendizagem",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/70"
  },
  {
    name: "Kit de limpeza",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/71"
  },
  {
    name: "Escada para altura",
    brand: "Amazon.es",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/72"
  },
  {
    name: "Kit para limpar a mesa",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/73"
  },
  {
    name: "Vassoura",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/74"
  },
  {
    name: "Kit para limpar vidros",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/autonomy/sug_autonomia_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/75"
  },
]

export const Light =[
  {
    name: "Quadro de luz A1",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/76"
  },
  {
    name: "Quadro de luz A2",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/77"
  },
  {
    name: "Mesa de luz",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/78"
  },
  {
    name: "Tabuleiro de luz A2",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/79"
  },
  {
    name: "Conjunto de peças translucidas",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/80"
  },
  {
    name: "Conjunto de formas geométricas",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/81"
  },
  {
    name: "Conjunto de peças magnéticas translucidas",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/82"
  },
  {
    name: "Espelho para experimentação",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/83"
  },
  {
    name: "Blocos de madeira",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_9.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/84"
  },
  {
    name: "Discos metálicos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/light/sug_luz_10.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/85"
  },
]

export const Cook =[
  {
    name: "Kit de Pasteleiro",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/86"
  },
  {
    name: "Kit para cortar fruta",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_2.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/87"
  },
  {
    name: "Kit para ovos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_3.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/88"
  },
  {
    name: "Kit para maçãs",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_4.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/89"
  },
  {
    name: "Kit para líquidos",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/90"
  },
  {
    name: "Medidor",
    brand: "Mumuchu",
    age: "",
    imgUrl: require("../../images/gifts/cook/sug_cozinhar_6.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/91"
  },
]

export const Books =[
  {
    name: "ABC - Livro sensorial",
    brand: "Amazon.es",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_1.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/92"
  },
  {
    name: "O monstro das cores - Livro popup",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_2.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/93"
  },
  {
    name: "Onde está a zebra? - Livro sensorial",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_3.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/94"
  },
  {
    name: "Gosto de ti (quase sempre)",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_4.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/95"
  },
  {
    name: "Livros de imagens reais",
    brand: "Tartaruguita",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_5.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/96"
  },
  {
    name: "Vamos jogar?",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_6.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/97"
  },
  {
    name: "Shapes - Livro sensorial",
    brand: "Amazon.es",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_7.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/98"
  },
  {
    name: "Vehicles - Livro sensorial",
    brand: "Amazon.es",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_8.jpg"),
    buttonUrl: "https://babygrow.pt/merchant_product/99"
  },
  {
    name: "Perdido e achado",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_9.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/100"
  },
  {
    name: "A lagartinha muito comilona",
    brand: "Wook",
    age: "",
    imgUrl: require("../../images/gifts/books/sug_livros_10.jpeg"),
    buttonUrl: "https://babygrow.pt/merchant_product/101"
  },
]
