export const GET_MERCHANTS = "GET_MERCHANTS";

export const getMerchants = () => ({
  type: GET_MERCHANTS,
  payload: {
    request: {
      method: "get",
      url: "/merchants/",
    },
  },
});
