import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../components/user_nav_bar';
import ActivityList from "../components/activity_list";

import { userLogin, userLogout } from "../redux/actions/user";
import {activityGetList} from "../redux/actions/activity"
import { kidGetList } from "../redux/actions/kid"

import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

import { genderHelper, skillKindHelper, isKidTooOld, getGender} from "../helpers/utilities";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      next_page: 1,
      timestamp: new Date().getTime()
    };
  }

  componentDidMount(){
    const { kid, activity, history } = this.props;

    if (kid.list.length === 0) {
      history.replace('/wizard/kid');
    } else if (activity.kid_activities.length == 0){
      this.activities_list();
      this.kidsList();
      console.log("DEBBUUUG", isKidTooOld(new Date(kid.list[kid.chosen].birthdate)));
    } else {
      this.setState({
        next_page: activity.page + 1,
        timestamp: new Date().getTime()
      });
    }
  }

  kidsList = () => {
    const { t } = this.props;
    this.props.dispatch(kidGetList())
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  activities_list(){
    const { dispatch, history, kid, t } = this.props;
    dispatch(activityGetList(kid.list[kid.chosen].id, this.state.next_page))
      .then((success) => {
        console.log("success", success)
        let data = success.payload.data;
        if (data.week_form) {
          toast.info(
            <span onClick={() => {
              toast.dismiss("weekForm");
              history.push(`/week_form/${data.week_form.id}`)}}>
              {t('global.toast_info.update_activites')}
            </span>,
            {autoClose: false, closeOnClick: false, toastId: "weekForm"}
          )
        }
        this.setState({
          next_page: data.page + 1,
          timestamp: new Date().getTime()
        });
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  // logout = () => {
  //   const { dispatch, history } = this.props;
  //   dispatch(userLogout())
  //     .then((success) => {
  //       // console.log("success", success);
  //     })
  //     .catch((error) => {
  //       // console.log("error", error);
  //       toast.error(
  //         "Aconteceu um erro imprevisto!", {toastId: "unknown_error"}
  //       )
  //     });
  //   history.replace("/");
  // };

  render() {
    const { t } = this.props;
    const { name, email } = this.props.user;
    const { kid_activities, next_page } = this.props.activity;
    const chosen_kid  = this.props.kid.list[this.props.kid.chosen];
    // const listActivities = this.props.activity.kid_activities.map((a) => <p>{a.title}</p>  );

    if (this.props.kid.list.length === 0) {
      return null;
    }
    let subtitle = `${t('home.homepage.subtitle_option1', {context: getGender(chosen_kid.gender), name: chosen_kid.name})}`;
    if (isKidTooOld(new Date(chosen_kid.birthdate))) {
      subtitle = `${t('home.homepage.subtitle_error1', {context: getGender(chosen_kid.gender), name: chosen_kid.name})}`;
    } else if (kid_activities.length == 0 && next_page != 1) {
      subtitle = `${t('home.homepage.subtitle_error2', {context: getGender(chosen_kid.gender), name: chosen_kid.name})}`;
    }

    return (
      <UserNavBar>
        <div className="feed__background">
          <div className="feed__background__header o_container o_container--feed">
            <h1 className="title_1">{t('home.homepage.title', {name: name})}</h1>
            <h4 className="title_4">{subtitle}</h4>
          </div>
        </div>
        {/*listActivities*/}
        <div className="o_container o_container--feed feed__content">
          <ActivityList kid_activities={kid_activities} timestamp={this.state.timestamp} showNextWeek={next_page} showMoreActivitiesCallback={() => this.activities_list()} />
        </div>
     </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid, user, activity }) => ({
  kid,
  user,
  activity
});

export default connect(mapStateToProps)(withTranslation()(Home));
