import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { skillGetList, kidAddSkill } from "../../redux/actions/kid";
import UserNavBar from '../../components/user_nav_bar';
// import AddSkill from '../../components/add_skill';
import Skills from '../../components/skills';

class KidSkillEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // kind: this.props.match.params.kind,
      // title: "",
      // options: []
    };
  }

  componentDidMount() {
    document.body.classList.add("color__background");

    // const { dispatch } = this.props;
    // const { kind } = this.state;
    //
    // dispatch(skillGetList(kind))
    //   .then((success) => {
    //     console.log("got", success);
    //     this.setState({title: success.payload.data.title, options: success.payload.data.list});
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  }

  // updateKidSkills = (skills) => {
  //   const { dispatch, history, kid } = this.props;
  //
  //   dispatch(kidAddSkill(kid.list[kid.chosen].id, skills.map(s => s.id)))
  //     .then((success) => {
  //       console.log("got", success);
  //       history.replace(`/profile/kid/${kid.list[kid.chosen].id}`);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //
  // };

  componentWillUnmount() {
    document.body.classList.remove("color__background");
  };

  render() {
    // const { title, options } = this.state;
    const { dispatch, history, kid } = this.props;

    let skillsIds = this.props.match.params.kind == -1 ? null : [this.props.match.params.kind];

    return (
      <UserNavBar>
        <div className="o_container o_container--medium">
          <Skills
            skillsIds={skillsIds}
            doneCallback={() => history.replace(`/profile/kid/${kid.list[kid.chosen].id}`)}/>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(KidSkillEdit);
