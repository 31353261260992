import React, { Component } from 'react';
import TextModal from "../../components/text_modal";
import ChooseAvatarModal from "../../components/choose_avatar_modal";
import EvaluateModal from "../../components/evaluate_modal";
import RemarksModal from "../../components/remarks_modal";

class ModalGuide extends Component{
  constructor(props) {
    super(props);
    this.state = {
      show_text_modal : false,
      show_avatar_modal : false,
      show_evaluate_modal : false,
      show_remarks_modal : false,
      remarks: [
        {id: 1, value: "Deitei de barriga para baixo", isChecked: false, isEditable: false},
        {id: 2, value: "Virei para o lado", isChecked: false, isEditable: false}
      ],
    };
  }

  handleCheckRemarks(e){
    let checkedRemark = [];

    this.state.remarks.forEach(remark => {
      if ("checkbox_" + remark.id == e.target.id) {
        remark.isChecked = !remark.isChecked;
      }
      checkedRemark.push(remark);
    });
    this.setState({remarks: checkedRemark});
  };

  handleAddRemark()
  {
    let newRemarks = this.state.remarks;
    let newId = newRemarks.length + 1;
    newRemarks.push({id: newId, value: "", isChecked: false, isEditable: true})

    this.setState({remarks: newRemarks})
  };

  handleTextRemarks(e){
    let newRemarks = [];

    this.state.remarks.forEach(remark => {
      if ("text_" + remark.id == e.target.id) {
        remark.value = e.target.value;
      }
      newRemarks.push(remark);
    });
    this.setState({remarks: newRemarks});
  };

  handleSubmit(id){
    //console.log(e);
    let newRemarks = [];

    this.state.remarks.forEach(remark => {
      if ("text_" + remark.id == id) {
        remark.isEditable = false;
      }
      newRemarks.push(remark);
    });
    this.setState({remarks: newRemarks});
  };

  // openModal() {
  //   this.setState({
  //     show : true
  //   });
  // }
  //
  // closeModal() {
  //   this.setState({
  //     show : false
  //   });
  // }

  render() {
    return(
      <div>
        <div style={{position: "absolute"}}>
          <h1 className="title_1"> Components Guide </h1>

          <h6 className="title_6"> Text Modal </h6>
          <button type="button" className="primary_button small_button" onClick={() => this.setState({show_text_modal : true})}>Abrir text modal</button>
          {/*<button onClick={() => this.openModal()}>Abrir modal</button>*/}

          <h6 className="title_6"> Choose Avatar Modal </h6>
          <button type="button" className="primary_button small_button" onClick={() => this.setState({show_avatar_modal : true})}>Abrir choose avatar modal</button>

          <h6 className="title_6"> Evaluate Modal </h6>
          <button type="button" className="primary_button small_button" onClick={() => this.setState({show_evaluate_modal : true})}>Abrir evaluate modal</button>

          <h6 className="title_6"> Remarks Modal </h6>
          <button type="button" className="primary_button small_button" onClick={() => this.setState({show_remarks_modal : true})}>Abrir remarks modal</button>
        </div>
        <div className="modal__wrapper">
          <TextModal show_text_modal={this.state.show_text_modal} hideModal={() => this.setState({show_text_modal : false})} cancelModal={() => this.setState({show_text_modal : false})} confirmModal={() => this.setState({show_text_modal : false})} message="Olá" />

          <ChooseAvatarModal show_avatar_modal={this.state.show_avatar_modal} hideModal={() => this.setState({show_avatar_modal : false})} cancelModal={() => this.setState({show_avatar_modal : false})} confirmModal={() => this.setState({show_avatar_modal : false})} message="Escolha um avatar ou adicione uma foto" />

          {false && <EvaluateModal show_evaluate_modal={this.state.show_evaluate_modal} hideModal={() => this.setState({show_evaluate_modal : false})} cancelModal={() => this.setState({show_evaluate_modal : false})} confirmModal={() => this.setState({show_evaluate_modal : false})} message="Classifique esta brincadeira" />}

          <RemarksModal show_remarks_modal={this.state.show_remarks_modal} hideModal={() => this.setState({show_remarks_modal : false})} cancelModal={() => this.setState({show_remarks_modal : false})} confirmModal={() => this.setState({show_remarks_modal : false})} remarks={this.state.remarks} handleCheckRemarks={(e) => this.handleCheckRemarks(e)} handleAddRemark={() => this.handleAddRemark()} handleTextRemarks={(e) => this.handleTextRemarks(e)} handleSubmit={(e) => this.handleSubmit(e)} message="Observações sobre a brincadeira" />
        </div>
      </div>
    )
  }
}

export default ModalGuide;
