import Environment from "../../environment";

const constants = {
  "development": {
    api_url: "http://localhost:3000",
    sentryDSN: "",
    locale: 'pt'
  },
  "staging": {
    api_url: "https://babygrow.hyp.pt",
    sentryDSN: "https://65a06499f36548089ad4db02d246179c@sentry.hyp.pt/55",
    locale: 'pt'
  },
  "production": {
    api_url: "https://babygrow.pt",
    sentryDSN: "https://7ef08bd71129459599c353302c377020@sentry.hyp.pt/58",
    locale: 'pt'
  },
  "production_en": {
    api_url: "https://babygrow.fun",
    sentryDSN: "https://7ef08bd71129459599c353302c377020@sentry.hyp.pt/58",
    locale: 'en'
  }
}

export default constants[Environment]
