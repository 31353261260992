import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { userLogin } from "../../redux/actions/user";

import NavBar from '../../components/nav_bar';

import { toast } from 'react-toastify';

import { withTranslation } from "react-i18next";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  login = (event) => {
    event.preventDefault();

    const { dispatch, history, t } = this.props;
    const { email, password } = this.state;

    dispatch(userLogin(email, password))
      .then((success) => {
        console.log("success", success);
        const { kids } = success.payload.data;
        if (kids.length > 0) {
          history.replace("/home");
        } else {
          history.replace("/wizard/kid");
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          t('global.toast_error.login_error')
        )
      });
  };

  handleInputChange = (event) => {
    const { name, checked, value } = event.target;

    this.setState({
      [name]: checked ? checked : value,
    });
  };

  render() {
    const { t } = this.props;
    const { email, password } = this.state;

    return (
      <NavBar theme="white">
        <div className="o_container o_container--small">
          <div className="user__avatar-container">
            <div className="user__avatar" style={{backgroundImage: `url(${require('images/caregiver/caregiver_avatar_01.png')})`}}></div>
          </div>
          <h1 className="o_container__header title_3">{t('auth.login.title')}</h1>
          <form className="form_login" onSubmit={this.login}>
            <label className="form_input">
              {t('auth.login.email_input')}
              <input
                type="text"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                autoComplete="username"
                className="input_text"
              />
            </label>
            <label className="form_input">
              {t('auth.login.password_input')}
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="current-password"
                className="input_text"
              />
            </label>

            <Link to="/resetPassword" className="tertiary_button u-pt" role="button">
              {t('auth.login.reset_password')}
            </Link>
            <div className="form_login_bottom">
              <input type="submit" value={t('auth.login.login')} className="button large_button primary_button"/>
              <Link to="/register" className="tertiary_button u-pt-md" role="button">
                {t('auth.login.register')}
              </Link>
            </div>
          </form>
        </div>
      </NavBar>
    );
  }
}
// export default Home;

const mapStateToProps = ({ app }) => ({
  app,
});

export default connect(mapStateToProps)(withTranslation() (Login));
