export default {
  error_boundary: {
    title: "500",
    subtitle: "Ups, something went wrong!",
    back_button: "Back to home"
  },
  not_found: {
    title: "404",
    subtitle: "Ups, page not found!",
    back_button: "Back to home"
  }
}