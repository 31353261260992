import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import BiographyComponent from "../../components/biography";
import Avatar from "../../components/avatar";

import { withTranslation } from "react-i18next";

import { genderHelper,  skillKindHelper, getGender} from "../../helpers/utilities";

class Biography extends Component {

  render() {
    const { t } = this.props;
    const { chosen, list } = this.props.kid;

    const kid = list[chosen];

    return (
      <UserNavBar>
        <div className="o_container o_container--medium">
          <h1 className="title_6 center">{t('wizard.kid_bio.title',{context: getGender(kid.gender), name: kid.name})}.</h1>
          <div className="o_container__header o_container__header--avatar">
            <Avatar kind="kid" editable={false} kidId={list[chosen].id} />
          </div>

          <BiographyComponent/>

          <div className="submit__buttons submit__buttons--center">
            <Link to="/wizard/skills" className="button primary_button large_button" role="button">
              {t('wizard.kid_bio.next_button')}
            </Link>
          </div>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Biography));
