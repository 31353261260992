import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { skillGetList, kidAddSkill } from "../../redux/actions/kid";
import UserNavBar from '../../components/user_nav_bar';
import SkillsComponent from '../../components/skills';

class Skills extends Component {

  constructor(props) {
    super(props);

    this.state = {
      i: 0,
      max: 3,
      title: "",
      options: []
    };
  }

  componentDidMount() {
    document.body.classList.add("color__background");

    // const { dispatch } = this.props;
    // const { i, titles } = this.state;
    //
    // dispatch(skillGetList(i))
    //   .then((success) => {
    //     console.log("got", success);
    //     this.setState({title: success.payload.data.title, options: success.payload.data.list});
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  }

  // updateKidSkills = (skills) => {
  //   const { dispatch, history, kid } = this.props;
  //   const { i, max } = this.state;
  //
  //   dispatch(kidAddSkill(kid.list[kid.chosen].id, skills.map(s => s.id)))
  //     .then((success) => {
  //       console.log("got", success);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //
  //   if (i == max) {
  //     history.replace("/wizard/resume");
  //   }
  //
  //   dispatch(skillGetList(i+1))
  //     .then((success) => {
  //       console.log("got", success);
  //       this.setState({i: i+1, title: success.payload.data.title, options: success.payload.data.list});
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };

  componentWillUnmount() {
    document.body.classList.remove("color__background");
  };

  render() {
    // const { title, options } = this.state;

    return (
      <UserNavBar>
        <div className="o_container o_container--medium">
          <SkillsComponent
            doneCallback={() => this.props.history.replace("/wizard/resume")}/>
        </div>
      </UserNavBar>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(Skills);
