export default {
  user_nav: {
    blog: "Blog",
    store: "Store",
    partners: "Partners"
  },
  nav: {
    blog: "Blog",
    store: "Store",
    partners: "Partners",
    register: "Sign Up",
    login: "Log In"
  },
  footer: {
    blog: "Blog",
    why: "Why Babygrow?",
    want_play: "Want to play?",
    partners: "Partners",
    terms: "Terms & Conditions",
    privacy: "Privacy Policy",
    cookies: "Cookies Policy",
    developer: "created by"
  },
  cookies_bar:{
    text: "By continuing to browse the site, you expressly agree that cookies will be stored on your computer to help measure statistics of visits.",
    accept_button: "I Agree",
    knowmore_button: "Know more"
  },
  toast_success:{
    create_account: "Account created with success!",
    reset_password_instructions: "Instructions have been sent to your email!",
    reset_password_success: "Password changed with success!",
    resend_invite: "Invitation resent with success!",
    avatar_update: "Avatar updated with success!",
    info_update: "Updated with success!",
    profile_update: "Profile updated with success!",
    form_update: "Filled with success!"
  },
  toast_error:{
    unknown_error: "An unknown error happened!",
    accept_terms: "You must accept the terms and conditions and the privacy policy.",
    login_error: "Please check that the email and password are correct.",
    incorrect_email: "An error has occurred! Please check that the email is correct."
  },
  toast_info:{
    update_activites: "Click here to tell us how the activities went",
  }
}