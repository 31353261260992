import React from "react";
import {
  Router,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { store } from "./redux/store";

import { history } from './helpers/routing';

import Landing from "./pages/landing";
import Play from "./pages/play";
import BetaTesters from "./pages/beta_testers";
import Why from "./pages/why_babygrow";
import How from "./pages/how_it_works";
import Home from "./pages/home";
import ShowConsents from "./pages/gdpr/showConsents";
import Terms from "./pages/gdpr/terms";
import CookiesPolicy from "./pages/gdpr/cookiesPolicy";
import PrivacyPolicy from "./pages/gdpr/privacyPolicy";
import Merchants from "./pages/merchants";
import Blog from "./pages/blog";
import ShowBlog from "./pages/blog/show";
import Suggestions from "./pages/suggestions";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ResetPassword from "./pages/auth/resetPassword";
import ChangePassword from "./pages/auth/changePassword";
import AcceptInvitation from "./pages/auth/acceptInvitation";
import NotFound from "./pages/not_found";
import ComponentsGuide from "./pages/components_guide";
import ModalGuide from "./pages/components_guide/modal";
import NavGuide from "./pages/components_guide/nav";
import ProfilePageListItemGuide from "./pages/components_guide/profile_page_list_item";
import ActivityListGuide from "./pages/components_guide/activity_list";
import AddSkillGuide from "./pages/components_guide/add_skill";
import WizardKid from "./pages/wizard/kid";
import WizardBiography from "./pages/wizard/biography";
import WizardSkills from "./pages/wizard/skills";
import WizardResume from "./pages/wizard/resume";
import WizardInvite from "./pages/wizard/invite";
import WizardAddAnotherKid from "./pages/wizard/add_another_kid";
import ActivityShow from "./pages/activities/show";
import WeekForm from "./pages/week_form/index";
import ProfileShow from "./pages/profile/show";
import ProfileEdit from "./pages/profile/edit";
import ProfileKidShow from "./pages/profile/kidShow";
import ProfileKidEdit from "./pages/profile/kidEdit";
import KidSkillEdit from "./pages/profile/kidSkillEdit";

import ScrollToTop from "./components/scroll";

const AuthRoute = ({ component: Component, requiresAuth, ...rest }) => {
  const { id } = store.getState().user;
  return (
    <Route
      {...rest}
      render={(props) =>
        (requiresAuth && id != null) || (!requiresAuth && id == null) ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: requiresAuth ? "/login" : "/home",
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
};

export default (
  <Router history={history}>
    <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/play" exact component={Play} />
        <Route path="/why_babygrow" exact component={Why} />
        <Route path="/how_it_works" exact component={How} />
        <Route path="/beta_testers" exact component={BetaTesters} />

        <Route path="/showConsents" exact component={ShowConsents} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
        <Route path="/cookiesPolicy" exact component={CookiesPolicy} />

        <Route path="/partners" exact component={Merchants} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:slug" exact component={ShowBlog} />

        <Route path="/lista-de-natal" exact component={Suggestions} />

        <AuthRoute path="/login" requiresAuth={false} exact component={Login} />
        <AuthRoute path="/register" requiresAuth={false} exact component={Register} />
        <AuthRoute path="/resetPassword" requiresAuth={false} exact component={ResetPassword} />
        <AuthRoute path="/changePassword" requiresAuth={false} exact component={ChangePassword} />
        <AuthRoute path="/acceptInvitation" requiresAuth={false} exact component={AcceptInvitation} />

        <AuthRoute path="/home" requiresAuth={true} exact component={Home} />
        <AuthRoute path="/activities/:id" requiresAuth={true} exact component={ActivityShow} />
        <AuthRoute path="/week_form/:id" requiresAuth={true} exact component={WeekForm} />

        <AuthRoute path="/wizard/kid" requiresAuth={true} exact component={WizardKid} />
        <AuthRoute path="/wizard/biography" requiresAuth={true} exact component={WizardBiography} />
        <AuthRoute path="/wizard/skills" requiresAuth={true} exact component={WizardSkills} />
        <AuthRoute path="/wizard/resume" requiresAuth={true} exact component={WizardResume} />
        <AuthRoute path="/wizard/invite" requiresAuth={true} exact component={WizardInvite} />
        <AuthRoute path="/wizard/add-another-kid" requiresAuth={true} exact component={WizardAddAnotherKid} />

        <AuthRoute path="/profile" requiresAuth={true} exact component={ProfileShow} />
        <AuthRoute path="/profile/edit" requiresAuth={true} exact component={ProfileEdit} />
        <AuthRoute path="/profile/kid/:id" requiresAuth={true} exact component={ProfileKidShow} />
        <AuthRoute path="/profile/kid/:id/edit" requiresAuth={true} exact component={ProfileKidEdit} />
        <AuthRoute path="/profile/kid/:id/edit_skills/:kind" requiresAuth={true} exact component={KidSkillEdit} />


        <Route path="/components_guide" exact component={ComponentsGuide} />
        <Route path="/components_guide/modal" exact component={ModalGuide} />
        <Route path="/components_guide/nav" exact component={NavGuide} />
        <Route path="/components_guide/profile_page_list_item" exact component={ProfilePageListItemGuide} />
        <Route path="/components_guide/activity_list" exact component={ActivityListGuide} />
        <Route path="/components_guide/add_skill" exact component={AddSkillGuide} />


        <Route component={NotFound} />
      </Switch>
  </Router>

);
