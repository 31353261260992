import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from "../../components/user_nav_bar";
import BiographyComponent from "../../components/biography";
import Avatar from "../../components/avatar";
import ProfilePageListItem from "../../components/profile_page_list_item";
import CaregiverSelect from "../../components/caregiverSelect";
import Skills from "../../components/skills";

import { genderHelper,  skillKindHelper, getGender} from "../../helpers/utilities";
import { kidAddCaregiver, kidRemoveCaregiver, kidResendInviteCaregiver } from "../../redux/actions/kid";
import { kidGetSkills } from "../../redux/actions/kid";

import { toast } from 'react-toastify';
import { showErrorToasts } from "../../helpers/utilities";

import { withTranslation } from "react-i18next";

class KidShow extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      kid: this.props.kid.list.find((kid) => kid.id == id),
      isAddCaretakerVisible: false,
    };
  }

  // componentDidMount() {
  //   console.log("SHOW DID MOUNT");
  //
  //   const { dispatch } = this.props;
  //   const { chosen, list } = this.props.kid;
  //
  //   dispatch(kidGetSkills(list[chosen].id))
  //     .then((success) => {
  //       console.log("got", success);
  //
  //       this.setState({skills: success.payload.data})
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }

  updateSkills = () => {
    this.props.history.push(`/profile/kid/${this.state.kid.id}/edit_skills/-1`);
    // console.log("skills");
  };

  handleAddCaregiverClick = () => {
    console.log("familyMember");
    this.setState({ isAddCaretakerVisible: true });
  };

  editKid = () => {
    this.props.history.push(`/profile/kid/${this.state.kid.id}/edit`);
  };

  addCaregiver = (email) => {
    const { t } = this.props;
    const { id } = this.state.kid;
    console.log(`add caregiver ${email}`);

    this.props
      .dispatch(kidAddCaregiver(id, email))
      .then((success) => {
        this.setState({
          isAddCaretakerVisible: false,
        });
        this.updateKid();
      })
      .catch((error) => {
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });
  };

  updateKid = () => {
    const { t } = this.props;
    const { id } = this.state.kid;

    this.setState({ kid: this.props.kid.list.find((kid) => kid.id == id) });
  };

  removeCaregiver = (caregiverId) => {
    const { id } = this.state.kid;
    const { t } = this.props;

    this.props
      .dispatch(kidRemoveCaregiver(id, caregiverId))
      .then((success) => {
        this.updateKid();
      })
      .catch((error) => {
        // console.log(error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  resendInvite = (caregiverId) => {
    const { t } = this.props;
    const { id } = this.state.kid;

    this.props
      .dispatch(kidResendInviteCaregiver(id, caregiverId))
      .then((success) => {
        // this.updateKid();
        toast.success(
          t('global.toast_success.resend_invite')
        )
      })
      .catch((error) => {
        // console.log(error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  renderFamilyMember = () => {
    const { kid } = this.state;

    return kid.users.map((person, index) => {
      return (
        <ProfilePageListItem
          key={index}
          type={person.name ? "caretaker" : "invitation"}
          person={person}
          buttonCallback={this.removeCaregiver}
          resendCallback={this.resendInvite}
        />
      );
    });
  };

  render() {
    const { kid, isAddCaretakerVisible } = this.state;
    const { t } = this.props;

    return (
      <UserNavBar>
        <div className="o_container o_container--medium-large">
          <h1 className="title_6 text--extra-bold center">{t('profile.kid_show.title',{context: getGender(kid.gender), name: kid.name})}</h1>

          <div className="o_container__header o_container__header--avatar">
            <Avatar kind="kid" editable={true} kidId={kid.id} />
          </div>
          <div className="profile_kid_bio">
            <h2 className="title_6 text--extra-bold">{t('profile.kid_show.bio_title')}</h2>
            <p>{t('profile.kid_show.bio_subtitle')}</p>
          </div>
          <div>
            <BiographyComponent onBasicInfoClick={this.editKid} />
          </div>
          <div className="profile_kid_button_wrapper">
            <button
              onClick={this.updateSkills}
              className="button secondary_button large_button"
            >
              {t('profile.kid_show.update_button')}
            </button>
          </div>
          <hr className="profile-page__separator"></hr>
          <div className="profile-page__header profile-page__header--show">
            <h2 className="title_6 text--extra-bold">{t('profile.kid_show.caregivers_title')}</h2>
            <button className="button profile-page__add-button" onClick={this.handleAddCaregiverClick} ><i className="fal fa-plus"></i></button>
          </div>
          <div className="caregivers_select">
            <CaregiverSelect
              caregivers={[]}
              addCallback={this.addCaregiver}
              isVisible={isAddCaretakerVisible}
            />
          </div>
          {this.renderFamilyMember()}
          <div className="submit__buttons submit__buttons--center">
            <Link to="/profile">
              <button className="button tertiary_button large_button">{t('profile.kid_show.back_button')}</button>
            </Link>
          </div>

          {/* precisa de botão de guardar? */}
          {/* <div className="submit__buttons"></div> */}
        </div>
      </UserNavBar>
    );
  }
}

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (KidShow));
