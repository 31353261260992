export default {
  kid_register: {
    title: "To get started we need some info about your child",
    gender_male: "Boy",
    gender_female: "Girl",
    name_input: "Name",
    date_input: "Date of birth",
    birth_date1: "Born at",
    birth_date2: "weeks",
    next_button: "Next"
  },
  kid_bio: {
    title_male: "We're almost finished! This is the information we have so far about {{name}}",
    title_female: "We're almost finished! This is the information we have so far about {{name}}",
    next_button: "Next"
  },
  kid_resume: {
    title_male: "Well done! This is the biography of {{name}}",
    title_female: "Well done! This is the biography of {{name}}",
    next_button: "Next"
  },
  invite: {
    title_male: "You can invite other caretakers to access and receive our play ideas",
    title_female: "You can invite other caretakers to access and receive our play ideas",
    next_button: "Next"
  },
  add_another_kid: {
    title: "Do you wish to add another child?",
    notnow_button: "Not now"
  }
}