import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import {startLoading, stopLoading} from '../../redux/actions/app';

import ProfilePageListItem from '../../components/profile_page_list_item';

class ProfilePageListItemGuide extends Component {

  render() {
    return (
      <div>
        <h1 className="title_1"> Components Guide </h1>

        <h5 className="title_4">Profile Page List Item</h5>

        <h6 className="title_6">Child</h6>
        <ProfilePageListItem type="child" person={{name: "Maria", birthdate: "05/02/2020", avatar : require('images/kid/kid_avatar_04.png')}}/>
        <h6 className="title_6">Invitation</h6>
        <ProfilePageListItem type="invitation" person={{email: "maria@cuidador.pt"}}/>
        <h6 className="title_6">Caretaker</h6>
        <ProfilePageListItem type="caretaker" person={{name: "Maria", email: "maria@cuidador.pt", avatar : require('images/caregiver/caregiver_avatar_04.png')}}/>
      </div>
    );
  }
}
// export default Home;
//
//
//


const mapStateToProps = ({app}) => ({
  app: app,
});

export default connect(mapStateToProps)(ProfilePageListItemGuide);
