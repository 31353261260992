import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Constants from "../helpers/constants";
import NavBar from '../components/nav_bar';

import header_image from '../../images/header_image.svg';
import s01_01 from '../../images/s01_01.svg';
import s01_02 from '../../images/s01_02.svg';
import s01_03 from '../../images/s01_03.svg';
import s01_04 from '../../images/s01_04.svg';
import s01_05 from '../../images/s01_05.svg';
import s01_06 from '../../images/s01_06.svg';
import s01_07 from '../../images/s01_07.svg';
import s01_08 from '../../images/s01_08.svg';

import { withTranslation } from "react-i18next";

class Landing extends Component {
  render() {
    const { t } = this.props;
    const locale = Constants.locale;
    return (
      <NavBar>
        {/* HEADER */}
        <div className="landing-page__header">
          <div className="landing-page__header__text">
            <h1 className="title_1 header__title">{t('landing.header_title')}</h1>
            <p className="text_large header__text">{t('landing.header_text')}</p>
            <Link to="/register" className="button primary_button large_button" role="button">
              {t('landing.header_button')}
            </Link>
          </div>

          <div className="landing-page__header__image">
            <img src={header_image} alt={t('landing.image_header')} className="header__image" />
            <div className="header__background"></div>
          </div>
        </div>

        {/* PORQUÊ */}
        <div className="l_container--large">
          <h3 className="title_3">{t('landing.section1_title')}</h3>
          <div className="landing-page__inside">
            <div className="section--odd section-1--odd">
              <div className="section__image">
                <img src={s01_01} alt={t('landing.image_s01_01')} />
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle1')}</h5>
                <p className="text_large">{t('landing.section1_text1')}</p>
              </div>
            </div>

            <div className="section--even section-1--even">
              <div className="section__image">
                <img src={s01_02} alt={t('landing.image_s01_02')} />
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle2')}</h5>
                <p className="text_large">{t('landing.section1_text2')}</p>
              </div>
            </div>

            <div className="section--odd section-1--odd">
              <div className="section__image">
                <img src={s01_03} alt={t('landing.image_s01_03')} />
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle3')}</h5>
                <p className="text_large">{t('landing.section1_text3')}</p>
              </div>
            </div>

            <div className="section--even section-1--even">
              <div className="section__image">
                <img src={s01_05} alt={t('landing.image_s01_05')} />
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle4')}</h5>
                <p className="text_large">{t('landing.section1_text4')}</p>
              </div>
            </div>

            <div className="section--odd section-1--odd">
              <div className="section__image">
                <img src={s01_04} alt={t('landing.image_s01_04')}/>
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle5')}</h5>
                <p className="text_large">{t('landing.section1_text5')}</p>
              </div>
            </div>


            <div className="section--even section-1--even">
              <div className="section__image">
                <img src={s01_06} alt={t('landing.image_s01_06')} />
              </div>
              <div className="section__text">
                <h5 className="title_5 section__title">{t('landing.section1_subtitle6')}</h5>
                <p className="text_large">{t('landing.section1_text6')}</p>
              </div>
            </div>
          </div>

          <div className="center">
            <Link to="/why_babygrow" className="button primary_button small_button" role="button">
              {t('landing.section1_button')}
            </Link>
          </div>
        </div>

        {/* BRINCADEIRAS */}
        <div className="l_container--full">
          <div className="section-2__container">
            <div className="landing-page__section-2">
              <h3 className="title_3 center">{t('landing.section2_title')}</h3>
              <p className="text_large center">{t('landing.section2_subtitle')}</p>

              <div className="section-2__text">
                <div>
                  <p className="title_4 text--bold section-2__number number--cyan">1</p>
                  <p className="title_6 text--bold">{t('landing.section2_text1')}</p>
                </div>

                <div>
                  <p className="title_4 text--bold section-2__number number--blue">2</p>
                  <p className="title_6 text--bold">{t('landing.section2_text2')}</p>
                </div>

                <div>
                  <p className="title_4 text--bold section-2__number number--red">3</p>
                  <p className="title_6 text--bold">{t('landing.section2_text3')}</p>
                </div>
              </div>
              <img src={require(`../../images/s02_01_${locale}.svg`)} alt={t('landing.image_s02_01')}/>
            </div>
          </div>

          <div className="center">
            <Link to="/play" className="button primary_button small_button" role="button">
              {t('landing.section2_button')}
            </Link>
          </div>
        </div>

        {/* COMO FUNCIONA */}
        <div className="l_container--large">
          <h3 className="title_3">{t('landing.section3_title')}</h3>
          <div className="landing-page__inside">
            <div className="section--even section-3--even">
              <div className="section__image">
                <img src={require(`../../images/s03_01_${locale}.svg`)} alt={t('landing.image_s03_01')} />
              </div>
              <div className="section__text">
                <p className="title_5 text--bold section-3__number number--cyan">1</p>
                <h5 className="title_5 text--cyan section-3__title">{t('landing.section3_subtitle1')}</h5>
                <p className="text_large">{t('landing.section3_text1')}</p>
              </div>
            </div>

            <div className="section--odd section-3--odd">
              <div className="section__image">
                <img src={require(`../../images/s03_02_${locale}.svg`)} alt={t('landing.image_s03_02')} />
              </div>
              <div className="section__text">
                <p className="title_5 text--bold section-3__number number--red">2</p>
                <h5 className="title_5 text--red section-3__title">{t('landing.section3_subtitle2')}</h5>
                <p className="text_large">{t('landing.section3_text2')}</p>
              </div>
            </div>

            <div className="section--even section-3--even">
              <div className="section__image">
                <img src={require(`../../images/s03_03_${locale}.svg`)} alt={t('landing.image_s03_03')} />
              </div>
              <div className="section__text">
                <p className="title_5 text--bold section-3__number number--blue">3</p>
                <h5 className="title_5 text--blue section-3__title">{t('landing.section3_subtitle3')}</h5>
                <p className="text_large">{t('landing.section3_text3')}</p>
              </div>
            </div>
          </div>

          <div className="center">
            <Link to="/how_it_works" className="button primary_button small_button" role="button">
              {t('landing.section3_button')}
            </Link>
          </div>
        </div>

        {/* PREÇOS */}
        {/*<div className="l_container--large landing-page__section-4">
          <h3 className="title_3 center">Preços</h3>
          <div className="landing-page__inside">
            <p className="text_large">
              O Babygrow encontra-se ainda na sua versão beta, o que significa que está numa <Link to="/beta_testers" className="text_large email__link">fase de testes</Link> e por isso ainda não na sua versão final. Dado o momento em que vivemos, com a pandemia Covid-19, decidimos colocar a plataforma disponível para uso público, pois consideramos muito importante que os pais e cuidadores que se encontram em casa com crianças dos 0 aos 3 anos, consigam promover situações de exploração e de descoberta.
            </p>
            <p className="text_large">
              Uma vez que nos encontramos ainda numa fase de testes e melhorias à plataforma, pedimos desde já a sua compreensão caso ocorra algum erro ou bug durante a sua utilização da plataforma. Se tal acontecer pode-nos reportar o problema através do endereço de email <a href={"mailto: mail@babygrow.pt"} className="text_large email__link">mail@babygrow.pt</a>.
            </p>
            <p className="text_large">
              Enquanto a plataforma se encontrar em fase beta a sua utilização será completamente gratuita.
            </p>
          </div>*/}
          {/*<div className="landing-page__inside section-4__container">
            <div className="section-4__box section-4__box--red">
              <p className="title_5">1 mês</p>
              <p className="title_3 text--bold">5€</p>
              <p className="section-4__text">Blandit nulla nunc penatibus pulvinar tempus, viverra morbi facilisi nunc sed.</p>
              <Link to="/" className="button primary_button small_button" role="button">
                Brevemente!
              </Link>
            </div>

            <div className="section-4__box section-4__box--yellow">
              <p className="title_5">3 meses</p>
              <p className="title_3 text--bold">12€</p>
              <p className="section-4__text">Blandit nulla nunc penatibus pulvinar tempus, viverra morbi facilisi nunc sed.</p>
              <Link to="/" className="button primary_button small_button" role="button">
                Brevemente!
              </Link>
            </div>

            <div className="section-4__box section-4__box--cyan">
              <p className="title_5">12 meses</p>
              <p className="title_3 text--bold">36€</p>
              <p className="section-4__text">Blandit nulla nunc penatibus pulvinar tempus, viverra morbi facilisi nunc sed.</p>
              <Link to="/" className="button primary_button small_button" role="button">
                Brevemente!
              </Link>
            </div>
          </div>*/}
        {/*</div>*/}
      </NavBar>
    );
  }
}
export default withTranslation()(Landing);
