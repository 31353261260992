import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import {startLoading, stopLoading} from '../../redux/actions/app';

import UserNavBar from '../../components/user_nav_bar';


class NavGuide extends Component {

  render() {
    return (
      <div>
        <h1 className="title_1"> Components Guide </h1>

        <h5 className="title_4">Nav bar</h5>

        <h6 className="title_6">No theme and user with one kid only</h6>
        <UserNavBar />

        <h6 className="title_6">White theme and user with more than one kid</h6>
        <UserNavBar theme="white" />
      </div>
    );
  }
}

const mapStateToProps = ({app}) => ({
  app: app,
});

export default connect(mapStateToProps)(NavGuide);
