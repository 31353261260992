import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Avatar from "../../components/avatar";
import UserNavBar from "../../components/user_nav_bar";

import { userAcceptInvitation } from "../../redux/actions/user";
import { getQueryStringParams } from "../../helpers/routing";
import { caregiverAvatar } from "../../helpers/avatar";

import { showErrorToasts } from "../../helpers/utilities";

import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

class AcceptInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: null,
      name: "",
      gender: null,
      password: "",
      passwordConfirmation: "",
      invitationToken: "",
      termsAccepted: false,
    };
  }

  componentDidMount = async () => {
    // console.log(getQueryStringParams(this.props.location.search));
    const { invitation_token } = getQueryStringParams(
      this.props.location.search
    );

    // loading default avatar file
    let response = await fetch(caregiverAvatar[0].imgUrl);
    let localAvatar = await response.blob();

    this.setState({ invitationToken: invitation_token, avatar: localAvatar });
  };

  acceptInvitation = (event) => {
    event.preventDefault();

    const { dispatch, history } = this.props;
    const {
      avatar,
      name,
      gender,
      password,
      passwordConfirmation,
      invitationToken,
      termsAccepted,
    } = this.state;

    if (termsAccepted) {
      dispatch(
        userAcceptInvitation(
          name,
          gender,
          password,
          passwordConfirmation,
          invitationToken,
          avatar,
          termsAccepted
        )
      )
        .then((success) => {
          // alert("Invitation accepted successfuly");


          // const { status, data } = success.payload
          // if (data.full_messages) {
          //   showErrorToasts(data.full_messages);
          // } else {
          toast.success(
            t('global.toast_success.create_account')
          )
          history.replace("/login");
          // }
        })
        .catch((error) => {
          const { status, data } = error.error.response
          if (data.full_messages) {
            showErrorToasts(data.full_messages);
          } else {
            toast.error(
              t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
            )
          }
        });
    } else {
      // alert("you need to accept the terms first");
      toast.error(t('global.toast_error.accept_terms'));
    }
  };

  handleInputChange = (event) => {
    const { name, checked, value } = event.target;

    this.setState({
      [name]: checked ? checked : value,
    });
  };

  handleAvatarChange = (avatar) => {
    this.setState({ avatar: avatar });
  };

  handleGender(value) {
    this.setState({ gender: value });
  };

  render() {
    const { t } = this.props;
    const {
      avatar,
      name,
      gender,
      password,
      passwordConfirmation,
      termsAccepted,
    } = this.state;

    return (
      <UserNavBar theme="white">
        <div className="o_container o_container--small o_container--relative">
          <Avatar
            kind="user"
            editable={true}
            handleSubmit={this.handleAvatarChange}
            customAvatarFile={avatar}
          />
        <h1 className="o_container__header title_3">{t('auth.accept_invitation.title')}</h1>
          <form className="form_login" onSubmit={this.acceptInvitation}>
            <div className="kid__gender-container">
              <label htmlFor="male" className={gender === 0 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="male" name="gender" value="0" onChange={() => this.handleGender(0)} />
                <p className="small_button_text">{t('auth.accept_invitation.gender_male')}</p>
              </label>

              <label htmlFor="female" className={gender === 1 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="female" name="gender" value="1" onChange={() => this.handleGender(1)} />
                <p className="small_button_text">{t('auth.accept_invitation.gender_female')}</p>
              </label>
            </div>
            <label className="form_input">
              {t('auth.accept_invitation.name_input')}
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                className="input_text"
                required
              />
            </label>
            <label className="form_input">
              {t('auth.accept_invitation.password_input')}
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                required
              />
            </label>
            <label className="form_input">
              {t('auth.accept_invitation.password_confirmation_input')}
              <input
                type="password"
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={this.handleInputChange}
                autoComplete="new-password"
                required
              />
            </label>
            <div className="form_terms">
              <label className="control control_checkbox label_text">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  value={termsAccepted}
                  onChange={this.handleInputChange}
                />
                <div className="control_indicator"></div>
              </label>
              <p>{t('auth.accept_invitation.accept_terms1')}
              <Link to="/terms" target="_blank" className="" role="button">
                {t('auth.accept_invitation.terms_link')}
              </Link>
              {t('auth.accept_invitation.accept_terms2')}
              <Link
                to="/privacyPolicy"
                target="_blank"
                className=""
                role="button"
              >
                {t('auth.accept_invitation.privacy_link')}
              </Link></p>

          </div>
            <div className="form_login_bottom">
              <input
                type="submit"
                value={t('auth.accept_invitation.confirm_button')}
                className="button large_button primary_button"
              />
            </div>
          </form>
        </div>
      </UserNavBar>
    );
  }
}
// export default Home;

const mapStateToProps = ({ app }) => ({
  app,
});

export default connect(mapStateToProps)(withTranslation() (AcceptInvitation));
