import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UserNavBar from '../../components/user_nav_bar';
import Avatar from '../../components/avatar';

import { genderHelper,  skillKindHelper, getGender} from "../../helpers/utilities";
import { kidEdit } from "../../redux/actions/kid";

import { toast } from 'react-toastify';

import { showErrorToasts } from "../../helpers/utilities";

import { withTranslation } from "react-i18next";

class Kid extends Component {

  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    const kid = this.props.kid.list.find(kid => kid.id == id);

    this.state = {
      kid: kid,
      name: kid.name,
      birthdate: kid.birthdate,
      gender: kid.gender,
      gestation: kid.gestation,
    }
  }

  goBack = (event) => {
    event.preventDefault(event);

    this.props.history.goBack();
  }

  editKid = (event) => {
    event.preventDefault();

    console.log("!!!", event);

    const { dispatch, history, t } = this.props;
    const { kid, name, birthdate, gender, gestation } = this.state;

    dispatch(kidEdit(kid.id, name, birthdate, gender, gestation))
      .then((success) => {
        history.goBack();
        toast.success(
          t('global.toast_success.profile_update')
        )
      })
      .catch((error) => {
        const { status, data } = error.error.response
        if (data.full_messages) {
          showErrorToasts(data.full_messages);
        } else {
          toast.error(
            t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
          )
        }
      });
  };

  handleInputChange = (event) => {
    console.log("!!!", event.target);
    const { name, checked, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleGender(value) {
    this.setState({ gender: value });
  };

  render() {
    const { t } = this.props;
    const { kid } = this.state;
    const { name, birthdate, gestation, gender } = this.state;

    return (
      <UserNavBar>
        <div className="o_container o_container--small">
          <div className="o_container__header">
            <h1 className="title_6 text--extra-bold center">{t('profile.kid_edit_profile.title', {context: getGender(kid.gender), name: kid.name})}</h1>
          </div>

          {/* <div className="o_container__header">
            <Avatar kind="kid" editable={true} kidId={kid.id} />
          </div> */}

          <form onSubmit={this.editKid}>
            <div className="kid__gender-container">
              <label htmlFor="male" className={gender === 0 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="male" name="gender" value="0" onChange={() => this.handleGender(0)} />
                <p className="small_button_text">{t('profile.kid_edit_profile.gender_male')}</p>
              </label>

              <label htmlFor="female" className={gender === 1 ? "kid__gender-button active__gender" : "kid__gender-button"}>
                <input type="radio" id="female" name="gender" value="1" onChange={() => this.handleGender(1)} />
                <p className="small_button_text">{t('profile.kid_edit_profile.gender_female')}</p>
              </label>
            </div>

            <div className="form_input">
              <label htmlFor="name">{t('profile.kid_edit_profile.name_input')}</label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                autoComplete="username"
                className="input_text"
              />
            </div>

            <div className="form_input">
              <label htmlFor="birthdate">{t('profile.kid_edit_profile.date_input')}</label>
              <input
                type="date"
                name="birthdate"
                value={birthdate}
                onChange={this.handleInputChange}
                className="input_text"
              />
            </div>

            <div className="form_input kid__input-weeks">
              <label>{t('profile.kid_edit_profile.birth_date1')}</label>
              <input
                type="number"
                name="gestation"
                value={gestation}
                onChange={this.handleInputChange}
                className="input_text"
              />
              <label>{t('profile.kid_edit_profile.birth_date2')}</label>
            </div>

            <div className="submit__buttons submit__buttons--center">
              <input type="submit" value={t('profile.kid_edit_profile.save_button')} className="button primary_button large_button" />
            </div>
          </form>
        </div>
      </UserNavBar>
    );
  }
}

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Kid));
