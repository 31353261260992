export const caregiverAvatar =[
  {
    id: 1,
    imgUrl: require("../../images/caregiver/caregiver_avatar_01.png"),
  },

  {
    id: 2,
    imgUrl: require("../../images/caregiver/caregiver_avatar_02.png"),
  },

  {
    id: 3,
    imgUrl: require("../../images/caregiver/caregiver_avatar_03.png"),
  },

  {
    id: 4,
    imgUrl: require("../../images/caregiver/caregiver_avatar_04.png"),
  },

  {
    id: 5,
    imgUrl: require("../../images/caregiver/caregiver_avatar_05.png"),
  },

  {
    id: 6,
    imgUrl: require("../../images/caregiver/caregiver_avatar_06.png"),
  },

]

export const kidAvatar =[
  {
    id: 1,
    imgUrl: require("../../images/kid/kid_avatar_01.png"),
  },

  {
    id: 2,
    imgUrl: require("../../images/kid/kid_avatar_02.png"),
  },

  {
    id: 3,
    imgUrl: require("../../images/kid/kid_avatar_03.png"),
  },

  {
    id: 4,
    imgUrl: require("../../images/kid/kid_avatar_04.png"),
  },

  {
    id: 5,
    imgUrl: require("../../images/kid/kid_avatar_05.png"),
  },

  {
    id: 6,
    imgUrl: require("../../images/kid/kid_avatar_06.png"),
  },
]
