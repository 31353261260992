export const KID_GET_LIST = "KID_GET_LIST";
export const KID_GET_LIST_SUCCESS = "KID_GET_LIST_SUCCESS";
export const KID_GET_LIST_FAIL = "KID_GET_LIST_FAIL";

export const KID_SET_LIST = "KID_SET_LIST";

export const KID_CREATE = "KID_CREATE";
export const KID_CREATE_SUCCESS = "KID_CREATE_SUCCESS";
export const KID_CREATE_FAIL = "KID_CREATE_FAIL";

export const KID_EDIT = "KID_EDIT";
export const KID_EDIT_SUCCESS = "KID_EDIT_SUCCESS";
export const KID_EDIT_FAIL = "KID_EDIT_FAIL";

export const KID_EDIT_AVATAR = "KID_EDIT_AVATAR";
export const KID_EDIT_AVATAR_SUCCESS = "KID_EDIT_AVATAR_SUCCESS";
export const KID_EDIT_AVATAR_FAIL = "KID_EDIT_AVATAR_FAIL";

export const KID_CHANGE = "KID_CHANGE";
export const KID_CHANGE_BY_ID = "KID_CHANGE_BY_ID";
export const SKILL_GET_LIST = "SKILL_GET_LIST";

export const KID_ADD_SKILLS = "KID_ADD_SKILLS";
export const KID_ADD_SKILLS_SUCCESS = "KID_ADD_SKILLS_SUCCESS";
export const KID_ADD_SKILLS_FAIL = "KID_ADD_SKILLS_FAIL";

export const KID_GET_SKILLS = "KID_GET_SKILLS";
export const KID_GET_SKILLS_SUCCESS = "KID_GET_SKILLS_SUCCESS";
export const KID_GET_SKILLS_FAIL = "KID_GET_SKILLS_FAIL";

export const KID_ADD_CAREGIVER = "KID_ADD_CAREGIVER";
export const KID_ADD_CAREGIVER_SUCCESS = "KID_ADD_CAREGIVER_SUCCESS";
export const KID_ADD_CAREGIVER_FAIL = "KID_ADD_CAREGIVER_FAIL";
export const KID_RESEND_CAREGIVER = "KID_RESEND_CAREGIVER";
export const KID_RESEND_CAREGIVER_SUCCESS = "KID_RESEND_CAREGIVER_SUCCESS";
export const KID_RESEND_CAREGIVER_FAIL = "KID_RESEND_CAREGIVER_FAIL";
export const KID_REMOVE_CAREGIVER = "KID_REMOVE_CAREGIVER";
export const KID_REMOVE_CAREGIVER_SUCCESS = "KID_REMOVE_CAREGIVER_SUCCESS";
export const KID_REMOVE_CAREGIVER_FAIL = "KID_REMOVE_CAREGIVER_FAIL";

export const kidGetList = () => ({
  type: KID_GET_LIST,
  payload: {
    request: {
      method: "get",
      url: "/kids",
    },
  },
});

export const kidSetList = (list) => ({
  type: KID_SET_LIST,
  list,
});

export const kidCreate = (name, birthdate, gender, gestation, avatar) => {
  let formData = new FormData();

  formData.append("avatar", avatar);
  formData.set("name", name);
  formData.set("birthdate", birthdate);
  formData.set("gender", gender);
  formData.set("gestation", gestation);

  return {
    type: KID_CREATE,
    payload: {
      request: {
        method: "post",
        url: "/kids",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    },
  };
};

export const kidChange = (index) => ({
  type: KID_CHANGE,
  index,
});

export const kidChangeById = (id) => ({
  type: KID_CHANGE_BY_ID,
  id,
});

export const kidEdit = (id, name, birthdate, gender, gestation) => ({
  type: KID_EDIT,
  payload: {
    request: {
      method: "patch",
      url: `/kids/${id}`,
      data: {
        name,
        birthdate,
        gender,
        gestation,
      },
    },
  },
});

export const kidEditAvatar = (id, avatar) => {
  let formData = new FormData();
  formData.append("avatar", avatar);

  return {
    type: KID_EDIT_AVATAR,
    payload: {
      request: {
        method: "patch",
        url: `/kids/${id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
    },
  };
};

export const kidAddCaregiver = (id, email) => ({
  type: KID_ADD_CAREGIVER,
  payload: {
    request: {
      method: "post",
      url: `kids/${id}/caregiver`,
      data: {
        email,
      },
    },
    kidId: id,
  },
});

export const kidResendInviteCaregiver = (id, caregiverId) => ({
  type: KID_RESEND_CAREGIVER,
  payload: {
    request: {
      method: "post",
      url: `kids/${id}/caregiver/${caregiverId}/resend_invite`,
    },
    kidId: id,
    caregiverId: caregiverId,
  },
});

export const kidRemoveCaregiver = (id, caregiverId) => ({
  type: KID_REMOVE_CAREGIVER,
  payload: {
    request: {
      method: "delete",
      url: `kids/${id}/caregiver/${caregiverId}`,
    },
    kidId: id,
    caregiverId: caregiverId,
  },
});

export const skillGetList = (kind, kid_id=null) => ({
  type: SKILL_GET_LIST,
  payload: {
    request: {
      method: "get",
      url: "/skills/get_skills" ,
      params: {
        kind: kind,
        kid_id: kid_id
       },
    },
  },
});

export const kidAddSkill = (kid_id, skill_ids) => ({
  type: KID_ADD_SKILLS,
  payload: {
    request: {
      method: "post",
      url: "/kids/" + kid_id + "/add_skills",
      data: {
        skill_ids
      },
    },
  },
});

export const kidGetSkills = (kid_id) => ({
  type: KID_GET_SKILLS,
  payload: {
    request: {
      method: "get",
      url: "/kids/" + kid_id + "/get_skills",
    },
  },
});
