export default {
  show: {
    title: "O meu perfil",
    edit_button: "Editar",
    kids_title: "Crianças",
    logout_button: "Terminar sessão"
  },
  edit: {
    title: "Editar perfil",
    gender_male: "Masculino",
    gender_female: "Feminino",
    name_input: "Nome",
    email_input: "Email",
    change_password_title: "Alterar palavra-passe",
    new_password_input: "Nova palavra-passe",
    confirm_new_password_input: "Confirmar a nova palavra-passe",
    save_button: "Guardar",
    receive_emails: "Subscrever Emails"
  },
  kid_edit_profile: {
    title_male: "Editar perfil do {{name}}",
    title_female: "Editar perfil da {{name}}",
    gender_male: "Menino",
    gender_female: "Menina",
    name_input: "Nome",
    date_input: "Data de Nascimento",
    birth_date1: "Nasceu às",
    birth_date2: "semanas",
    save_button: "Guardar"
  },
  kid_show: {
    title_male: "Editar perfil do {{name}}",
    title_female: "Editar perfil da {{name}}",
    bio_title: "A minha biografia",
    bio_subtitle: "Clica nos parágrafos para atualizar.",
    update_button: "Alguma novidade?",
    caregivers_title: "Cuidadores",
    back_button: "Voltar"
  }
}
