import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { skillGetList, kidAddSkill } from "../../redux/actions/kid";
import AddSkill from './add_skill';

import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";

class Skills extends Component {

  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      title: "",
      options: [],
      chosenKid: 0
    };
  }

  componentDidMount() {
    document.body.classList.add("color__background");

    const { dispatch, skillsIds, chosenKid, kid, t } = this.props;
    const { index, titles } = this.state;

    let skillsIdsAux = skillsIds ? skillsIds : [0,1,2,3];
    let chosenKidAux = chosenKid ? chosenKid : kid.chosen;

    let kid_id = kid.list[chosenKidAux].id;

    dispatch(skillGetList(skillsIdsAux[index], kid_id))
      .then((success) => {
        console.log("got", success);
        this.setState({title: success.payload.data.title, options: success.payload.data.list, skillsIds: skillsIdsAux, chosenKid: chosenKidAux});
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  updateKidSkills = (skills) => {
    const { dispatch, history, kid, doneCallback, t } = this.props;
    const { index, skillsIds, chosenKid } = this.state;

    dispatch(kidAddSkill(kid.list[chosenKid].id, skills.map(s => s.id)))
      .then((success) => {
        console.log("got", success);
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });

    let nextIndex = index+1;

    if (nextIndex >= skillsIds.length) {
      // history.replace("/wizard/resume");
      doneCallback();
    }

    dispatch(skillGetList(skillsIds[nextIndex], kid.list[chosenKid].id))
      .then((success) => {
        console.log("got", success);
        this.setState({index: nextIndex, title: success.payload.data.title, options: success.payload.data.list});
      })
      .catch((error) => {
        // console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  };

  componentWillUnmount() {
    document.body.classList.remove("color__background");
  };

  render() {
    const { title, options } = this.state;

    return (
        <AddSkill title={title}
          nextStep={(checked_list) => this.updateKidSkills(checked_list)}
          options={options}/>
    );
  }
}

// export default Home;

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Skills));
