export default {
  header_title: "Let's play!",
  header_text: "With Babygrow parents and caretakers of children aged 0 to 3 years old can get weekly suggestions to play together. These ideas meet the child’s interests and development stage, in order to promote physical, intellectual and emotional growth in a fun way!",
  header_button: "Let's go!",
  section1_title: "Why Babygrow?",
  section1_subtitle1: "Playing is key",
  section1_text1: "It is through play that the baby learns about the world. By exploring, manipulating, observing and interacting, the child discovers themselves and their capacities, simultaneously learning about and acting upon the world that surrounds them.",
  section1_subtitle2: "The first 3 years are crucial",
  section1_text2: "In the first 3 years of their lives, children absorb the world through all of their senses. It is the period of life with the most potential for learning!",
  section1_subtitle3: "How to play?",
  section1_text3: "The suggestions brought to you by Babygrow are proposed and reviewed by Childhood experts. They seek to promote the exploratory, communicative and investigative capacities of each child through play, allowing the child to answer questions such “What’s this?”, “What does it do?” or “What can I do with it?” on their own, or with little adult help.",
  section1_subtitle4: "Going beyond commercial toys",
  section1_text4: "By encouraging the child to interact with objects present in their daily routine that each have different textures, shapes and sizes, Babygrow offers play with much richer interaction than that of normal commercial toys, and without the associated costs that come with them!",
  section1_subtitle5: "Creating opportunity",
  section1_text5: "By giving the child the opportunity to explore and experiment, we allow them to believe in themselves and their personal conquests, which in turn helps them grow through play. Exploring multiple senses draws out different responses and languages from the child.",
  section1_subtitle6: "Everyone can do it!",
  section1_text6: "The suggestions brought to you by Babygrow can also be used by grandparents or nannies. This way you can make sure that the time your baby spends with other adults is enriching and positive for their growth.",
  section1_button: "I want to know more!",
  section2_title: "Want to play?",
  section2_subtitle: "Our suggestions are based on 3 premises:",
  section2_text1: "Serene and sensitive interactions through an attentive and responsive analysis of the child’s communication signals",
  section2_text2: "Creating enriching opportunities for exploration and discovery",
  section2_text3: "Favouring the contact with nature through simple play, supporting and expanding the child’s life experience",
  section2_button: "I want to know more!",
  section3_title: "How does it work?",
  section3_subtitle1: "Register your child on the platform",
  section3_text1: "We need to know some basic information so that the suggestions we give are tailored to your baby. You can give each of the child’s caretakers access, so that everyone can receive the suggestions and play with the baby at any time!",
  section3_subtitle2: "Get playing!",
  section3_text2: "Every week you will receive 3 suggestions specifically chosen for your child’s profile! These activities are based on common materials from our daily lives, and as such need very little to no previous setup. You will also have access to all the previous activities you have received, so you can repeat them whenever you wish.",
  section3_subtitle3: "Play and observe",
  section3_text3: "Watch how your baby plays and reacts to new situations! Add notes to your baby’s profile so that next week’s suggestion are even more adapted to your baby!",
  section3_button: "I want to know more!",
  image_header: "Family",
  image_s01_01: "Playing is key",
  image_s01_02: "The first 3 years are crucial",
  image_s01_03: "How to play?",
  image_s01_05: "Going beyond commercial toys",
  image_s01_04: "Creating opportunity",
  image_s01_06: "Everyone can do it!",
  image_s02_01: "Activities",
  image_s03_01: "Register",
  image_s03_02: "Receive activities",
  image_s03_03: "Observe"
}
