import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Merchant extends Component {

  render() {
    const {showPromo, t} = this.props;
    const {logo, name, description, promo_text, facebook, instagram, id} = this.props.merchant;


    return(
      <div key={this.key} className="merchant_container">
        <div className="merchant_logo">
          {logo.large.url && <img src={logo.large.url} alt="merchant-logo" />}
        </div>

        <div className="merchant_details">
          <div className="merchant_details__info">
            <h2 className="title_5 merchant_details__info-name">{name}</h2>
            <p className="merchant_details__info-description">{description}</p>
            <p className="text--blue merchant_details__info-promo">{showPromo && promo_text}</p>
          </div>
          <div className="merchant_details__links">
            <div className="merchant_details__links-icons_container">
              {facebook &&
                <a href={"/partner/"+id+"/?social=facebook"} target="_blank"><i className="fab fa-facebook-f merchant_details__links-icon"></i></a>
              }
              {instagram &&
                <a href={"/partner/"+id+"/?social=instagram"} target="_blank"><i className="fab fa-instagram merchant_details__links-icon"></i></a>
              }
            </div>
            <a href={"/partner/"+id} className="merchant_details__links-button" target="_blank">
              <button type="button" name="button" className="small_button_text primary_button small_button">
                {t('components.merchants_component.see_more_button')}
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation() (Merchant);
