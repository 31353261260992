import React, { Component } from 'react';
import { connect } from "react-redux";
import { weekEmojis } from "../../../helpers/emojis";

import { activityUpdateRating } from "../../../redux/actions/activity";
import { withTranslation } from "react-i18next";

import Constants from "../../../helpers/constants";

import moment from 'moment';

class Step0 extends Component{
  constructor(props) {
  super(props);
    this.state = {
      clickedIcon: ""
    }
  };

  componentDidMount() {
    this.setState({clickedIcon: this.props.rating});
  }

  handleClickedIcon(key){
    let currentIcon = null;

    if(this.state.clickedIcon != key){
      currentIcon = key;
    }

    this.setState({clickedIcon: currentIcon});
  };

  render(){
    const {hideModal, show_evaluate_modal, message, activity, kidWeekDate, t} = this.props;
    const locale = Constants.locale;

    console.log("Adadadadasdadadadadad", moment(kidWeekDate));

    console.log("activity", activity, this.state.clickedIcon);
    let emojis = weekEmojis.map((emojis, key) =>
    <button type="button" className="modal__emojis-button" key={emojis.id} onClick={() => this.handleClickedIcon(key)}>
      {console.log("->", key)}
      <div className="modal__emojis-button-container">
        <i className={this.state.clickedIcon === key ? emojis.iconSelected : emojis.icon}></i>
        <p className="overline_text">{t(emojis.text)}</p>
      </div>
    </button>
    );


    return (
      <div className="o_container">
        <div className="week_form_header">
          <h1 className="title_3">{t('week_form.step0.title', {start_date: moment(kidWeekDate).locale(locale).format(t('date.formats.short')), end_date: moment(kidWeekDate).add(6, 'days').locale(locale).format(t('date.formats.short'))})}</h1>
          <h2 className="title_5">{t('week_form.step0.subtitle')}</h2>
        </div>
        <div className="week_form_emojis modal__emojis">
          {emojis}
        </div>
        <div className="week_form_buttons">
          <button type="button" name="button" className="button large_button primary_button" onClick={() => this.props.nextStep(1, this.state.clickedIcon)}>{t('week_form.step0.next_button')}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ kid }) => ({
  kid,
});

export default connect(mapStateToProps)(withTranslation() (Step0));
