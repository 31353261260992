import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import { genderHelper,  skillKindHelper} from "../helpers/utilities";

import logo_purple from "../../images/logo_purple.png";
import { withTranslation } from "react-i18next";
import Constants from "../helpers/constants";

class Footer extends Component {
  render() {
    const { t } = this.props;
    const locale = Constants.locale;
    return (
      <footer>
        <div className="f_container">
          <div className="footer__info">
            <div className="footer__image">
            <Link to="/">
              <img src={logo_purple} alt="Babygrow" />
            </Link>
            </div>
            <div className="footer__info--links">
              <div className="footer__info--links--column">
              
              {locale === 'pt' && 
                <Link to="/blog" className="overline_text">{t('global.footer.blog')}</Link>
              }
                <Link to="/why_babygrow" className="overline_text">{t('global.footer.why')}</Link>
                <Link to="/play" className="overline_text">{t('global.footer.want_play')}</Link>
              {locale === 'pt' && 
                <Link to="/partners" className="overline_text">{t('global.footer.partners')}</Link>
              }
              </div>

              <div className="footer__info--links--column">
                <Link to="/terms" className="overline_text">{t('global.footer.terms')}</Link>
                <Link to="/privacyPolicy" className="overline_text">{t('global.footer.privacy')}</Link>
                <Link to="/cookiesPolicy" className="overline_text">{t('global.footer.cookies')}</Link>
              </div>
            </div>
          </div>

          <hr/>

          <div className="footer__social">
            <p className="overline_text footer__company">@{new Date().getFullYear()} {t('global.footer.developer')} <a target="blank" href="https://www.hyp.pt" className="overline_text footer__company--link">HYP</a></p>
            <div>
              {locale === 'pt' && 
                <>
                  <a href="https://medium.com/babygrow-pt" target="_blank"><i className="fab fa-medium-m footer__icons"></i></a>
                  <a href="https://www.facebook.com/babygrowpt" target="_blank"><i className="fab fa-facebook-f footer__icons"></i></a>
                  <a href="https://www.instagram.com/babygrow.pt" target="_blank"><i className="fab fa-instagram footer__icons"></i></a>
                </>
              }
              {locale === 'en' && 
                <>
                  <a href="https://www.facebook.com/babygrowfun" target="_blank"><i className="fab fa-facebook-f footer__icons"></i></a>
                  <a href="https://www.instagram.com/babygrow.fun/" target="_blank"><i className="fab fa-instagram footer__icons"></i></a>
                </>
              }
              <a href="mailto:mail@babygrow.pt"><i className="fal fa-envelope footer__icons"></i></a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);


// export default connect(mapStateToProps)(Footer);
