import React from "react";

import Routes from "./routes";
import Cookies from "./components/cookies";
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const CloseButton = ({ closeToast }) => (
  <button onClick={closeToast} className="Toastify__close-button Toastify__close-button--default">
    <i className="fal fa-times"></i>
  </button>
);

export default (props) => (
  <>
    <ToastContainer closeButton={<CloseButton />} />
    {Routes}
    <Cookies />
  </>
);
