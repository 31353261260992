import { createStore, combineReducers, applyMiddleware } from "redux";
// if (process.env.NODE_ENV != "production") {
import logger from "redux-logger";
// }
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
// import * as AxiosLogger from 'axios-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { history } from "../helpers/routing";
import app from "./reducers/app";
import user from "./reducers/user";
import kid from "./reducers/kid";
import activity from "./reducers/activity";
import consents from "./reducers/consents";
import { startLoading, stopLoading } from "./actions/app";
import { userSetTokens, userLogout } from "./actions/user";
// import {BASE_API_URL} from '../config/constants';
// import {storeCredentials, getCredentials} from '../helpers/asyncStorage';

import Constants from "../helpers/constants";

// const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
let token = document.querySelector('meta[name="csrf-token"]').content;
// console.log("aaaaaaaaaaaaaa", token);
// replace baseURL with constants variable with backend API url
axios.defaults.withCredentials = true
const client = axios.create({
  baseURL: Constants.api_url + "/api/v1",
  // withCredentials: true,
  responseType: "json",
  headers: {
    // crossDomain: true,
    // "Content-Type": "application/json",
    // accept: "application/json",
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'X-CSRF-Token': token,
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': token,
  },
  timeout: 10000,
});
// client.interceptors.request.use(AxiosLogger.requestLogger);

// setup axios to automaticaly set the loading state
const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, req) {
          // let token = document.querySelector('meta[name="csrf-token"]').content;
          // console.log("aaaaaaaaaaaaaa", Cookies.get('my_csrf_token'));
          req.headers['X-CSRF-Token'] = Cookies.get('my_csrf_token');
          const { accessToken, client, uid } = getState().user;
          // console.log("getCredentials", accessToken, client, uid);
          // if (accessToken) {
          //   req.headers["access-token"] = accessToken;
          //   req.headers["client"] = client;
          //   req.headers["uid"] = uid;
          // }
          // console.log("request success", req);
          dispatch(startLoading());
          return req;
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          dispatch(startLoading());
          return response;
        },
      },
    ],
    response: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, res) {
          const { headers } = res;
          // console.log("response success", res);
          // dispatch(
          //   userSetTokens(
          //     headers["access-token"],
          //     headers["client"],
          //     headers["uid"]
          //   )
          // );
          // console.log(
          //   "storeCredentials",
          //   headers["access-token"],
          //   headers["client"],
          //   headers["uid"]
          // );
          dispatch(stopLoading());
          return Promise.resolve(res);
        },
        error: async function ({ getState, dispatch, getSourceAction }, error) {
          // console.log("response error", error);
          const { response, message } = error;
          const { headers, status } = response;

          if (response) {
            // dispatch(
            //   userSetTokens(
            //     headers["access-token"],
            //     headers["client"],
            //     headers["uid"]
            //   )
            // );
          } else {
            error = { response: { data: { message: message } } };
          }
          dispatch(stopLoading());
          if (status === 412) {
            history.replace("/showConsents");
            return Promise.reject(error);
          } else if (status === 401) {
            dispatch(userLogout());
            history.replace("/login");
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        },
      },
    ],
  },
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  // stateReconciler: autoMergeLevel2,
  blacklist: ["activity"],
};

// const userPersistConfig = {
//   key: "user",
//   // storage: storage,
//   storage: new CookieStorage(Cookies),
//   // whitelist: ["user"],
// };

const rootReducer = combineReducers({
  // user: persistReducer(userPersistConfig, user),
  user: user,
  app: app,
  kid: kid,
  activity: activity,
  consents: consents,
});

let aux_middleware = [];
if (process.env.NODE_ENV == "production") {
  aux_middleware = [axiosMiddleware(client, middlewareConfig)];
} else {
  aux_middleware = [axiosMiddleware(client, middlewareConfig), logger];
}

const middleware = applyMiddleware(...aux_middleware);

let store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  middleware
);
let persistor = persistStore(store);
// persistor.purge();

// export {store};
export { store, persistor };
