import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../redux/store";

import { getMerchants } from "../redux/actions/merchants";

import { toast } from 'react-toastify';

import NavBar from '../components/nav_bar';
import Merchant from '../components/merchant';
import { withTranslation } from "react-i18next";


class Merchants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      merchants: [],
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.props
      .dispatch(getMerchants())
      .then((success) => {
        this.setState({ merchants: success.payload.data });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(
          t('global.toast_error.unknown_error'), {toastId: "unknown_error"}
        )
      });
  }

  _merchants = () => {
    return this.state.merchants.map((merchant, index) => {
      const { id } = this.props.user;
      return (
        <Merchant key={index} merchant={merchant} showPromo={id != null ? true : false} />
      )
    });
  }

  render() {
    const { t } = this.props;
    return (
      <NavBar>
        <div className="o_container">
          <h1 className=" title_1">{t('merchants.title')}</h1>
          <div className="all_merchants_container landing-page__inside">
            {this._merchants()}
          </div>
        </div>
      </NavBar>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

// export default connect(() => ({}))(Merchants);
export default connect(mapStateToProps)(withTranslation() (Merchants));
