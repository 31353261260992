import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as Sentry from '@sentry/browser';

import error from '../../images/error.svg';
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component{
  // constructor(...args){
  //   super(...args);
  //   this.state = {
  //     catchedError: false
  //   }
  // }
  // componentDidCatch(error, errorInfo){
  //   this.setState({ catchedError: error });
  // }
  //
  // render(){
  //   const { children } = this.props;
  //   const { catchedError } = this.state;
  //
  //   if (catchedError)
  //     return (<div>Something went wrong</div>);
  //
  //   // return the children
  //   return children;
  // }

  constructor(props) {
        super(props);
        this.state = { eventId: null };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      if (process.env.NODE_ENV == "production") {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
      }
    }

    render() {
        const { t } = this.props;
        if (this.state.hasError) {

          return (
            <div className="o_container o_container--medium center error__container">
              <h1 className="title_1">{t('error_pages.error_boundary.title')}</h1>
              <img src={error} alt="Pessoa confusa" />
              <h3 className="title_3 error__message">{t('error_pages.error_boundary.subtitle')}</h3>

              <Link to="/" className="button proceed_button small_button" role="button">
                {t('error_pages.error_boundary.back_button')}
              </Link>
            </div>
          );
            //render fallback UI
            // return (
            //   <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
            // );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default withTranslation() (ErrorBoundary);
