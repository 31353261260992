import {START_LOADING, STOP_LOADING} from '../actions/app';

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {...state, loading: true};
      break;
    case STOP_LOADING:
      return {...state, loading: false};
      break;
    default:
      return state;
      break;
  }
};
