export const CONSENTS_GET = "CONSENTS_GET";
export const CONSENTS_ACCEPT = "CONSENTS_ACCEPT";
export const TERMS_GET = "TERMS_GET";
export const PRIVACY_POLICY_GET = "PRIVACY_POLICY_GET";
export const COOKIES_POLICY_GET = "COOKIES_POLICY_GET";
export const COOKIES_POLICY_ACCEPT = "COOKIES_ACCEPT";

export const consentsGet = () => ({
  type: CONSENTS_GET,
  payload: {
    request: {
      method: "get",
      url: "/show_consents",
    },
  },
});

export const consentsAccept = () => ({
  type: CONSENTS_ACCEPT,
  payload: {
    request: {
      method: "post",
      url: "/accept_consents",
    },
  },
});

export const termsGet = () => ({
  type: TERMS_GET,
  payload: {
    request: {
      method: "get",
      url: "/terms",
    },
  },
});

export const privacyPolicyGet = () => ({
  type: PRIVACY_POLICY_GET,
  payload: {
    request: {
      method: "get",
      url: "/privacy_policy",
    },
  },
});

export const cookiesPolicyGet = () => ({
  type: COOKIES_POLICY_GET,
  payload: {
    request: {
      method: "get",
      url: "/cookies_policy",
    },
  },
});

export const cookiesPolicyAccept = () => ({
  type: COOKIES_POLICY_ACCEPT,
});
