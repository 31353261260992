export default {
  kid_register: {
    title: "Para começar precisamos de algumas informações sobre a criança!",
    gender_male: "Menino",
    gender_female: "Menina",
    name_input: "Nome",
    date_input: "Data de Nascimento",
    birth_date1: "Nasceu às",
    birth_date2: "semanas",
    next_button: "Seguinte"
  },
  kid_bio: {
    title_male: "Estamos quase a terminar! Esta é a informação que já temos sobre {{name}}",
    title_female: "Estamos quase a terminar! Esta é a informação que já temos sobre {{name}}",
    next_button: "Seguinte"
  },
  kid_resume: {
    title_male: "Muito bem! Esta é a biografia do {{name}}",
    title_female: "Muito bem! Esta é a biografia da {{name}}",
    next_button: "Seguinte"
  },
  invite: {
    title_male: "Pode convidar familiares para usar o Babygrow consigo e com o {{name}}",
    title_female: "Pode convidar familiares para usar o Babygrow consigo e com a {{name}}",
    next_button: "Seguinte"
  },
  add_another_kid: {
    title: "Deseja adicionar mais alguma criança?",
    notnow_button: "Agora não"
  }
}